<template>
    <section>
        <GCLandscape v-if="landscape_mode" :game="game" :tracks="tracks" @closeClicked="closeClicked" @nextGame="nextGame()" />
        <GCPortrait v-else :game="game" :tracks="tracks" @closeClicked="closeClicked"  @nextGame="nextGame()" />
    </section>
</template>

<script>
import Vue from 'vue';

import { gameLogic } from "@/mixins";

import GCLandscape from './GCLandscape.vue';
import GCPortrait from './GCPortrait.vue';

export default {
  name: "GCMobile",
  mixins: [gameLogic],
  props: ["landscape_mode", "game", "tracks"],

  components: {
    GCLandscape,
    GCPortrait, 
  },
  methods: {
    closeClicked() {
        console.log("Mobile.closeClicked()")
        this.$router.push({name: 'Streaming'})
    },
    nextGame() {
        this.$emit('nextGame')
    }
  },


}
  



</script>


<style lang="scss" scoped>

section {
    margin: 0 auto;
}
.recommended {
    height: 81.4%;
    width: 61.5%;
    background: rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    position: relative;
    margin-top: 2.7%;
    z-index: 1;
    border-radius: 22px;
}

.dots_container {
    position: absolute;
    z-index: 0;
    bottom: 12vh;
    left: 0;
    height: 54%;
    width: 100%;
    right: 0;
    //background-image: url('src/assets/GameCompleteBG.png');
    //background: black;
}
.bg_image {
    position: absolute;
    z-index: 0;
    bottom: 12vh;
    left: 0;
    width: 100%;
    right: 0;
    //background-image: url('src/assets/GameCompleteBG.png');
    //background: black;
}
.dots {
        height: 100%;
    width: 100vw;
    position: absolute;
    left: 0;
    bottom: 0;
    background: transparent;
}
.info_boxes {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
   // padding-left: 6vw;
    //padding-right: 4vw;
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 1;
    &__whole {
        width: 61.5%;
        height: 82.5%;
        background: rgba(0, 0, 0, 0.3);

    }
    &__side { 
        background: rgba(0, 0, 0, 0.3);
        width: 26%;
        min-height: 55%;//45%;
        max-height: 87%;
        //height: max-content;
        //margin-top: 27.7vh;
       // margin-bottom: 30vh;
        border-radius: 22px;
         padding-top: 2rem;
         padding-bottom: 62px;
    }
    &__center {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 22px;
        width: 34.4%;
        max-height: 95%;//81%;
        //margin-top: 2rem;
        margin-top: -3rem;
        padding-top: 2%;//2rem;
        padding-bottom: 6%;
        overflow: scroll;

    }
}

.mobile {
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
    &__card {
        width: 93vw;
        margin: 0 auto;
        border-radius: 22px;
        max-height: 52vh;
        overflow: scroll;
    }
}


 @media screen and (orientation:landscape) {
     
     .mobile {
       flex-direction: row;
              padding-left: 10.5vw;
       padding-right: 2.1vw;
        &__card {
            width: 43vw;
            margin: 0 auto;
            border-radius: 22px;
            max-height: 52vh;
            // overflow: scroll;
        }
    }
    .landscape {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        height: 100vh; //THIS WILL PROB NEED TO CHANGE
        &__rhs {
            min-height: 100vh;
            overflow: hidden;
        }
    }

 }
</style>

