<template>
    <DesktopProfile v-if="!$isMobile()" :artist_name="artist_name" 
                                        :artist_image="artist_image" 
                                        :playlists="playlists" 
                                        @joinGame="joinGame" />
    <MobileProfile v-else   :artist_name="artist_name" 
                            :artist_image="artist_image" 
                            :playlists="playlists" 
                            @joinGame="joinGame" />
</template>

<script>
import { mapActions } from 'vuex'
import MobileProfile from "@/components/Profile/Mobile.vue"
import DesktopProfile from "@/components/Profile/Desktop.vue"
import { db } from '@/store/modules/firebase'
import api from '@/api'

export default {
    name: "ProfilePage",
    data() {
        return {
          //  artistId: null,
            artist: null,
            playlists: [],
        }
    },
    components: {MobileProfile, DesktopProfile},
    computed: {
        artist_name() {
            return this.artist ? this.artist.name : ""
        },
        artist_image() {
            return this.artist ? this.artist.images[0].url : ""
        },
        artistId() {
            return this.$route.params.id
        }
        
    },
    updated() {
        console.log("Updated")
    
    },
    async mounted() {
        console.log("ProfilePage mounted");
        if (this.$route.params.id) {
            const artist_id = this.$route.params.id
            console.log("Getting playlist id: ", artist_id);
            this.artist = await this.getArtistFromSpotify(artist_id)
            this.playlists = await this.getPlaylistsFromSpotify(this.artist.name)
            
          //  this.artistId = artist_id
        } 
    },
    methods: {
        joinGame(game) {
            console.log("Joining game", game)
            this.$store.dispatch('webHQ/joinGame', {game})
        },
        async getArtistFromSpotify(id) {
            var response = await api.spotify.artists.getArtist(id)
            var artist = response.data
            console.log("ARTIST", artist)
            return artist
        },
        async getPlaylistsFromSpotify(artist_name) {
            var response = await api.spotify.artists.getArtistPlaylists(artist_name)
            var playlists = response.data.playlists.items
            console.log("PLAYLISTS FOUND", playlists)
            return playlists
        },
    },
    watch: {
        async artistId(newVal, oldVal) {
            console.log("artistId changed", newVal, oldVal)
            if (newVal && newVal !== oldVal) {
                console.log("Getting artist from Spotify")
                //if first character in artist_id is "-", then it's an active artist
                if (newVal.charAt(0) === "-") {
                    console.log("Binding artist");
                    this.$bind('artistId', db.ref(`artists/${newVal}`))
                } else {
                    console.log("Not a Snipitfm artist (yet)")
                    var artist = await this.getArtistFromSpotify(newVal)
                    this.artist = artist 
                }
                console.log("Artist - ", this.artist.name)
                var playlists = await this.getPlaylistsFromSpotify(this.artist_name)
                this.playlists = playlists
                
            }
        }
    }
}
</script>

<style lang="scss" scoped>
section {
    color: white;
    font-family: "Rubik";
}

.desktop-section {
    background-image: url('~@/assets/bgMay2022.png');
}

.gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 76, 166, 0.85) 0%, rgba(0, 0, 0, 0.85) 100%);
}
.foreground {
    z-index: 2;
    position: absolute;
    top: 8.9vh;
    left: 0;
    right: 0;
    bottom: 0;
    
}
.background {
    z-index: 1;
}
.app_name {
    font-style: normal;
    font-weight: 500;
    font-size: 42px;

}
.artist_name {
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    text-align: center;
    margin: 0 15vw;
    color: #47F113;
}
.playlists_title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: left;
}
.playlists {
   // display: flex;
    flex-direction: row;
    margin-top: 1.5vh;
    gap: 5vw;
    overflow: scroll;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 19vh;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;

}
.playlist {
    width: 30.5vw;
    height: 30.5vw;
    display: inline-block;
    margin-right: 6vw;

    &__cover {
        width: 30.5vw;
        height: auto;
    }
    &__name {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        text-align: left;
    }
}

.pfp-container {
    position: relative;
    width: 34vw;
    height: 34vw;
    margin: 0 auto;
    margin-top: 4.1vh;
    .pfp {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        border-radius: 50%;
    }
    .pfp-mobile {
        transform: translate(3.4vw, 3.4vw);
        width: 27.8vw;
        height: 27.8vw;
    }
    .pfp-desktop {
        transform: translate(1.2vw, 1.2vw);
        width: 32vw;
        height: 32vw;
    }
    .glow {
        position: absolute;
        width: 34vw;
        height: 34vw;
        top: 0;
        left: 0;
        border-radius: 50%;
        background: rgba(217, 217, 217, 0.9);
        filter: blur(6px);
    }
}
</style>