<template>
    <section>
        <div class="header">
            <h1>{{header_text}}</h1>
            <p> Select a few genres to personalize your SnipitFM experience. They will not be visible on your profile.</p>
        </div>
        <div class="genre_selection">
          <div class="genre_selection_container">
            <div class="genre_selection_item"   v-for="category in categories" 
                                                :key="category.id"
                                                :class="{'genre_selection_item_selected': selected_categories.includes(category.id)}"
                                                @click="selectCategory(category)">
                  <div class="genre_selection_item_text">{{category.name}}</div>
            </div>
          </div>
        </div>
        <div class="footer">
            <div class="selected_categories_counter">{{count_num}} selected</div>
            <div class="next_btn"
                    :class="{'next_btn_active': next_btn_active}"
                    @click="genreSelectionClosed()">Next</div>
        </div>
    </section>
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import { db } from '@/store/modules/firebase';

export default {
    name: "GenreSelection",
    props: [],
    data() {
        return  {
            landscape_mode: false,
            categories_obj: [],
            selected_categories: [],
            min_count: 1
        }
    },
    firebase: {
        categories_obj: db.ref('categories')
    },
    methods: {

        selectCategory(category) {
            if (this.selected_categories.includes(category.id)) {
                this.selected_categories = this.selected_categories.filter(id => id !== category.id)
            } else {
                this.selected_categories.push(category.id)
            }
        },
        genreSelectionClosed() {
            console.log("GenreSelection.genreSelectionClosed()");
            if (this.next_btn_active) {
                this.$store.dispatch('firebase/updatePlaylistCategories', {categories: this.selected_categories})
                this.$emit('genreSelectionClosed')
            }
        }
    },
    computed: {
        header_text() { return "What do you want to see on SnipitFM? " },
        count_num() { return this.selected_categories.length },
        next_btn_active() { return this.selected_categories.length >= this.min_count },
        //get all categories where hidden is false
        categories() {
            return Object.values(this.categories_obj).filter(category => !category.hidden)
        },
        ...mapGetters({
            user_ref: 'firebase/user',
            categories_ref: 'firebase/categories_ref',
        })
    },
    mounted() {
  
    },
}
</script>

<style lang="scss" scoped>
section {
    background-color: #1f1f1f;
    color: white;

    height: 100%;
    width: 100%;
    color: #E8E8E8;
    font-size: 1.2rem;
    font-weight: bold;
}
.header {
    padding-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 2.5rem;
}
h1 {
    font-weight: 700;
    font-size: 1.5rem;
    text-align: left;
    font-family: Rubik;
}
p {
    text-align: left;
    font-size:  0.75rem;
    font-family: Rubik;
    font-weight: 400;
}
.genre_selection {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    height: 100%;
}
.genre_selection_container {
    display: flex;
    background-color: #1f1f1f;
    flex-direction: row;
    gap: 6.2vw;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0 6.2vw;
    padding-bottom: 30rem;
}
.genre_selection_item {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 45%;
    height: 8vh;
    margin-bottom: 3.5%;
    padding-left: 12px;
    padding-bottom: 12px;
    border-radius: 10px;
    background-color: #1f1f1f;
    border: 1px solid #C2C2C2;
    font-size: 0.75rem;
    cursor: pointer;
}

.genre_selection_item_text {
    text-align: left;
}
.genre_selection_item_selected {
    background-color: #E8E8E8;
    color: #1f1f1f;
}
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    padding: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1f1f1f;
        padding-bottom: calc(1em + env(safe-area-inset-bottom, 72px));

}
.next_btn {
    background-color: #C2C2C2;
    color: #1F1F1F;
    padding: 4px 8px;
    border-radius: 8px;
    cursor: pointer;
}
.next_btn_active {
    background: linear-gradient(90.11deg, #CAFF34 0.13%, #9BFF7B 99.95%); 
}


@media only screen and (min-width: 480px) {
    .genre_selection_container {
        width: 80vw;
        margin: 0 auto;
    }
    .genre_selection_item {
        font-size: 1rem;
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}
@media screen and (min-width: 480px) {
    .genre_selection {
        margin-top: 4rem;
    }
    .footer {
        bottom: 3vh;
    }
    .genre_selection_container {
        gap: 3.2vw;
    }
    .genre_selection_item {
        width: 27%;
    }
    
}
// @media screen and (max-width: 480px) {
//     .genre_selection {
//         position: absolute;
//         bottom: 10.4vh;
//         top: 15.6vh;
//         left: 0;
//         right: 0;
//     }
//     .header {
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         height: 15.6vh;
//     }
//     .footer {
//         position: absolute;
//         height: 10.4vh;
//     }
// }
</style>