<template>
     <div class="section2">
        <!-- <ResultsToggle class="results_toggle" @toggleBtn="toggleBtn" :active="active" /> -->
        <div class="section2__card">
            <Track class="tracks_list__row" :header="true" />
            <div class="scrollyollypolly">
                <div class='tracks_list'>
                    <Track class="tracks_list__row" v-for="(track, index) in tracks" 
                                                    :key="index" 
                                                    :track="track" 
                                                    :index="index" 
                                                    :votes_count="votes_count[track.item.id]"
                                                    @artistSelected="artistSelected"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Track from '@/components/GameComplete/VoteResults/Track'
import { mapGetters, mapState } from "vuex";
import ResultsToggle from './ResultsToggle.vue';    
export default {
    name: "MobileVoteResults",
    props: ["tracks", "game_expired", "votes_count", "active"],
  
  
    components: { 
        Track, 
        //ResultsToggle 
    },
       
    computed: {
        show_community_results() { return this.active == "community" },

        show_community_votes() { return this.active != "personal" },
        
        currentUserPositions() {
            var dict = {}
            console.log("Iterating through ", this.tracks)
            this.tracks.forEach(track => {
                console.log("TRACK: ", track.item)
                if (track.item) {
                    if (!track.voters) { dict[track.item.id] = 0  }
                    else {
                        let votesCount = Object.keys(track.voters[this.current_user_id]).length 
                        let totalVotes = this.tallyTotalVotesFor(track)
                        let percentage = (votesCount / totalVotes) * 100
                        dict[track.item.id] = percentage  
                    } 
                }
                
            })

            
            console.log(dict)
            return dict
        },
    ...mapGetters({
        current_user_id: "user/id",
      }),
    ...mapState('context', ['preview_mode']),

        // ordered_tracks: function() {
        //     return this.tracks.sort((a,b) => {this.tallyTotalVotesFor(a) > this.tallyTotalVotesFor(b)})
        // }
    },
    methods: {
        ctaClicked() {
            this.$emit("ctaClicked")
        },
        artistSelected(artist) {
            this.$emit("artistSelected", artist)
        },
        toggleBtn(str) {
            console.log("Mobile() - TOGGLEBTN CALLED", str)
            this.$emit('toggleBtn', str)
            //Vue.set(this, "active", str)
        },
        voteCountFor(track) {
             return this.active == "personal"
                    ? this.tallyCurrentUsersVotesFor(track)
                    : this.tallyTotalVotesFor(track)
            // return (this.preview_mode || !this.game_expired) 
            //         ? this.tallyCurrentUsersVotesFor(track)
            //         : this.tallyTotalVotesFor(track)
        },
        tallyTotalVotesFor(track) {
            var sum = 0
            if (!this.game_expired) { return 0}
            if (track.voters != null) {
                for (let key in track.voters) {
                    // key represents a userID. track[key] is a dictionary. it should have 1 key per vote by the userID
                    // we want to aggregate the total votes by adding 1 for every subkey
                    sum += Object.keys(track.voters[key]).length
                }
            }
            return sum
        },
        tallyCurrentUsersVotesFor(track) {
            console.log("Tallying votes for ", track, this.current_user_id)
            var userVotedForThisSong = track.voters && this.current_user_id && track.voters[this.current_user_id] 
            var  tally = userVotedForThisSong ? Object.keys(track.voters[this.current_user_id]).length : 0
            return tally 
        },
    }

}
</script>

<style lang="scss" scoped>
.section2 {
    position: relative;
    height: 100%;
    overflow: hidden;
    &__card {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 22px;
        overflow: hidden;
        height: 100%;
        padding-left: 4%;
        padding-right: 4%;
        padding-top: 4%;
    }
}
h1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 8px;
}
h2 {
    font-weight: bold;
font-size: 14px;
line-height: 17px;
margin-top: 10px;
}
.tracks_list {
    width: 100%;
    margin: 0 auto;
    // padding-left: 8%;
    // padding-right: 35px;
    //margin-top: 11.6%;//70px;
   // overflow: scroll;
    height: 100%;
    overflow: scroll;
    &__header {
        display: flex;
        width: 100%;

        &__col1 {
        min-width: 21.3%;

        }
        &__col2 { 

        }
        &__col3 {

        }
        &__col4 {

        }

    }
    &__row {
        width: 100%;
        overflow-x: hidden;
    }
   
}
.green_gradient_btn {
    // position: sticky;
    // margin: 0 auto;
    // width: 83.9%;
    // left: 50%;
    // transform: translateX(-50%);
    // bottom: 7.1%;
    background: linear-gradient(90deg, #CAFF34 0%, #9BFF7B 102.96%);
    font-size: 20px;
    line-height: 24px;
    padding: 10px 80px;
    font-weight: 600;
    border: 0;
    border-radius: 8px;
    
}
.results_toggle {
        overflow: hidden;
    height: auto;
}
.green_gradient_btn:hover {
    cursor: pointer;
}
.scrollyollypolly {
    overflow: scroll;
    height: 88%;//83%;
    margin-bottom: 12.5%;



}

</style>