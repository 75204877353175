<template>
    <svg width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.82617C-0.000109116 2.82622 6.38709 4.54224 10.9331 9.25923C15.475 13.9861 14.917 22.6231 14.917 22.6231L10.013 21.7314C9.83056 21.6982 9.65617 21.82 9.62347 22.0035C9.60629 22.0999 9.63158 22.1991 9.69278 22.2753L17.2522 31.6959C17.3687 31.8411 17.58 31.8639 17.7241 31.747C17.7446 31.7304 17.763 31.7114 17.779 31.6905L25.5615 21.5395C25.6746 21.392 25.6473 21.18 25.5005 21.066C25.4138 20.9987 25.3 20.9781 25.1956 21.0108L21.0002 22.3233C21.0003 22.3229 20.7343 12.5842 14.8806 7.31664C9.03001 2.04071 -0.00026897 2.82624 0 2.82617V2.82617Z" :fill="fill" :fill-opacity="opacity"/>
    </svg>

</template>
<script>
export default {
    name: 'DownArrow',
    props: {
        fill: {
            type: String,
            default: '#FFFFFF'
        },
        opacity: {
            type: Number,
            default: 0.5
        }
    }
}
</script>