import request from "./request";

export default {
    getUserAuthURL: function () {
        console.log("getUserAuthURL called2", process.env, process.env.VUE_APP_ENV, window.location.href);
        return request.get(`login?env=${process.env.VUE_APP_ENV}&url=${window.location.href}`)
      
    },
    getGuestToken: async function() {
        var clientId = '3758633f4f4d48b5b8421182e6ef599e'
        var clientSecret = '4c39e88730e349beb045fd68f147404c'
        var data = await fetch('https://accounts.spotify.com/api/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic ' + btoa(clientId + ':' + clientSecret)
            },
            body: 'grant_type=client_credentials'
        });
        return data
        //const data = await result.json();
        //return data.access_token
    },

    refreshToken: function (refresh_token) {
        return request.get(`refresh_token?refresh_token=${refresh_token}`);
    }
};
