<template>

    <desktop-category-item v-if="!$isMobile()" :category="category" @setCategory="setCategory"/>
    <mobile-category-item  v-else :category="category" @setCategory="setCategory"/>
</template>
<script>
import MobileCategoryItem from '@/components/GameBrowser/Header/CategoryItem/Mobile.vue'
import DesktopCategoryItem from '@/components/GameBrowser/Header/CategoryItem/Desktop.vue'
export default {
    name: "CategoryItem",
    components: { MobileCategoryItem, DesktopCategoryItem },
    props: ["category", "active_tab"],
    methods: {
        setCategory(val) {
            console.log("CategoryItem.setCategory()", val)
            this.$emit("setCategory", val)
        }
    },
 }
</script>
<style scoped>
.penis {
    background: 'red';
}
</style>