const state = {
    notifications: []
};

const getters = {
    getNotifications: (state) => state.notifications
};

const mutations = {
    NOTIFICATION_ADD(state, data) {
        state.notifications.push(data);
    },
    NOTIFICATION_REMOVE(state, data) {
        const index = state.notifications.indexOf(data);
        state.notifications.splice(index, 1);
    }
};

const actions = {
    // To keep the notification on the page, set notification.duration = -1
    // However, this is not recommended until a manual close button is implemented
    // TODO - add a manual close button to Notifications
    add({ commit, dispatch }, notification) {
        commit("NOTIFICATION_ADD", notification);
        //default notifcation duration is 2 seconds (2 * 1000ms)
        var notification_duration = notification.duration || 2000
        console.log("NOTIFICATION DURATION ", notification_duration)
        if (notification_duration > 0) {
            setTimeout(() => {
                dispatch("remove", notification);
            }, notification_duration);
        } 
    },

    remove({ commit }, notification) {
        commit("NOTIFICATION_REMOVE", notification);
    }
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default module;
