<template>

    <div class="instructions-container" :class="{'black': use_black, 'white': !use_black}">
        <div class="header2">
            <p class="modal_title">{{modal_title}}</p>
            <p class="modal_description">{{modal_description}}</p>
        </div>
        <div class="instructions" >
            <div v-if="currentStep" class="instructions__step">
                <p class="instructions__stepNum">Step {{ index + 1 }} of {{ steps.length }}</p>
                <img :src="currentStep.image" alt="">
                <p class="instructions__title">{{ currentStep.title }}</p>
                <p class="instructions__description">{{ currentStep.description }}</p>
            </div>
            <div v-if="nextStep" class="instructions__step">
                <p class="instructions__stepNum">Step {{ index + 2 }} of {{ steps.length }}</p>
                <img :src="nextStep.image" alt="">
                <p class="instructions__title">{{ nextStep.title }}</p>
                <p class="instructions__description">{{ nextStep.description }}</p>
            </div>
        </div>
        <button class="cta-btn" @click="nextPage()">Continue</button>

    </div>
</template>
<script>

export default {
    name: "Instructions",
    props: {
        color: {
            type: String,
            default: "white",
        },
    },
    data() {
        return {
            modal_title: "How to Play SnipitFM",
            modal_description: '',//"Bet on your music taste, make money for being right",
            playlist: null,
            playlistURL: "",
            index: 0,
            steps: [
              {
                image: require("@/assets/InstructionsBrowsing.png"),
                title: "Browse the Music Options",
                description: "Select a playlist to get started",
              },
              {
                image: require("@/assets/InstructionsPoll.png"),
                title: "Judge music along other people",
                description: "Votes end every 30 mins",
              },
              {
                image: require("@/assets/InstructionsBrowsing.png"),
                title: "A top 3 song bet means you win 🤑",
                description: "",
              },
              // add more steps here
            ],
        }
    },
    computed: {
      currentStep() {
        return this.steps[this.index];
      },
      nextStep() {
        return this.steps[this.index + 1];
      },
      use_black() {
        return this.color === "black";
      },
    },
    methods: {
        nextPage() {
          if (this.index < this.steps.length - 1) {
            this.index += 2;
          }  else {
            this.$emit('close');
          }
        },
    },
}
</script>

<style lang="scss" scoped>
.black {
  color: #1f1f1f;
}
.white {
  color: #fff;
}
.instructions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

}
.modal_title {
  font-family: 'Rubik';
  margin: 0 auto;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
}
.modal_description {
    font-family: 'Rubik';
    text-align: center;
    margin-top: 0.75rem;
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 1.5rem;
}

.instructions {
  display: flex;
  justify-content: center;
  gap: 3rem;
  font-family: 'Rubik';
  font-size: 1rem;
  &__step {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    margin: 0 1rem;
    margin-bottom: 2rem;
    img {
      width: 250px;
      height: auto;
    }
  }
  &__stepNum {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  &__title {
    font-weight: 700;
    font-size: 1rem;
  }
  &__description {
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
  }
}

.cta-btn {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 36px;
    color: black;
    background: linear-gradient(90.11deg, #CAFF34 0.13%, #9BFF7B 99.95%);
    border: 0;
    border-radius: 8px;
    width: 40%;
    height: 40px;
    margin: 0 auto;
    margin-top: .5rem;
}
.cta-btn:hover {
    cursor: pointer;
}
</style>