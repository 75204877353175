<template>
    <div class="track-addition">
        <svg v-if="show_plus"  @click.prevent.stop="saveTrack" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.9286 5.89286H9.10714V1.07143C9.10714 0.479799 8.62734 0 8.03571 0H6.96429C6.37266 0 5.89286 0.479799 5.89286 1.07143V5.89286H1.07143C0.479799 5.89286 0 6.37266 0 6.96429V8.03571C0 8.62734 0.479799 9.10714 1.07143 9.10714H5.89286V13.9286C5.89286 14.5202 6.37266 15 6.96429 15H8.03571C8.62734 15 9.10714 14.5202 9.10714 13.9286V9.10714H13.9286C14.5202 9.10714 15 8.62734 15 8.03571V6.96429C15 6.37266 14.5202 5.89286 13.9286 5.89286Z" fill="#CAFF34"/>
        </svg>
      
        <svg v-if="show_checkmark"  @click.prevent.stop="removeTrack"  width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3754 0L16.2184 1.16724C13.0452 4.34733 10.1508 7.47544 7.08532 10.5973L3.32764 7.59725L2.04778 6.57338L0 9.13309L1.27986 10.157L6.19454 14.0887L7.3413 15L8.37543 13.9659C11.904 10.4297 15.0712 6.9499 18.5324 3.48123L19.6894 2.31399L17.3754 0Z" fill="#CAFF34"/>
        </svg>

        <img v-if="show_outline_heart"  @click.prevent.stop="saveTrack" src="@/assets/heart_icon.png" />
        <img v-if="show_full_heart"  @click.prevent.stop="removeTrack" src="@/assets/heart_full_icon.png" />
    </div>
</template>

<script>
import Vue from "vue"
import api from '@/api';
import { db, analytics } from '@/store/modules/firebase.js'
export default {
    name: "TrackAddition",
    data() {
        return {
            userSavedTrack: false,
        }
    },
    props: {
      track: {
        required: true
      },
      heart: {
          required: false,
          default: false,
      },
      game_complete: {
          required: false,
          default: true
      }
      
    },
    computed: {
        savedTrack() { 
            return !this.game_complete 
                    ? Object.keys(this.$store.getters['user/favorites_playlist_tracks']).length != 0 
                    ? this.$store.getters['user/favorites_playlist_root'].tracks[this.track.id] != null : false
                    : this.userSavedTrack

        },
        show_plus() {
            return !this.savedTrack && !this.heart
        },
        show_checkmark() {
            return this.savedTrack && !this.heart
        },
        show_full_heart() {
            return this.savedTrack && this.heart
        },
        show_outline_heart() {
            return !this.savedTrack && this.heart

        }
    },
    methods: {
        async saveTrack() { 
            await this.$store.dispatch('user/favorite_song', this.track)
            Vue.set(this, "userSavedTrack", true)
            analytics.logEvent("favorite", { song_name: this.track.name, song_artist: this.track.artists[0].name})
        },
        async removeTrack() { 
            
            var playlist_id = this.$store.getters["user/favorites_playlist_id"]

            console.log("TrackAddition.removeTrack() ", this.track.name, playlist_id);
            try {
                var response = await api.spotify.playlists.removeTracks(playlist_id, this.track.uri, this.$store.getters['auth/getAccessToken'])
                console.log("Response", response)
                return db.ref(`user_favorites_playlists/${this.$store.getters["user/id"]}/tracks`).child(this.track.id).set(null).then(() => { 
                    console.log(`${this.track.name} removed from User's Spotify Favorites Playlist`) 
                    Vue.set(this, "userSavedTrack", false)

                })

            }
            catch (e) {
                console.log(e);
            }
            //await this.$store.dispatch('user/unfavorite_song', this.info)
            //context.dispatch('removeSongsFromPlaylist', [[song], context.state.user.favorites_playlist.id])
            //analytics.logEvent("unfavorite", { song_name: this.info.name, song_artist: this.artist})
        },
    }
}
</script>

<style lang="scss" scoped>
    .track-addition {
        width: 100%;
        height: 100%;
    }
    .track-addition:hover {
        cursor: pointer;
    }
</style>