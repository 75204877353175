<template>
        <section :class="$isMobile() ? 'mobile-section' : 'desktop-section'">
        <div class="foreground">
            <!-- <span class="app_name">SnipitFM</span> -->
            <!-- <h1 class="artist_name"> { Version</h1> -->
            <div class="foreground-flexbox">
                <div class="bx">
                    <div class="pfp-container">
                        <img class="pfp" :class="$isMobile() ? 'pfp-mobile': 'pfp-desktop'" :src="artist_image" alt="">
                        <div class="glow"></div>
                    </div>
                    <div class="socials">
                        <img class="" src="" alt="">
                        <img class="" src="" alt="">
                        <img class="" src="" alt="">
                    </div>
                </div>
                <div class="bx">

                    <h2 class="playlists_title"> {{artist_name}}</h2>
                    <h2 class="playlists_subtitle"> Featured In:</h2>
                    <ul class="playlists">
                        <li class="playlist" @click="joinGame(playlist)"  v-for="playlist in playlists" :key="playlist.id">
                            <img class="playlist__cover" :src="playlist.images[0].url" alt="">
                            <h3 class="playlist__name">{{playlist.name.slice(0,28)}}</h3>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
        <div class="background gradient"></div>

    </section>
</template>
<script>
import '@/assets/css/profile.scss';
export default {
    name: "DesktopProfile",
    props: {
        artist_name: {
            type: String,
            required: true
        },
        artist_image: {
            type: String,
            required: true
        },
        playlists: {
            type: Array,
            required: true
        },
    },
    methods: {
        joinGame(playlist) {
            this.$emit('joinGame', playlist)
        }
    },
    components: {
      //  SearchIcon
    }
}
</script>
<style lang="scss" scoped>
.desktop-section {
    background-image: url('~@/assets/bgMay2022.png');
}
.foreground {
    top: 0;
}

.foreground-flexbox {
    display: flex;
    align-items: center;
    height: 100vh;
}
.bx {
    flex: 1;
    width: 50%;
}
.playlists {
    height: auto;
    overflow: auto;
}

.playlist {
    width: 10vw;
    height: auto
}

</style>
