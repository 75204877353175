<template>
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5.71729" y="2.81018" width="7.88211" height="7.88211" rx="2" transform="rotate(46.5 5.71729 2.81018)" fill="url(#paint0_linear_457:2344)"/>
        <rect x="12.1802" y="2.77759" width="8.31814" height="7.88211" rx="2" transform="rotate(46.5 12.1802 2.77759)" fill="url(#paint1_linear_457:2344)"/>
        <path d="M8.11521 0.363693C8.64574 -0.139767 9.48396 -0.117819 9.98743 0.412718L13.5899 4.20896C14.0934 4.73949 14.0714 5.57772 13.5409 6.08118L9.74466 5.26961V9.68367C9.21412 10.1871 8.61867 10.2142 8.11521 9.68367V8.08378L4.26994 5.83841C3.76648 5.30787 3.78843 4.46965 4.31897 3.96619L8.11521 0.363693Z" fill="url(#paint2_linear_457:2344)"/>
        <path d="M8.11521 7.31633C8.64574 6.81287 9.48396 6.83482 9.98743 7.36535L13.5899 11.1616C14.0934 11.6921 14.0714 12.5304 13.5409 13.0338L9.74466 16.6363C9.21412 17.1398 8.3759 17.1178 7.87244 16.5873L4.26994 12.791C3.76648 12.2605 3.78843 11.4223 4.31897 10.9188H8.11521V7.31633Z" fill="url(#paint3_linear_457:2344)"/>
        <defs>
        <linearGradient id="paint0_linear_457:2344" x1="9.65834" y1="2.81018" x2="9.65834" y2="10.6923" gradientUnits="userSpaceOnUse">
        <stop stop-color="#757575"/>
        <stop offset="0.521127" stop-color="#E3E3E3" stop-opacity="0.90875"/>
        <stop offset="1" stop-color="#606060" stop-opacity="0.76"/>
        </linearGradient>
        <linearGradient id="paint1_linear_457:2344" x1="16.3392" y1="2.77759" x2="16.3392" y2="10.6597" gradientUnits="userSpaceOnUse">
        <stop stop-color="#757575"/>
        <stop offset="0.380208" stop-color="#E3E3E3" stop-opacity="0.90875"/>
        <stop offset="1" stop-color="#606060" stop-opacity="0.76"/>
        </linearGradient>
        <linearGradient id="paint2_linear_457:2344" x1="8.92993" y1="0" x2="8.92993" y2="17" gradientUnits="userSpaceOnUse">
        <stop stop-color="#757575"/>
        <stop offset="0.380208" stop-color="#E3E3E3" stop-opacity="0.90875"/>
        <stop offset="1" stop-color="#606060" stop-opacity="0.76"/>
        </linearGradient>
        <linearGradient id="paint3_linear_457:2344" x1="8.92993" y1="0" x2="8.92993" y2="17" gradientUnits="userSpaceOnUse">
        <stop stop-color="#757575"/>
        <stop offset="0.380208" stop-color="#E3E3E3" stop-opacity="0.90875"/>
        <stop offset="1" stop-color="#606060" stop-opacity="0.76"/>
        </linearGradient>
        </defs>
    </svg>
</template>
<script>
export default {
    name: "GrayCoin"
}
</script>