<template>
    <div class="game_card" >
        <!-- Card Body -->
        <!-- Row 0: Playlist Image -->
        <div class="cover-img" >
            <img class="album-cover" :src="game_image" />
        </div>

        <!-- IMAGE OVERLAY: Draft Status Label (Deprecated), Admin Dropdown Menu, & Completion Checkmark -->
        <p v-if="isDraft" class="status_label">{{statusLabel}}</p>
        <dropdown-menu v-if="show_dropdown_menu" :isAdmin="isAdmin" :isDraft="isDraft" :gameOver="gameOver" @optionSelected="optionSelected" />
        <black-white-checkmark v-else-if="show_game_complete_icon" />
        <!-- IMAGE OVERLAY END -->

        <div class="card_body">
            <!-- Row 1: Playlist Name -->
            <p class="playlist_name">{{game_name}} </p>
            <!-- Row 2: Playlist Creator -->
            <p v-if="creator" class="playlist_author mt-3">created by {{creator_username}} </p>
            <!-- Row 3: Playlist Tags --> 
            <!-- <div class='mt-2'><span v-for="tag in game_tags" class="genre_tag" :key="tag" > {{tag}} </span></div> <br /> -->
            <!-- Row 4: More Info-->
            <div class="playlist_cols" v-show="false">
                <p>ENTRANCE FEE<br /> {{game.fee || "nil"}}</p>
                <p>PLAYERS <br /> {{playerCount}}</p>
            </div>
            <!-- Row 5: Show Time Remaining-->
            <div class="time_div" v-if="show_countdown">
                <span class="clock_icon mr-1">
                <clock-icon :color="'#ffffffa3'"/>
                </span>
                <p class="time_label" >Game ends in <span class="strong">{{expireDateLabel}}m</span></p>
              </div>
              <p v-else class="new_tracks_label"> New tracks!</p>
            <!-- Row 6: CTA Button -->
            <div>
                <p class="card-footer active-game" v-if="!gameIsOver(game)"> JACKPOT: {{game.tokens|| 0}} </p>
                <p class="card-footer expired-game" v-else>VOTING FINISHED</p>
            </div>
        </div>                
    </div> 
</template>
<script>
import { gameLogic } from "@/mixins";
import DropdownMenu from '@/components/GameBrowser/GameCard/DesktopDropdownMenu.vue'
// import { db } from '@/store/modules/firebase'
// import { mapGetters } from "vuex";
// import Vue from "vue";
import ClockIcon from '../../SVGs/Clock.vue';
import BlackWhiteCheckmark from '../../SVGs/BlackWhiteCheckmark.vue';


export default {
    name: "DesktopGameCard",
    props: ["game", "game_name", "game_image", "isFromSpotify", "isDraft", "creator", "isAdmin", "game_tags", "playerCount",  "show_countdown"],// "minutesToGameOver"],
    components: { DropdownMenu, ClockIcon, BlackWhiteCheckmark },       
    mounted() {
      console.log("GAME TAGS -", this.game_tags)
    },  
    mixins: [gameLogic],
    computed: {
        show_dropdown_menu() {
            return false //(this.$store.getters['user/isAdmin'] || (this.isDraft && this.$store.getters['user/id'] == this.game.created_by)) 
        },
        creator_username() { return this.creator.username ? `${this.creator.username}` : "SnipitFM" },
        showTimeLabel() { return this.game && this.game.expire_date ? true : false },
        minutesRemaining() { return this.game && this.game.expire_date ? getMinutesDiffFromNow(this.game.expire_date) : 0 },
        show_game_complete_icon() { return false },// !this.isDraft && !this.isAdmin},
        expireDateLabel() { return this.minutesRemaining },
    },
    methods: {
        optionSelected(str) {
            this.$emit("optionSelected", str)
        },
        
    }

}
function getMinutesDiffFromNow(timestamp) {
  // Get current date and time
  var now = new Date();
  
  // Convert timestamp to date object
  var date = new Date(timestamp);
  
  // Calculate the difference in minutes
  var diff = now - date;
  var diffMinutes = Math.floor(diff / 1000 / 60);
  
  return diffMinutes * -1;
}
</script>

<style lang="scss" scoped>


.card-footer {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  
  padding: 8px 16px;
  text-align: center;
  margin-top: 1rem;
  display: block;
  border-radius: 20px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  position: absolute;
  bottom: 2rem;
  width: 80%;
  left: 50%;

  transform: translateX(-50%);
}
.new-game {
    background: #2A2D35;
}
.active-game {
background: #FFFFFF;
  color: #373737; // border: 1px solid #FFFFFF;
}
.expired-game {
  background: #994444;
  border: 0;

}

.game_card {
  // height: 84%;
 // border: 1px solid #4D4D4D;
  box-sizing: border-box;
  background: #2A2D35;
  filter: drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.56));
  border-radius: 12px;
  text-align: left;
  margin-bottom: 64px;
  width: 28.66%;
  position: relative;
  padding-bottom: 2rem;
  height: 100%;
  z-index: 1;
}

.playlist_name {

  font-weight: 600;
font-size: 30px;
line-height: 36px;
  //margin-top: 12px;
  color: #FFFFFF;
}
.playlist_author {
  font-size: 12px;
line-height: 14px;
}
.playlist_cols {
  display: flex;
  justify-content: space-between;
  color: white;
  font-family: "Rubik";
  font-style: normal;
  font-size: 12px;
 // margin-top: 1rem;
}
.genre_tag {
    background: #2F2F2F;//#4b83d840;
    font-size: 12px;
    margin-right: 8px;
    border-radius: 20px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);

    padding: 4px 16px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #5B5B5B;
}
.cover-img {
  height: auto; //50%; CHANGE BACK MARKO
  //max-height: 43%;
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 47%;
}

.cover-img:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: -moz-linear-gradient(top, rgba(0,0,0,0.0) 0%, rgba(0,0,0,1.0) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.0)), color-stop(100%,rgba(0,0,0,1.0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.0) 0%,rgba(0,0,0,1.0) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0,0,0,0.0) 0%,rgba(0,0,0,1.0) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0,0,0,0.0) 0%,rgba(0,0,0,1.0) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(255,255,255,0.0) 0%,rgb(0, 0, 0) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  // background: rgba(255, 255, 255, 0.6);

}

.card_body {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 12px;
}
    

.album-cover {
  width: 100%;
  height: auto;
  border-radius: 12px;
  max-height: 275px;
}
.time_div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
}


.new_tracks_label {
  display: inline;
  font-size: 12px;
  color: #13FF2B;
  line-height: 14px;
}
.time_label {
  display: inline;
  font-size: 12px;
  color: #ffffffa3;
  line-height: 14px;
}

.status_label {
  color: white;
  background-color: rgba(0,0,0, 0.5);
  position: absolute;
  width: 100%;
  top: 0; //1rem;
  // left: 50%;
   padding: 16px 0;
  // transform: translateX(-50%);
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  z-index: 2;
  border-radius: 12px 12px 0 0;
  text-align: center;
}
.status_label:hover {
  cursor: pointer;
}
.times-circle {
  color: white;
}
.status_circle {
  content: "";
  /* create custom radiobutton appearance */
  display: flex;
  width: 15px;
  height: 15px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #000;// #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}
.clock_icon {
    display: inline;
    height: 14px;
    vertical-align: revert;
    width: auto;
}
.game_complete_icon {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 16px;
  top: 8px;
  z-index: 1;
}
.strong {
  font-weight: 900;
}



</style>