import Vue from 'vue'
import Vuex from 'vuex'
import notification from './modules/notification'
import audioPlayer from './modules/audioPlayer'
import player from './modules/player'
import firebase from './modules/firebase'
import user from './modules/user'
import auth from './modules/auth'
import context from './modules/context'
import game from './modules/game'
import gameCreation from './modules/gameCreation'
import modals from './modules/modals'
//import sampleData from '../sampleData'
import spotify from './modules/spotify'
import payment from './modules/payment'
import webHQ from './modules/webHQ'
import wyre from './modules/wyre'
import spotifyApiPlugin from '@/api/spotify/plugin';
import wyreApiPlugin from '@/api/wyre/plugin';
import { vuexfireMutations } from 'vuexfire'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0'
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion
    }
    // the rest of your getters here  
  },
  mutations: vuexfireMutations,
  actions: {
  },
  plugins: [spotifyApiPlugin, wyreApiPlugin],

  modules: {
    notification,
    player,
    audioPlayer,
    firebase,
    user,
    auth,
    context,
    game,
    gameCreation,
    modals,
    spotify,
    payment,
    webHQ,
    wyre
  }
})
