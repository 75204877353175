<template>
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0.0890198H5V1.75569H10V0.0890198ZM6.66667 10.9224H8.33333V5.92235H6.66667V10.9224ZM13.3583 5.41402L14.5417 4.23069C14.1833 3.80569 13.7917 3.40569 13.3667 3.05569L12.1833 4.23902C10.8917 3.20569 9.26667 2.58902 7.5 2.58902C3.35833 2.58902 0 5.94735 0 10.089C0 14.2307 3.35 17.589 7.5 17.589C11.65 17.589 15 14.2307 15 10.089C15 8.32235 14.3833 6.69735 13.3583 5.41402ZM7.5 15.9224C4.275 15.9224 1.66667 13.314 1.66667 10.089C1.66667 6.86402 4.275 4.25569 7.5 4.25569C10.725 4.25569 13.3333 6.86402 13.3333 10.089C13.3333 13.314 10.725 15.9224 7.5 15.9224Z" :fill="color"/>
          </svg>
</template>
<script>
export default {
    name: "ClockIcon",
    props: {
        color: {
            type: String,
            default: "#63FF34"
        }
    }
  
}
</script>