<template>
    <div class="spotify-sign-in">
        <!-- image of SpotifyLogo -->
        <div class="top">
            <img src="@/assets/SpotifyLogo170.png" alt="Spotify Logo" />
            <h1>Connect to Spotify</h1>
        </div>
        <div class="bottom">
            <button @click="signIn()" class="green-gradient">Sign In</button>
            <div class="skip">Not Now</div>
        </div>



    </div>
</template>
<script>
export default {
    name: 'SpotifySignIn',
    data() {
        return {
        }
    },
    methods: {
       signIn() {
             // await this.$store.dispatch("auth/login", {guest_email: null});
             this.$store.dispatch("auth/login", {guest_email: null});
             this.skip()
       },
       skip() {
            this.$emit('spotifySignInClosed')
       }
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
.spotify-sign-in {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    height: 70vh;
    font-family: 'Rubik';
}
h1 {
    font-family: 'Futura-Black';
    margin-top: 1rem;
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 2px;
}
.green-gradient {
    background: linear-gradient(90.11deg, #CAFF34 0.13%, #9BFF7B 99.95%);
    width: 67vw;
    height: 6.2vh;
    font-weight: 500;
    font-size: 30px;
    line-height: 32px;
    font-family: 'Rubik';
    border: 0;
    border-radius: 9px;
    color: black

}
.skip {
    text-decoration: underline;
    color: white;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 5.5vh;
}
</style>