<template>
    <div>
        <div class="card_header" v-if="showSpotifyRequiredSign"><p>Requires Spotify</p></div>
        <div class="game" :class="{pointyTopCorners: showSpotifyRequiredSign }">
            <div class="game_container">
                <div class="game__img_container"><img class="game__image" :src="game_image" />   </div>
                <div class="game__rhs">
                    <p class="game__rhs__name">{{game_name}}</p>
                    <p class="game__rhs__tags">{{game_tags}}</p>
                    <p class="game__rhs__tokens">{{game_tokens}} Tokens</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "MobileGameCard",
    props: ["game", "game_tags", "playerCount", "game_name", "game_image", "isFromSpotify"],
    computed: {
     //   game_tags_str() { return this.game_tags.length > 0 ? this.game_tags.join(' • ') : "" },
        game_tokens() { return this.isFromSpotify ? 0 : this.game.tokens },
        spotifyAccountConnected() { return this.$store.getters['user/id'] != null},
        userIsPremium() { return this.$store.getters['user/premium'] },
        showSpotifyRequiredSign() { return this.isFromSpotify && !this.userIsPremium }
    }
}
</script>

<style lang="scss" scoped>

.card_header {
    background: #CAFF34;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    border-radius: .5rem .5rem 0 0;
    height: 3.6vh;
    display: flex;
    align-items: center;
    justify-content: center;;
}
.pointyCornerTops {
    border-radius: 0 0 8px 8px;
}
.game_container {
    margin-left: 5.3%;
    margin-top: 32px;
    margin-right: 5.3%;// 14.2%;
    display: flex;
    gap: 1.25rem;

    width: 100%;
    height: 100%;
}
.game {
    display: flex;
    width: 100%;
    height: 100%;
    background: #4D5045;// #353535;
    border-radius: .5rem;
  //  padding: 28px 14.2%;
   // padding-right: 14.2%;
   // padding-left: 9.5%;
    &__img_container {
        perspective: 6.625rem;
      //  padding-top: 0.25rem;
        margin-right: -0.25rem;
        margin-bottom: -0.25rem;
//        padding-left: 0.2rem; // 2
       // margin-left: -1rem; //-16 = -5.3% + 9.5% =
//        margin-top: 28px;


    }
    &__image {
        width: 20vw;
        height: auto;
        transform: rotateY(10deg);
    }
    &__rhs {
  //      display: flex;
//        flex-direction: column;
        width: 58%;
       // justify-content: space-between;
        &__name {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            //white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__tags {
            color: #B0B0B0;
            font-weight: 400;
            font-size: 10px;
        }
        &__tokens {
            font-size: 12px;
            line-height: 14px;
            color: white;
            background: #1F1F1F;
            border-radius: 100px;
            text-align: center;
            padding-top: 1px;
            padding-bottom: 1px;
            margin-top: 10.5%;
        }
    }
}
</style>