<template>
    <div class="search-container">
        <SearchIcon class="search__icon" :opacity="search_opacity"/>
        <transition name="fade" mode="out-in">
            <p v-if="(query.length == 0)" class="placeholder" v-bind:key="placeholder"> {{placeholder}}</p>
        </transition>
        <input  v-if="true"
                type="text"
                class="search__input"
                ref="searchMusic"
                v-model="query"
                @keyup.enter.prevent="search">    
    </div>
</template>
<script>
import SearchIcon from '@/components/SVGs/Search.vue'
import api from '@/api';
import { mapGetters } from 'vuex';

export default {
    name: "Search",
    props: ['PLACEHOLDER'],
    data() {
        return {
            search_opacity: 0.5,
            // credit to GPT3 for these 🔥 placeholders
            placeholders: [  "Search for music if you're feeling lucky",
                                "What song would set your soul on fire",
                                "Which melody would make your heart sing",
                            // "Set the vibe here.",
                            // "What's the vibe",
                                "Have you seen the options below?",
                                "What beat would get your body moving",
                                //"Which artist will become your new favorite",
                                "Which artist are you feeling right now",
                                //"What genre will fit your mood",
                                //"What genre fancies your liking",
                                "What genre are you feeling right now",
                                "Which lyrics would speak to your soul",
                                "What song is the soundtrack to your life",
                                "Which tune would make your day brighter",
                                "What music would elevate your mood",
                                "Which rhythm would get you grooving"],
            placeholder_index: 0,
        }
    },
    mounted() {
     //   const the = this
      //  this.placeholder_index = Math.floor(Math.random() * this.placeholders.length)
        // setInterval(() => {
        //   if (the.query.length == 0) {
        //       // pick a random index in the placeholders array
        //       the.placeholder_index = (the.placeholder_index + 1) % the.placeholders.length
        //   }
        // }, 6000)
    },
    components: {
        SearchIcon,
    },
    computed: {
      placeholder() { return this.PLACEHOLDER || this.placeholders[this.placeholder_index] },
      query: {
        get () {
          return this.QUERY
        },
        set (value) {
          this.$store.commit('spotify/setQuery', value)
        }
      },
      ...mapGetters({
        QUERY: 'spotify/getQuery'
      })
    },
    methods: {
       /*
        SearchModal.start() calls api.spotify.search() to retrieve the playlists that match the query (@TODO Fail-State needed)
        SearchModal.start() calls store.game.createGameFromPlaylists() to create the game and change games (@TODO Fail-State Needed)
        */
        async search() {
            console.log(`SearchModal.start() called`, this.query)
            //this.showLoadingIcon() 
            // var response = await api.spotify.search.search(this.query)
            // var playlists = response.data.playlists?.items
            // // generate a random index between 0 and 10
            // var randIndex = getRandomInt(0, 10)
            // var playlist = playlists[randIndex]
            // console.log("SearchModal.start() random index - ", randIndex, playlist, playlists.map(playlist => [playlist.name, playlist.external_urls.spotify]))
            // playlist.name = `Search: ${this.query}`
            this.$emit('start', this.query)



        },
    }
}
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
</script>
<style lang="scss" scoped>
.search-container {
  position: relative;
}
.search-container {
  display: flex;
  align-items: center;
  gap: 3.5%;
  padding: 10px 8px 10px 8px;
  width: 100%;
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  margin: 0 auto;
  &__icon {
    width: 16px;
    height: 16px;
  }
  &__input {
    background: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.95);
    font-weight: 700;
    width: 100%;

  }
  &__input:focus {
    outline: none;
  }
}
.placeholder {

  &__icon {
    width: 64px;
    height: 64px;
  }
  &__text {
    font-weight: 700;
    font-size: 16px;
//    color: rgba(255, 255, 255, 0.5);
    color: #464545;
    margin-top: 1rem;
  }
}
.placeholder, input {
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: transparent;
  border: 0;
  text-align: left;
//  color: rgba(255, 255, 255, 0.5);
  color: #464545;
  font-weight: 700;
}
// hide input focus outline
input:focus {
  outline: none;
}
.fade-enter-active {
  transition: opacity 1.5s;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>