<template>

    <li @click="setCategory(`${category_id}`)"  >
            <a><span>{{category_name}}</span></a>
    </li>

</template>

<script>
import { db} from '@/store/modules/firebase';
export default {
    name: "DesktopCategoryItem",
    data() {
        return {
///            category: {}
        }
    },

    props: ["category"],
    mounted() {
        console.log("MOUNTED - CategoryItem2: ", this.category_id)
        if (this.category_id != "community") {
         //   this.$bind('category', db.ref('categories').child(this.category_id))
        } else {
            this.category = {name: "Community", id: "community"}
        }
    },
    computed: {
        category_name() { return this.category_id == "community" ? "Community" : this.category.name },
        category_id() { return this.category ? this.category.id : "community" },

        isEditButton() { return this.category_id == "edit"}
    },
    methods: {
        setCategory() {  
            this.$emit("setCategory", this.category)
        },
        goToEdit() {
            this.$emit("goToEdit")
        }
    }
}
</script>

<style lang="scss" scoped>
.tabs.is-toggle {
    a {
        border-color: #DDD;
        color: #FFF;
        border-style: solid;
        border-width: 1px;
        margin-bottom: 0;
        position: relative;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
    }
    a:hover {
        background-color: #DDD;
        color: black;
        border-color: #b5b5b5;
        z-index: 2;
    }
    li.is-active a {
        // background-color: #DDD;
        border-color: #DDD;
        // color: #1f1f1f;
        color: white;
        background-color: gray;
       // border-color: gray;
        z-index: 1;
        font-size: 18px;
        line-height: 21px;
    }
     li:last-child a {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
    li:first-child a {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
}
</style>