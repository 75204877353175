<template>
    <div>
        <div class="leaderboard">
            <Item class="leaderboard__row" 
                                            :tracks="ordered_tracks_all_users" 
                                            :header="true"/>
            <div class="leaderboard__list hide-scrollbar" v-if="showLeaderboard">
                <Item class="leaderboard__row"  v-for="(tokens, playerID) in sorted_players"    :key="playerID" 
                                                                                                :tracks="ordered_tracks_all_users" 
                                                                                                :fee="game.fee" 
                                                                                                :user_id="playerID" 
                                                                                                :_reward="tokens" 
                                                                                                :header="false"/>
                            
            </div>
            <div class="leaderboard__empty" v-else>
                <p><em>Voting Leaderboards will appear here when the voting expires.</em></p>
            </div>
        </div>
    </div>
</template>

<script>
import { gameLogic } from "@/mixins";
import Item from '@/components/GameComplete/Leaderboard/LeaderboardItem.vue'
import '@/assets/css/main.scss';
import LeaderboardHeader from '../../SVGs/LeaderboardHeader.vue';

export default {
    name: "Leaderboard",
    props: ["game", "tracks", "ordered_tracks_all_users", "ordered_tracks_current_users"],
    components: { Item },
    mixins: [gameLogic],
    computed: {
        sorted_players() {
            return this.game ? this.game.players : []
            // return Object
            //         .entries(this.game.allowed_copy)
            //         .sort((a, b) => a[1] - b[1])
            //         .reduce((_sortedObj, [k,v]) => ({
            //         ..._sortedObj, 
            //         [k]: v
            //         }), {})
        },
        showLeaderboard() {
            return (this.game && this.gameIsOver(this.game)) || true
        }
    },
    methods: {
        albumImageForTrack(track) {
            return track && track.item ? track.item.album.images[0].url : ""
        }
    }
}
</script>

<style lang="scss" scoped>
// .header {
//     width: 66%;
//     height: 14.4%;
// }

.leaderboard {
    height: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    &__list {
        overflow: scroll;
        height: 100%;
        margin-top: 7.2%
    }
    &__row {
        // margin-left: 10.4%;
        // margin-right: 15.6%;
    } 
    &__empty {
        color: white;
        margin-top: 3rem;
    }
}

</style>