<template>
  <div class="app-layout">
      <NavBar class="navigation-bar" v-if="show_navbar()" />
      <div class="app-layout__wrap">
          <div class="app-layout__main">
            <router-view :class="routerView" :style="{'height': routerViewHeight()}">
              <transition v-slot="{ Component }" name="fade">
                <component :is="Component" />
              </transition>
            </router-view>
          </div>
      </div>
      <Footer class="footer-bar" v-if="show_footer()" />
  </div>
</template>

<script>
import NavBar from '@/components/Layout/NavBar'
import Footer from '@/components/Layout/Footer'
import { mapGetters } from "vuex";

export default {
    name: "AppLayout",
    
    data() {
      return {
        routerView: "router-view",
      }
    },
    components: {
        NavBar,
        Footer
    },

    computed: {
      is_logged_in: function() { return !this.$store.getters["user/is_logged_out"] },
      ...mapGetters({
      })
    },
    mounted() {

    },
    methods: {
      // method to add nubmers
    
      
      routerViewHeight() {
//        if (this.$route.name == "ProfilePage") { return "100vh" } 
        var height = 100;
        if (this.show_navbar()) { height -= 10 }
        if (this.show_footer()) { height -= 10 }
        // console.log("routerViewHeight", this.show_navbar(), this.show_footer(), height)
        return `${height}vh`
      },
      show_navbar() {
        if (this.$isMobile()) { 
          // if (this.$store.getters["modals/genreModalVisible"] || this.$store.getters["modals/streamingModalVisible"]) { return false }
          // if (this.$route.name == "Poll") { return false }
          return false
        }
        
        return this.$route.name == "Login" 
                                   || this.$route.name == "Poll"
                                   || this.$route.name == "Browser"
                                   || (this.$route.name == "GameComplete" && !this.$isMobile())
                                   || this.$route.name == "ConfirmationPage"
                                   || this.$route.name == "ProfilePage"
                                   || this.$route.name == "Streaming"
      },
      show_footer() {
        if (this.$isMobile()) { 
          if (this.$store.getters["modals/genreModalVisible"] || this.$store.getters["modals/streamingModalVisible"]) { return false }
        }
        
        return this.$route.name == "Login" || this.$route.name == "Streaming"
                        || (this.$route.name == "Poll" && !this.$isMobile())
                        || (this.$route.name == "GameComplete" && !this.$isMobile())

      }
    }
}
</script>
<style lang="scss" scoped>
.app-layout {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    max-width: 100%;
    background-color: #1f1f1f;
    &__wrap {
      display: flex;
      flex-flow: row;
      flex-grow: 1;
    }

    &__main {
      display: grid;
      flex-grow: 1;
    //   max-height: calc(100vh - 200px);
      overflow-y: auto;
    }
}
.navigation-bar {
    height: 10vh; //76px;
    width: 100%;
}
.footer-bar {
    min-height: 10vh; //12vh;//94px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: env(safe-area-inset-bottom);

}
.router-view {
    width: 100%;
    height: 80vh;//78vh;//100%;
    padding-bottom: env(safe-area-inset-bottom);

}


.tv-off-enter-active {
    transition: all 0.5s;
  }
  .tv-off-leave-active {
    transition: all 0.5s;
  }
  .tv-off-enter, .tv-off-leave-to {
    opacity: 0;
    transform: scale(0.75);
  }

</style>