import axios from "axios";

//@todo move baseURL to config file

const baseURL = "https://snipitfm-backend.herokuapp.com/"
    // process.env.NODE_ENV === "production"
    //     ? "https://snipitfm-backend.herokuapp.com/"
    //     : "http://localhost:5000/";

const request = axios.create({
    baseURL
});

export default request;
