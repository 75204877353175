<template>
   <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="22.5" cy="23" rx="22.5" ry="23" fill="black" fill-opacity="0.8"/>
        <path d="M18.4911 32.2766L10.3661 24.1516C9.87797 23.6635 9.87797 22.8721 10.3661 22.3839L12.1338 20.6161C12.622 20.1279 13.4135 20.1279 13.9016 20.6161L19.375 26.0894L31.0984 14.3661C31.5865 13.878 32.378 13.878 32.8662 14.3661L34.6339 16.1339C35.122 16.622 35.122 17.4135 34.6339 17.9016L20.2589 32.2767C19.7707 32.7648 18.9792 32.7648 18.4911 32.2766V32.2766Z" fill="white"/>
    </svg>
</template>
<script>
export default {
    name: "BlackWhiteCheckmark"
}
</script>