import api from '@/api'
const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
    async findOrCreateUserWallet(context) {

    },
    async makeWallet(context, payload) {
        const wallet_id = payload.id
        return await api.wyre.createUserWallet(wallet_id)
        
    },
    async getWalletBalance(context, payload) {
        console.log("wyre/getWalletBalance", payload)

        const wallet_id = payload.id
        return await api.wyre.getWalletBalance(wallet_id)
    }
};



const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default module;
