import request from './../request'

export default {
    getCurrentPlayback() {
        return request.get("me/player");
    },
    getUserDevices() {
        return request.get("me/player/devices")
    },
    
    /*
        Response
        A completed request will return a 204 NO CONTENT response code, and then issue the command to the player. 
        Due to the asynchronous nature of the issuance of the command, you should use 
        the Get Information About The User’s Current Playback endpoint to check that your issued command was handled correctly by the player.

        If the device is not found, the request will return 404 NOT FOUND response code.

        If the user making the request is non-premium, a 403 FORBIDDEN response code will be returned.
        
        = Valid contexts are albums, artists & playlists.
        Pass tracks into URIS, albsums artists and playlists into context_uri

    */
    play(uris) {
        console.log("Spotify.player.play() called ");
        return request({
            method: "put",
            url: `/me/player/play`,
            data: {
                uris
            }
        })
    },
    pause(device_id) {
        return request.put("/me/player/pause", {
            device_id
        });
    },
    volume(volume_percent, device_id) {
        return request({
            method: "put",
            url: "/me/player/volume",
            params: {
                volume_percent,
                device_id
            }
        })
    },
    seekToPosition(position_ms, device_id) {
        return request({
            method: "put",
            url: "/me/player/seek",
            params: {
                position_ms,
                device_id
            }
        })
    },
    nextTrack(device_id) {
        return request.post("/me/player/next", {
            device_id
        })
    },
    previousTrack(device_id){
        return request.post("/me/player/previous", {
            device_id
        })
    },
    shuffle(state, device_id) {
        return request({
            method: "put",
            url: "/me/player/shuffle",
            params: {
                state: !state,
                device_id: device_id
            }
        });
    },

    repeat(state, device_id) {
        return request({
            method: "put",
            url: "/me/player/repeat",
            params: {
                state,
                device_id
            }
        });
    },
    transferUsersPlayback(device_ids, play = false) {
        return request.put("me/player", {
            device_ids,
            play
        });
    }

}