<template>
    <section>
        <p>New polls in {{ timer }}</p>
        <p class="gray">~~~~~~ OR ~~~~~~</p>
        <div class="skip">
            <p class="gray">Skip the wait</p>
            <down-arrow />
        </div>
        <div class="btn">
            <img src="@/assets/appleMessages_icon.png" alt="">

            <p>Invite a Friend</p></div>

    </section>
</template>
<script>
import DownArrow from '../../SVGs/DownArrow.vue'
export default {
    name: 'InviteFriends',
    components: { DownArrow },
    data() {
        return {
            timer: "00:00"
        }
    },

}
</script>
<style lang="scss" scoped>

section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}
.gray {
    color: rgba(246, 246, 249, 0.53);
}
.btn {
    display: flex;
    background: white;
    color: black;
    gap: 8px;
    padding: 4px 16px;
    border-radius: 16px;
    font-weight: 500;
}
.skip {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    margin-bottom: 16px;
}
</style>