<template>
      <section>
        <div class="body2">
            <div class="browse_page" v-if="true">
                <div class="game-grid" >
                      <GameCard :class="{pointer: true}" 
                                v-for="(playlist, index) in playlists" 
                                :style="{'z-index': (pageSize - (index % pageSize))}" 
                                class="card2"
                                :key="playlist.id" 
                                :playlist="playlist" 
                                :isDraft="false"  
                                @click.native="selectItem(playlist)" 
                                @unselectItem="unselectItem()"/>                   
                </div>
                <div class="page_buttons">
                      <button class="green_gradient page_button" @click="prevPage()" v-if="showPrevPageBtn">Newer Games</button>
                      <button class="green_gradient page_button"  @click="nextPage()" v-if="showNextPageBtn">Older Games</button>
                </div>
            </div>

        </div>

      </section>
  
</template>

<script>
import GameCard from '@/components/GameBrowser/GameCard'
export default {
    name: "DesktopGameBrowser",
    props: ["playlists", "pageSize", "showNextPageBtn", "showPrevPageBtn"],
    components: {GameCard},

    methods: {
        selectItem(item) {
            this.$emit("gameSelected", item)

        },
    },
    computed: {

    }
}
</script>
<style lang="scss" scoped>
.body2 {
    display: flex;
    height: 100%;
    width: 87%;
    margin: 0 auto;
    padding-left: 36px;
    
    padding-right: 36px;
}
.browse_page {
  width: 100%;
  height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.game-grid {
  font-family: "Rubik";
  font-style: normal;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content:  flex-start;//start; //space-between;
  flex-wrap: wrap;
  gap: 7%; //3%
  height: 100%; 
}
.card2 {
  height: 54vh;
  z-index: 1;
}
.card2:hover {
    cursor: pointer;
}

.page_buttons {
  margin: 0 auto;
  margin-top: -32px;
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.page_button {
    border-radius: 8px;
    color: black;
    font-weight: 500;
    height: 41px;
    padding-left: 24px;
    padding-right: 24px;
    border: 0;
}
.page_button:hover {
  cursor: pointer;
}
 .green_gradient {
  background: linear-gradient(90.11deg, #CAFF34 0.13%, #9BFF7B 99.95%);
}

</style>