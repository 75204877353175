import axios from "axios";
import request from "./request";

export default {
	async createWalletOrderReservation() {
		console.log("createWalletOrderReservation()");
		const response = await request.get(`http://localhost:8089/create_wyre_reservation`);
		console.log("createWalletOrderReservation() response", response);
		if (response.status == 200) {
			const reservation = response["data"]["message"]["reservation"];

			return reservation;
		} else {
			console.log(response);

			return {};
		}
	},
	async getWalletBalance(userId) {
		console.log("api/wyre/getWalletBalance", userId);
		// Send current user ID
		return request.get("http://localhost:8089/balance", {
			params: {
				walletId: userId,
			},
        })
	},	
	async createUserWallet(userId) {
		// Send current user ID
		return axios.post("http://localhost:8089/create_user_wallet", {
			userId: userId,
        })
	},
	async createPlaylistWallet(playlistId) {
		axios.post("http://localhost:8089/create_playlist_wallet", {
			playlistId: playlistId,
		}).then(function (response) {
			console.log(response);
		}).catch(function (error) {
			console.log(error);
		});
		
		// const response = await request.post("http://localhost:8089/create_playlist_wallet", {
		// 	playlistId: playlistId
		// });
		//
		// if (response.status == 200) {
		// 	const clientResponse = response["data"]["message"];
		//
		// 	// clientResponse["message"]["id"] is the new wallet ID.
		// 	// Store that in firebase
		//
		// 	return clientResponse;
		// } else {
		// 	console.log(response);
		//
		// 	return {};
		// }
	},
	async transferFromUserToPlaylist(userId, playlistId, amount) {
		// Need to implement getFromFirebase here
		var srcWalletId = getFromFirebase(userId, "user")
		var destWalletId = getFromFirebase(playlistId, "playlist")

		const balanceResp = await request.post("http://localhost:8089/balance", {
			walletId: srcWalletId
		});

		if (balanceResp.status == 200) {
			const balance = balanceResp["data"]["balance"];

			if (amount > balance) {
				console.log("insufficient balance")
				return {};
			}

			const response = await request.post("http://localhost:8089/transfer_wallets", {
				srcWalletId: srcWalletId,
				destWalletId: destWalletId,
				amount: amount
			});

			if (response.status == 200) {
				const clientResponse = response["data"]["message"];

				return clientResponse;
			} else {
				console.log(response);

				return {};
			}

			//return clientResponse;
		} else {
			console.log(balanceResp);

			return {};
		}
	}
};

function getFromFirebase(id, node) {
	return
}
		// }).then(function (response) {
		// 	console.log(response);
		// }).catch(function (error) {
		// 	console.log(error);
		// });

		// if (response.status == 200) {
		// 	const clientResponse = response["data"]["message"];
		//
		// 	return clientResponse;
		// } else {
		// 	console.log(response);
		//
		// 	return {};
		// }