<template>
  <div class="photo-input">
    <img
      :src="sassyPanda"
      alt="default profile"
      class="image-preview"
      :class="{'not-selected': this.previewImage == null}"
      @click="selectImage(sassyPanda)"
    />
    <label for="file-input" class="file-input-label">
      <img
        :src="sassyCamera"
        alt="default profile"
        class="image-preview"
        :class="{'not-selected': this.previewImage != null}"
      />
    </label>
    <input
      type="file"
      id="file-input"
      @change="handleImageSelection"
      accept="image/*"
      hidden
    />
    <img
      :src="sassyDog"
      alt="default profile"
      class="image-preview"
      :class="{'not-selected': this.previewImage == null}"
      @click="selectImage(sassyDog)"
    />


    <div v-if="previewImage">
      <img :src="previewImage" alt="preview" class="image-preview" />
    </div>
  </div>
</template>

<script>
import { db } from 'firebase/app';

export default {
  name: 'PhotoUpload',
  data() {
    return {
      sassyPanda: require('@/assets/sassyPanda.png'),
      sassyDog: require('@/assets/sassyDog.png'),
      sassyNarwhal: require('@/assets/sassyNarwhal.png'),
      sassyCamera: require('@/assets/sassyCamera.png'),
      previewImage: null,
      selectedImage: null,
    };
  },
  methods: {
    handleImageSelection(event) {
      const file = event.target.files[0];

      if (!file) return;

      this.previewSelectedImage(file);
      this.uploadImage(file);
    },
    previewSelectedImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    async uploadImage(file) {
      console.log('uploading image');
      // Upload image implementation
    },
    selectImage(imageSrc) {
      this.selectedImage = imageSrc;
    },
  },
};
</script>
  
  <style scoped>
  .photo-input {
    display: flex;
    align-items: center;
  }
  
  .file-input-label {
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    cursor: pointer;
    gap: 1.5rem;
  }
  
  .upload-icon {
    margin-left: 10px;
  }
  .not-selected {
    opacity: 30%;
    filter: blur(2px);
  }
  
  .image-preview {
    width: 100px;
    height: auto;
    border-radius: 50%;
  }
  </style>