import firebase from 'firebase/compat/app'
import "firebase/compat/analytics";
import "firebase/compat/auth";
import 'firebase/compat/database'

//import { getAuth, RecaptchaVerifier } from "firebase/auth";

var firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_FIREBASE_APPID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENTID
};


var fb = firebase.initializeApp(firebaseConfig)

export const db = fb.database()
export const analytics = fb.analytics()
export const auth = fb.auth()

const getters = {
    games_ref: () => { return db.ref("games") },
    playlists_ref: () => { return db.ref("playlists") },
    users_ref: () => { return db.ref("users") },
    tracks_ref: () => { return db.ref("tracks") },
    categories_ref: () => { return db.ref("categories") },
    current_poll_ref: (state, getters, rootState, rootGetters) => { return db.ref(`polls/${rootGetters["context/cPoll_id"]}/${rootGetters["context/cGame_id"]}`) },
    currentUserRef: (state, getters, rootState, rootGetters) => { return db.ref(`users/${rootGetters["user/id"]}`) },
    /* 
        User Progress stores the polls generated for each member 
            Each user gets the same first 8 polls, but the remaining 8 are generated based on their initial votes
        User progress also stores the index in their array of polls 
            ie if user has voted 3 times, their poll_idnex is 2
            their poll_index is -1 to indicate they completed the game
            their poll_index is 0 to indicate they have not voted yet
    */
   user_progress_ref: (state, getters, rootState, rootGetters) => {
       if (rootGetters['user/id']) {
           return  db.ref(`user_progress/${rootGetters['user/id']}`)
       } else { return null }
   },
//    user_progress_ref: (state, getters, rootState, rootGetters) => {
//        if (rootGetters['user/id']) {
//            return  db.ref(`user_progress/${rootGetters['user/id']}/active_games`)
//        } else { return null }
//    },
    // game_progress_ref: (state, getters, rootState, rootGetters) => {
    //     var game_id = rootGetters['context/cGame_id']
    //     if (rootGetters['user/user_progress'] && game_id) {
    //         var game_is_complete = rootGetters['user/user_progress'].completed_games ? rootGetters['user/user_progress'].completed_games[`${game_id}`] : false
    //         var game_is_active = rootGetters['user/user_progress'].active_games ? rootGetters['user/user_progress'].active_games[`${game_id}`] : false
    //         if (game_is_complete) {
    //             console.log("game_progress_ref: game is complete")
    //             return getters['user_progress_ref'].child("completed_games").child(`${game_id}`)
    //         } else if (game_is_active) {
    //             console.log("game_progress_ref: game is active")
    //             return getters['user_progress_ref'].child("active_games").child(`${game_id}`)
    //         } else {
    //             console.log("game_progress_ref: game is not active or complete")
    //             return null
    //         }
    //     } else { 
    //         console.log("game_progress_ref: no user progress or game id")
    //         return null 
    //     }
    // },
    game_progress_ref: (state, getters, rootState, rootGetters) => {
        var game_id = rootGetters['context/cGame_id']
        var user_progress = rootGetters['user/user_progress']
        console.log("game_progress_ref: user_progress", user_progress, game_id)
        if (!(user_progress && game_id)) { 
            console.log("firebase/game_progress_ref: no user progress or game id")
            return null 
        }
        if (user_progress.completed_games && game_id in user_progress.completed_games) {
            console.log("game_progress_ref: game is complete")
            return getters['user_progress_ref'].child("completed_games").child(`${game_id}`)
        } else if (user_progress.active_games && game_id in user_progress.active_games) {
            console.log("game_progress_ref: game is active")
            return getters['user_progress_ref'].child("active_games").child(`${game_id}`)
        } else {
            console.log("game_progress_ref: player has not started game")
            
            return null
        }
        
    },
            
//             var active_games_ref = db.ref(`user_progress/${rootGetters['user/id']}/active_games`)

//             return getters['user_progress_ref'].child("active_games").child(`${rootGetters['context/cGame_id']}`)
// //            return rootState.context.preview_mode ? null : db.ref(`user_progress/${rootGetters['user/id']}/active_games/${rootGetters['context/cGame_id']}`)

    user_active_games_ref: (state, getters, rootState, rootGetters) => {
        console.log("user_active_games_ref", rootGetters['user/id'])
        if (rootGetters['user/id']) {
            return getters['user_progress_ref'].child('active_games')
        } else { 
            console.log("user_active_games_ref: no user id")
            return null }
    },
    user_completed_games_ref: (state, getters, rootState, rootGetters) => {
        if (rootGetters['user/id']) {
            return  getters['user_progress_ref'].child('completed_games')
        } else { return null }
    },

    /*
        Game Results indexes the votes by track -> user.
        "user" in this case is a key-value pair: timestamp of their vote -> true
        ie game_results/gameA/
            trackA
                userA
                    timestampA: true
                    timestampA+10: true
                userB
                    timestampA+5: true
                ...
    */
    game_results_ref: (state, getters, rootState, rootGetters) => { return db.ref(`game_results/${rootGetters['context/cGame_id']}/tracks`) },
    vote_queue_ref: (state, getters, rootState, rootGetters) => {
        return (rootGetters["user/id"] && rootGetters['context/cGame_id']) ? db.ref(`user_progress/${rootGetters["user/id"]}/active_games/${rootGetters['context/cGame_id']}/vote_queue`) : null
    },
}

const actions = {
    /**
     * Summary: Registers the user's vote with the backend
     * 
     * Description: The database is updated in 2 nodes
     *      1. /poll/:poll_id/:game_id/:song_id contains {user_id: date_timestamp} 
     *      2. /game_results/:game_id/tracks/:song_id/voters/:user_id  contains  {date_timestamp: true}
     * 
     * @param  {[String]} poll_id poll id
     * @param  {String} song_id  song id
     * @param  {String} user_id user id
     * @param  {String} game_id game id
     * 
     */
    async newVote(context, { poll_id, song_id, user_id, game_id }) {
        console.log("firebase.newVote()", poll_id, song_id, user_id, game_id)
        var date = new Date().valueOf()

        //Create the vote records for both locations
        var voteRecordForPollRef = {}
        voteRecordForPollRef[`${user_id}`] = date
        var voteRecordForGameRef = {}
        voteRecordForGameRef[date] = true
        // Update firebase with new votes
        await db.ref(`polls/${poll_id}/${game_id}/${song_id}`).update(voteRecordForPollRef)
        await db.ref(`game_results/${game_id}/tracks/${song_id}/voters/${user_id}/`).update(voteRecordForGameRef)
    },
    async newGame(context, { game }) {
        const game_id = game.id || game.game_id
        // Dispatch the newTracks action with the updated tracks
       // context.dispatch('newTracks', { tracks: game.tracks });

        // Replace each track object with its ID in the game.tracks object
        // const updatedTracks = {};
        // Object.entries(game.tracks).forEach(([trackId, _]) => {
        //   updatedTracks[trackId] = true;
        // });
//        game.tracks = updatedTracks; TO:
        const newGameRef = context.getters['games_ref'].child(`${game_id}`)
        console.log("firebase.newGame()", game.tracks)
        // Save the updated game object to Firebase Realtime Database
        await newGameRef.update(game);
      
      },
    async newTracks(context, { tracks }) {
        const tracks_arr = Array.isArray(tracks) ? tracks : Object.values(tracks).map((track) => track.item)
        console.log("firebase.newTracks()", tracks_arr)
        tracks_arr.forEach((track) => {
            console.log("TRACK", track)
            if (track) {
                delete track.disc_number
                delete track.explicit
                delete track.episode
                delete track.track
                delete track.href
                delete track.external_ids
                delete track.external_urls
                delete track.type
                track.score = 1400
            }
        })
        var TRACKS = tracks_arr
        console.log("firebase.newTracks()", tracks, TRACKS , context.getters.tracks_ref)

        // If tracks is an array, convert to object
        if (Array.isArray(TRACKS)) {
            var tracks_obj = {}
            TRACKS.forEach((t) => {
                tracks_obj[t.id] = t
            })
        }
        await context.getters.tracks_ref.update(tracks_obj)
    },
    updateVoteQueue(context, { vote, game_id }) {
        var targetGameID = game_id || context.rootGetters['context/cGame_id']
        console.log("firebase.updateVoteQueue()", vote, targetGameID, context.rootGetters['context/cGame_id'], game_id)
        var ref = db.ref(`user_progress/${vote.voted_by}/active_games/${targetGameID}/vote_queue`)
        console.log("obj: ", vote)
        ref.update({ [vote.voted_at]: vote })
    },
    addGameToCompletedGames(context, { game_id }) {
        var user_id = context.rootGetters['user/id'] 
        if (user_id) {
            var ref = db.ref(`user_progress/${user_id}/completed_games/${game_id}`)
            var timeString = new Date().toISOString()
            ref.set(timeString)
        } else {
           console.log("No user id found")
        }
    },
    emptyVoteQueue(context) {
        console.log("EmptyVoteQueue()")
        context.getters.vote_queue_ref?.remove()
    },
    // async randomPlaylist(context, { genre }) {
    //     console.log("firebase.randomPlaylist()", genre)
    //     var genre_key = genre
    //     var ref = db.ref(`playlists/${genre_key}`)
    //     await ref.once('value', (snapshot) => {
    //         console.log("snapshot", snapshot.val())
    //         // Convert the snapshot into an array of objects
    //         const itemsArray = Object.values(snapshot.val());

    //         // Choose a random index from the array
    //         const randomIndex = Math.floor(Math.random() * itemsArray.length);

    //         // Get the item at the random index
    //         const randomItem = itemsArray[randomIndex];

    //         console.log('Random item:', randomItem);
    //         return randomItem
    //     });
        
    // },
    async randomPlaylist(context, { genre }) {
        console.log("firebase.randomPlaylist()", genre);
        const genre_key = genre;
        const ref = db.ref(`playlists/${genre_key}`);
    
        try {
            const snapshot = await ref.once('value');
            const itemsArray = Object.values(snapshot.val());
            const randomIndex = Math.floor(Math.random() * itemsArray.length);
            const randomItem = itemsArray[randomIndex];
            console.log('Random item:', randomItem);
            return randomItem;
        } catch (error) {
            console.error(error);
            return null;
        }
    },

    async getRandomTracks(context, {playlist} ) {
        console.log("firebase.getRandomTracks()", playlist)
        const ref = db.ref(`tracks`);
        const trackIds = Object.keys(playlist.tracks)
        const randomTrackIds = shuffle(trackIds).slice(0, 8)
        console.log("firebase.getRandomTracks()", trackIds, randomTrackIds)
        const trackSnapshots = await Promise.all(randomTrackIds.map(trackId => ref.child(trackId).once('value')))
        console.log("trackSnapshots", trackSnapshots, trackSnapshots[0].val())
        const tracks = trackSnapshots.map(snapshot => snapshot.val())
        console.log("tracks", tracks)
        return tracks.filter(track => track !== null)
      },
    updatePlaylistCategories(context, { categories }) {
        console.log("firebase.updatePlaylistCategories()", context.rootGetters['user/id'])
        if (context.rootGetters['user/id']) {
            context.getters.users_ref.child(context.rootGetters['user/id']).update({ categories })
        }
    },
    updatePlaylistWithGameID(context, { playlist_id, game_id, category_key }) {
        console.log("firebase.updatePlaylistWithGameID()", playlist_id, game_id, category_key)
        context.getters.playlists_ref.child(`${category_key}`).child(`${playlist_id}`).update({"game_id": game_id })
    },
    addGameToPlaylistIndex(context, {game}) { 
        console.log("firebase.addGameToPlaylistIndex()", game)
        if (game){
            db.ref('playlist_games_mapping').child(game.playlist_id).child(game.id).set(game.end_date || false)
        } else {
            console.log("firebase.addGameToPlaylistIndex() - game is null")
        }
    },
}
function shuffle(array) {
    let currentIndex = array.length
    let temporaryValue, randomIndex
  
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }
  
    return array
  }
export default {
    namespaced: true,
    getters,
    actions,
}