import { randomBytes } from "crypto";
import { providers, Wallet, utils as etherUtils } from "ethers";

import { makeProvider } from "@/api/payments/walletconnect";


export default {
    async resetState() {
        console.log("reset");

        this.$store.commit("SET_CONNECTION_STATUS", false);
        this.$store.commit("SET_WALLET_ADDRESS", "");
        this.$store.commit("SET_CUSTODIAL_WALLET_ADDRESS", "");
        this.$store.commit("SET_PROVIDER_OBJ", null);
    },
    async connectWallet() {
        console.log("Attempting wallet connection...");
        var provider = null;
        try {
            if (window.ethereum) {
                console.log("Using Metamask");
                provider = window.ethereum;
            } else {
                console.log("Using WalletConnect");
                provider = makeProvider();
            }

            provider.on("disconnect", (code, reason) => {
                this.resetState();
            });

            provider.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    this.$store.commit("SET_WALLET_ADDRESS", accounts[0]);
                }
            });

            provider.on("chainChanged", (chainId) => {
                console.log("Chain changed: " + chainId);
            });

            await provider.enable();

            const web3Provider = new providers.Web3Provider(provider);

            const signer = await web3Provider.getSigner();
            const address = await signer.getAddress();
            // const chainId = await provider.request({ method: "eth_chainId" });
            return {provider: provider, address: address}

 
        } catch (error) {
            console.log(error);
        }
    },
    async disconnect() {
        await this.$store.getters["payments/providerObj"].disconnect();
    },
    makeCustodialWallet() {
        // TODO: Replace with Wyre wallets creation
        var id = randomBytes(32).toString("hex");
        var privateKey = "0x" + id;

        var wallet = new Wallet(privateKey);

        this.$store.commit("SET_CUSTODIAL_WALLET", {
            address: wallet.address,
            privateKey: privateKey
        });
    },
    async transact() {
        const params = {
            from: this.$store.getters["payments/walletAddress"],
            to: this.$store.getters["payments/custodialWallet"].address,
            value: etherUtils.parseEther("0.001").toHexString()
        };

        const transactionHash = await this.$store.getters["payments/providerObj"].send(
            'eth_sendTransaction', [params]);
        console.log(transactionHash);
    }
}
