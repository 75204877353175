<template>
    <div class="questionaire">
        <h1>SnipitFM</h1>
        <div v-if="showMotto" class="primary-text-wrapper">
            <p class="primary-text motto">Share <br /> Discover <br /> Earn</p>
             <img src="@/assets/musicalNotes.png" alt="musica">

            <button class="join-fun" @click="closeQuestionaire()">Join The Fun <right-arrow :color="'#000'" /></button>

        </div>
        <div v-else class="primary-text-wrapper">
            <p class="primary-text question">{{questions[index]}}</p>
            <div class="buttons">
                <div class="buttons-grid">
                        <button @click="yesClicked()">Yes</button>
                        <button @click="noClicked()">No</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RightArrow from '@/components/SVGs/RightArrow.vue'

export default {
    name: 'Questionaire',
    components: { RightArrow },

    data() {
        return {
            showMotto: false,
            randomize: false,
            questions: [
                "Do you like music?",
                "Did you take a music class in school?",
                "Do you enjoy listening to music with friends?",
                "Do you enjoy listening to friends with music?",
                "Do you listen to your friends?",
                "Are you satisfied with the musical frequency of A flat?",
                "Tu aimes la musique?",
                "Do you want to be rich?",
                "Are you an idea guy?",
                "Dost thou fancy a strum of the harp?",
                "Do you have a friend who \"plays guitar\"?",
                "Alright, we'll ask you again. Ready?",
                "Are your friends listening to music right now?",
                "Do you have friends?",
            ],
            index: 0
        }
    },
    methods: {
        noClicked() {
            // On the first round, we want to go in the pre-selected order of questions
            // At the end of the array, we want to start randomizing the questions order
            //After a user clicks "No" generate a random index in questions array
            if (this.index == this.questions.length -1 && !this.randomize) { this.randomize = true }
            if (!this.randomize) { this.index += 1 } 
            else { this.index = this.getRandomIndex() }
             
        },
        getRandomIndex() {
            let new_index = this.index
            while (this.randomize && new_index == this.index) { 
                let min = 0
                let max = this.questions.length - 1       
                new_index = Math.floor(min + Math.random()*(max - min + 1))//Math.random() this.questions.length//(this.index == this.questions.length - 1) ? 0 : this.index + 1
            } 
            return new_index
        },
        yesClicked() {
            this.showMotto = true
        },
        closeQuestionaire() {
            this.$emit("questionaireClosed")

        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-size: 32px;
    font-weight: 700;
}
.questionaire {
    display: flex;
    flex-direction: column;
  //  justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 7vh;
    background: rgba(31, 31, 31, 0.28);
    padding-top: 3rem;
}
.buttons {
    display: flex;
}
.buttons-grid {
    display: grid;
    grid-template-columns: 42.5% 42.5%;
    width: 90%;
    gap: 4%;
    flex: 2 1 0%;
    margin: 0 auto;
    justify-content: end;
    align-items: center;
}
.question {
    font-size: 3rem;
}
button {
    background: linear-gradient(270deg, #9BFF7B 0%, #D9FF7B 100%);
    border: 0;
    font-size: 2.5rem;
    border-radius: 2.5rem;
    height: 5rem;
    color: black;
 //   box-shadow: rgb(66 55 45 / 16%) 0px 4px 16px;
}
button:hover {
    cursor: pointer;
}
.primary-text-wrapper {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 78px;
}

.question {
    line-height: 58px;
    padding: 0 16px;
}
.motto {
    font-family: 'Futura-Black';

}

@media only screen and (min-width: 480px) {
    button {
        width: 26vw;
        height: 12vh;
        margin-top: 6.2vh;
        font-weight: 600;
    }
    .buttons-grid {
        margin-top: 6.2vh;
    }
    .primary-text-wrapper {
            margin-top: 3.5rem;
    }
}
@media only screen and (max-width: 480px) {
    button {
        height: 4rem;
        font-size: 2rem;
        padding: 0 2rem;
    }
    .question {
        margin-bottom: 15vh;
    }
    .buttons-grid {
        justify-content: center;
    }
    .join-fun {
        margin-top: 2rem;
        border-radius: 0.5rem;
    }
}

@media only screen and (min-width: 480px) {
    .primary-text-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        justify-content: center; 
        height: 70vh;
        align-items: center;    
    }
    .buttons {
        width: 80vw;
        margin: 0 auto;
    }
    img {
        width: 21vw;
        height: auto;
    }
}
.primary-text-wrapper {
    padding-bottom: env(safe-area-inset-bottom);

}
</style>