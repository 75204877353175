<template>
    <div class="track_card" >

        <div class="album_container">
            <div v-if="header">
                <p class="header__left">Rank</p>
            </div>
            <div v-else>
                <img class="track_card__img" :src="image" alt="" />
                <span class="top-center number_Circle2" :class="{'isWinner': index <= 2}">{{rank}} </span>
            </div>
        </div>
        <div class="track_card__details">
            <div v-if="header">
                <p class="header__left">Song</p>
            </div>
            <div v-else>
                <p class="track_card__name">{{name_label}}</p>
                <p class="track_card__artist" @click="artistSelected(artist_label)">{{artist_label}}</p>
            </div>
        </div>
        <div class="track_card__votes_group">
            <div v-if="header">
                <p class="header__center" style="display: inline">Votes </p>
                <VoteIcon />
            </div>
            <div v-else class="header__icon">
                <p :title="votes_count_title(track)" class="track_card__votes">{{votes_count}}</p>
                
            </div>
        </div>
        <div class="track_card__roi_header">
            <div v-if="header" class="header__icon">
                <p class="header__right">ROI</p>
                <CoinsIcon :width="20" :height="20"/>
                
            </div>
            <div v-else>
                 <p class="track_card__roi">{{ROI}}</p>

                <PlayButton v-if="false" class="play_btn btn" :isPng="false" :track="track.item" :preview="false" />
            </div>
            <TrackAddition v-if='false' :heart="false" :track="track.item" :game_complete="true" class="add_btn btn"/>
        </div>
    </div>
</template>
<script>

function truncate(str, n) { return (str?.length > n) ? str.substr(0, n-1) + '...' : str }

import TrackAddition from '@/components/TrackAddition/'
import PlayButton from '@/components/PlayButton/'
import VoteIcon from '@/components/SVGs/Vote.vue'
import CoinsIcon from '@/components/SVGs/Token.vue'
export default {
    name: "Track",
    props: ["track", "index", "votes_count", "header", "roi"],
    components: { PlayButton, TrackAddition, VoteIcon, CoinsIcon },
    data() {
        return {
        }
    },
    computed: {
        ROI() { return this.roi || "-" },
        rank() { return this.index + 1 },
        image() { 
            var images = this.track && this.track.item.album ? this.track.item.album.images : null
            return images ? images[images.length - 1].url : ""
        },
        name_label() { return truncate(this.track.item.name, 11)},
        artist_label() { return this.track.item.artists ? truncate(this.track.item.artists.map(artist => artist.name) .join(", "), 18) : "" },

    },
    methods: {
        votes_for_track(track) {
            console.log("VOTERS COUNT: ", Object.keys(track.voters).reduce((r,k) => Object.keys(track.voters[k]).reduce((o,p) => (o[p] = o[p] + 1 || 0, o), r), {}))
            return Object.keys(track.voters).reduce((r,k) => Object.keys(track.voters[k]).reduce((o,p) => (o[p] = o[p] + 1 || 0, o), r), {});
        },
        artistSelected(artist_name) {
            //var artist = this.track.item.artists.find(artist => artist.name == artist_name)
            var artist = this.track.item.artists[0]
            this.$emit("artistSelected", artist.id)
        },
        async addTrackToPlaylist() {
            console.log("Track.addTrackToPlaylist", this.track)
            await this.$store.dispatch('user/favorite_song', this.track.item)
        },
        getCurrentUserPositionForTrack(track) {
            console.log("getCurrentUserPositionForTracks() called")
            if (this.currentUserPositions && track.item) {
                return this.currentUserPositions[track.item.id] 
            } else {
                return "null"
            }
        },
        votes_count_title(track) { return `You voted ${this.tallyCurrentUsersVotesFor(track)} times for this song`},

        tallyTotalVotesFor(track) {
            var sum = 0
            if (track.voters != null) {
                for (let key in track.voters) {
                    // key represents a userID. track[key] is a dictionary. it should have 1 key per vote by the userID
                    // we want to aggregate the total votes by adding 1 for every subkey
                    sum += Object.keys(track.voters[key]).length
                }
            }
            return sum
        },
        tallyCurrentUsersVotesFor(track) {
          var tally = null
          if (this.preview_mode) {
            tally = this.$store.getters['game_preview/vote_tally'][track.item.id] || 0
          } 
          else {
            console.log("tallyCurrentUserVotesFor", track, track.voters)
            tally = track.voters  
                    && this.$store.getters['user/id'] 
                    && track.voters[this.$store.getters['user/id']] 
                    ? Object.keys(track.voters[this.$store.getters['user/id']]).length 
                    : 0
          }
          return tally
          
            
        },
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
.album_container { 
    width: 20%;
    height: auto;
    position: relative;
}
.play_btn {
    margin-left: 8%
}
.btn:hover {
    cursor: pointer;
}
.add_btn {
    margin-left: 6.6%
}
button {
    background: transparent;
    border: 0;
}
.track_card {
    display: flex;
    margin-bottom: 5.2%;
    align-items: center;
    width: 100%;
    &__details {
        text-align: left;
        margin-left: 3.8%;
        min-width: 21.3%;
        margin-right: 11.3%;
        white-space: nowrap;
    }
    &__img {
        height: auto;
        min-width: 54px;
        width: 54px;
    }
    &__name {
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
    }
    &__artist {
        font-size: 12px;
        line-height: 14px;
    }
    
    &__votes_group {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        margin-left: 7.3%; 
        margin-right: 4.6%;
        min-width: 23.5%;

        //16%;
    }
    &__votes {
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
    }
    &__votes_group:hover {
        cursor: pointer;
    }
    &__roi_header {
        min-width: 11%;

    }
    &__roi {
        color: #CAFF34;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
    }
}
.number_Circle2 {
    min-width: 35.8%;
    min-height: 35.8%;
    width: fit-content;
    height: fit-content;
}

.top-center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: black;
    font-size: 16px;
    line-height: 19px;
    font-weight: 900;
    display: flex;
    background: #A8A8A8;
    justify-content: center;
    align-items: center;
}
button:hover {
    cursor: pointer;
}
.isWinner {
      background: #CAFF34;
}
.header {
    &__left {
    text-align: left;

    }
    &__center {
    text-align: center;

    }
    &__right {
    text-align: right;

    }
    &__icon {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}
</style>
