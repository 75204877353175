<template>
    <transition name="fade">
        <div class="whiteModal" v-show="true" >
        <div class="whiteModal__backdrop" @click="closeModal()"/>
                <div class="whiteModal__dialog" style="display:flex">
                  <button type="button" class="whiteModal__close" @click="closeModal()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                      <path
                        fill="currentColor"
                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                      ></path>
                    </svg>
                  </button> 
                    <div class="content__coverBackground">
               
                      <div class="content__body">
                        <instructions :color="'black'" />
                      </div>
                    </div>
                  
                </div>
        </div>
        
    </transition>
</template>
<script>
import Instructions from '../../GameBrowser/Instructions.vue';
export default {
    name: "WhiteGreenModal",
    components: {
      Instructions,
    },
    data() {
        return {

            playlist: null,
            playlistURL: "",
            index: 0,
            steps: [
              {
                image: require("@/assets/InstructionsBrowsing.png"),
                title: "Browse the Music Options",
                description: "Select a playlist to get started",
              },
              {
                image: require("@/assets/InstructionsPoll.png"),
                title: "Judge music along other people",
                description: "Votes end every 30 mins",
              },
              {
                image: require("@/assets/InstructionsBrowsing.png"),
                title: "A top 3 song bet means you win 🤑",
                description: "",
              },
              // add more steps here
            ],
        }
    },
    computed: {
      currentStep() {
        return this.steps[this.index];
      },
      nextStep() {
        return this.steps[this.index + 1];
      },
    },
    methods: {
        closeModal() {
          this.$store.dispatch('modals/HIDE_WHITE_MODAL')
        },
        nextPage() {
          if (this.index < this.steps.length - 1) {
            this.index += 2;
          } else {
            this.closeModal();
          }
        },
    },
}
</script>
<style lang="scss" scoped>


button:active {
  background-color: white;
}
.modal_title {
  font-family: 'Rubik';

    font-weight: 600;
    font-size: 32px;
    color: #2A2D35;
}
.modal_description {
  font-family: 'Rubik';

    margin-top: 0.75rem;
    margin-bottom: 2.5rem;
    font-weight: 400;
    font-size: 16px;
    color: #1B1B1B;
}

.content {
  &__coverBackground {
//    background:  linear-gradient(179.61deg, #CAFF34 0.34%, rgba(173, 255, 96, 0.53) 17.03%, rgba(155, 255, 123, 0) 21.61%);
    //background: linear-gradient(179.25deg, #CAFF34 0.65%, rgba(173, 255, 96, 0.53) 16.58%, rgba(155, 255, 123, 0) 33.35%, rgba(155, 255, 123, 0) 101.69%);
    background: linear-gradient(179.23deg, #CAFF34 0.66%, rgba(173, 255, 96, 0.53) 16.22%, rgba(155, 255, 123, 0) 32.61%);
    //linear-gradient(179.61deg, #CAFF34 0.34%, rgba(173, 255, 96, 0.53) 19.03%, rgba(155, 255, 123, 0) 23.61%);
    //linear-gradient(179.61deg, #CAFF34 0.34%, rgba(173, 255, 96, 0.53) 15.03%, rgba(155, 255, 123, 0) 27.12%);
    //linear-gradient(179.61deg, #CAFF34 0.34%, rgba(173, 255, 96, 0.53) 51.92%, rgba(155, 255, 123, 0) 83.27%);;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__body {
    padding-left: 39px;
    padding-right: 39px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.centerBody {
      position: absolute;
    top: 2.5rem;
    bottom: 2.5rem;
}



.whiteModal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  color: #E8E8E8;
  &__backdrop {
    background-color: rgba(0,0,0, 1.0);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &__dialog {
    background-color: #FFFFFF;
    position: relative;
    // width: 67vw;
    // height: 30%;
    margin: 50px auto;
    margin-top: 13vh;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;
    height: 78vh; //70vh;
    overflow: scroll;
    width: 45%;
    text-align: left;
    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }

  &__close {
    width: 30px;
    height: 30px;
        border: 0;
    color: #5d5d5d;
    position: absolute;
    top: 8px;
    right: 8px;
    background: transparent;
  }
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-family: Rubik;
    font-weight: 700;
    font-size: 36px;
  }
  &__body {
    overflow: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  &__footer {
    padding: 10px 20px 20px;
  }
}




/* resetting default box-sizing */
*,
*:before,
*:after {
  box-sizing: border-box;
}


</style>