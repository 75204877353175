
<template>
    <div>
      <h1>SnipitFM</h1>
      
      <div class="overlay">
      </div>
      <div class="grid-container">
        <div
          v-for="(url, index) in imageUrls"
          :key="index"
          class="grid-item"
          :style="{ backgroundImage: `url('${url}')` }"
          :class="{ fade: changingIndices.includes(index) }"
        ></div>
      </div>
      <div class="blur-layer"></div>
    </div>
  </template>
  
  <script>
  import { db } from '@/store/modules/firebase.js';
  
  export default {
    data() {
      return {
        imageUrls: [],
        changingIndices: [],
        shownImages: [],

        trackIDs: [
        "08zJpaUQVi9FrKv2e32Bah",
        "05RgAMGypEvqhNs5hPCbMS",
        "08uGhvS5MfBk7crUCpnjva",
        "09IStsImFySgyp0pIQdqAc",
        "09b84uwrksszAvJwM6J28L",
        "0A1y3d5O3SDvXaQ8snkmOf",
        "09gysnJpfQ3ublBmJDfcEC",
        "06kxa3al7bUqRRo5nAFduZ",
        "06l5Tf3jswkIljwnrje7Yo",
        "074JmiFOVEwdu3O4oJO0dS",
        "0AAVJIN4plafvmNKqRCltG",
        "0ARK753YaiJbpLUk7z5yIM"
        ]
      };
    },
    async created() {
      await this.fetchImageUrls();
      setInterval(this.changeRandomImage, 3000);
    },
    methods: {
      async fetchImageUrls() {
        const trackIDs = this.trackIDs;
        const promises = trackIDs.map((trackID) => {
          return db.ref(`/tracks/${trackID}/album/images/0/url`).once('value');
        });
  
        const snapshotResults = await Promise.all(promises);
        this.imageUrls = snapshotResults.map((snapshot) => snapshot.val());
      },
      changeImage(index) {
        this.changingIndices.push(index);
        var newImgIndex = Math.floor(Math.random() * this.imageUrls.length);
        while (this.shownImages.includes(this.imageUrls[newImgIndex])) {
            newImgIndex = Math.floor(Math.random() * this.imageUrls.length);
        }
        setTimeout(() => {
          this.$set(this.imageUrls, index, this.imageUrls[newImgIndex]);
          this.shownImages.push(this.imageUrls[newImgIndex]);
          // find this.imageUrls[index] and remove from shownImages
            this.shownImages = this.shownImages.filter((url) => url !== this.imageUrls[index]);
          this.changingIndices = this.changingIndices.filter((i) => i !== index);
        }, 1000);
      },
      changeRandomImage() {
        const randomIndex = Math.floor(Math.random() * this.imageUrls.length);
        this.changeImage(randomIndex);
      },
    },
  };
  </script>
  
  <style scoped>
  body {
    margin: 0;
    overflow: hidden;
  }
  /* }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
  } */
  h1 {
    font-size: 32px;
    font-weight: 700;
}
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 100vh;
    width: 100vw;
  }
  
  .grid-item {
    background-size: cover;
    background-position: center;
    transition: opacity 1s;
  }
  
  .fade {
    opacity: 0;
  }
  
  .blur-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(0px);
  }
  </style>