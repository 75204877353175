import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Browser from '../views/Browser.vue'
import ConfirmationPage from '../views/ConfirmationPage.vue'
import Login from '../views/Login.vue'
import Poll from '../views/Poll.vue'
import ProfilePage from '../views/Profile.vue'
import GameComplete from '../views/GameComplete.vue'
import AccountCreation from '../views/AccountCreation.vue'
import ImageGrid from '../views/ImageGrid.vue'
//import Streaming from '../views/Streaming.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/browse',
    name: 'Browser',
    component: Browser
  },
  {
    path: '/grid',
    name: 'ImageGrid',
    component: ImageGrid
  },
  {
    path: "/signup",
    name: "AccountCreation",
    component: AccountCreation
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },

  {
    path: '/streaming',
    name: 'Streaming',
    component: Poll
  },
  {
    path: '/browse/:playlistCategory/:playlistID',
    name: 'ConfirmationPage',
    component: ConfirmationPage,
    props: true
  },
  {
    path: '/user/:id',
    name: 'ProfilePage',
    component: ProfilePage,
    props: true
  },
  {
    path: '/play/:id',
    name: 'Poll',
    component: Poll,

  },
  {
    path: '/results/:id',
    name: 'GameComplete',
    component: GameComplete
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
