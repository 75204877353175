<template>
    <div>
        <p class="jackpot__date_label">Claim Your Jackpot On</p>
        <p class="jackpot__date">FRIDAY SEP 26 - 4:05PM</p>
    </div>
</template>
<script>
export default {
    name: "JackpotTimer",
    //props: ["expire_date"]
}
</script>

<style lang="scss" scoped>
.jackpot {
    color: #E0E0E0;
    &__date {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        margin-bottom: 29px;
    }
    &__date_label {
        font-weight: 400;
                margin-bottom: 6px;

    }
}
</style>