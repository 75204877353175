
import request from './../request.js'

export default {

    getArtist(artist_id) {
        return request.get(`/artists/${artist_id}`, {
            params: {
                
            }
        });
    },
    getArtistPlaylists(artist_name) {
        var q = artist_name
        var type = "playlist"
        var limit = 20
        var offset = 0
        var market = "US"
        var include_external = "audio"
        return request.get(`/search`, {
            params: {
                q,
                type,
                limit,
                offset,
                market,
                include_external
            }
        });
    },
 

}