<template>
    <div class="current-track">
        <div v-if="playback" class="cover_album">
              <img class="cover_album" v-bind:src="cover_album" />
          </div>
          <div v-if="playback" class="song_details ml-4 mr-4">
                <p  class="name">{{name_label}}</p>
                <p v-if="false" class="artist">{{artist_label}}</p>


          </div>
    </div>
</template>
<script>
import Vue from 'vue'
function truncate(str, n) { return (str?.length > n) ? str.substr(0, n-1) + '...' : str }

export default {
    name: "Currentplayback",

    computed: {
      playback() { return this.$store.state.audioPlayer.current_song },
      playerPlayback() { return this.$store.state.player?.playback },
      artists() { return this.playback.artists || this.playback.meta.artiists },
      artist_label() { return this.artists ? truncate(this.artists.map(artist => artist.name) .join(", "), 18) : "" },
      name_label() { return truncate(this.playback.name.replace(/\(.*?\)/g, ''), 25) },
      cover_album() { return this.playback.album ? this.playback.album.images[2].url : ""}

    },
    methods: {
      async favorite() { 
        await this.$store.dispatch('user/favorite_song', this.playback)
       // analytics.logEvent("favorite", { song_name: this.info.name, song_artist: this.artist})

      },
      searchArtist(artist) {
        console.log(`CUrrentTrack.SearchArtist(${artist})`);
        this.showPlaylistsModal(artist)
      },
      showPlaylistsModal(query) {
        Vue.set(this.$store.state.modals.playlists, 'show', true)
        Vue.set(this.$store.state.modals.playlists, 'query', query)
        console.log("showPlaylistsModal() - NEW QUERY: ", this.$store.state.modals.playlists.query, query);
      },
    },
    watch: {
      playerPlayback(newValue) {
        if (!this.$store.state.audioPlayer.isPlayingPreview) {
          Vue.set(this.$store.state.audioPlayer, "current_song", newValue.item)
        }

      }
    }

}
</script>

<style lang="scss" scoped>

.current-track {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.artist {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  opacity: 0.6;
}
.cover_album {

  height: 80%;
  display: flex;
  width: auto;
  align-items: center;
}
.cover_album > img {
      max-height: 50px;
    width: auto;
}
.name {
  display: inline-block;
}
.song_details {
  text-align: left;
}
.heart-btn > * > img {
  height: 16px;
  width: auto;
  display: inline;
}
.btn:hover {
  cursor: pointer;
}
</style>