<template>
    <section class="bracket-feed">
        <!-- <p class="list-header">Or pick from one our brackets below</p> -->
        <div v-if="showIsLoading" class="spinner-container">
            <pulse-loader />
        </div>
        <div v-else class="feed-container">
            <div v-if='hasBrackets'>
                <em>Your feed is currently empty. Add friends to unlock access to music</em>
            </div>
            <div v-else class="lhs">
                <div class="genre-list">
                    <p class="genre" v-for="genre in genres" :key="genre.id" @click="selectGenre(genre.name)">{{ genre.name }}</p>
                </div>
            </div>
            <div class="rhs">
                <div class="list-container" v-show="!showIsLoading" ref="list">
                    <PlaylistCard v-for="playlist in selectedList" :key="getGameID(playlist)" :game_id="getGameID(playlist)" @start="start($event)" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import PulseLoader from '@/components/SVGs/PulseLoader.vue';
import PlaylistCard from '@/components/Modals/Playlists/PlaylistCard.vue';
export default {
    name: "BracketFeed",
    data() {
        return {
            selectedGenre: null,

        };
    },
    computed: {
        selectedList() {
            if (this.selectedGenre) {
                return this.games[this.selectedGenre];
            } else {
                return this.brackets;
            }
        },
        hasBrackets() {
            return this.brackets.length > 0;
        }
    },
    props: {
        showIsLoading: {
            type: Boolean,
            default: false
        },
        brackets: {
            type: Array,
            default: () => []
        },
        genres: {
            type: Array,
            default: () => []
        },
        games: {
            type: Object,
            default: () => {}
        }
    },
    mounted() {
        setTimeout(() => {
            this.scrollToActiveElement();
        }, 5000);
    },
 
    components: {
        PlaylistCard,
        PulseLoader
    },
  
    methods: {
        start(event) {
            console.log("BracketFeed.start()", event)
            this.$emit('start', event);
        },
        getPlaylist(playlist) {
            return playlist[1]
        },
        getGameID(playlist) {
            console.log("BracketFeed.getGameID()", playlist)
            return playlist[1] || playlist.game_id || playlist.id
        },
        selectGenre(genre) {
            console.log("BracketFeed.selectGenre()", genre)
//            this.selectedGenre = genre;
//            this.scrollToActiveElement();
        },
        scrollToActiveElement() {
            this.$nextTick(() => {
                let activeElement = null;
                var j = 0
                console.log('children list', this.$refs.list.children.length)
                for (let i = 0; i < this.$refs.list.children.length; i++) {
                    console.log('child', i, this.$refs.list.children[i].classList)
                    if (this.$refs.list.children[i].classList.contains('card-content-inactive')) {
                        activeElement = this.$refs.list.children[i];
                        j = i
                        break;
                    }
                }
                console.log("Scrolling to active element", j, activeElement)
                if (j >= 4) {
                    if (activeElement) {
                       // activeElement = this.$refs.list.children[j]
                        activeElement.scrollIntoView({ behavior: 'auto',  block: 'center', inline: 'center'  });
                    }
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.bracket-feed {
    width: 100%;
    height: 100%;
    font-family: "Open Sans";
} 
.genre-list {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
    height: 100%;
 
}
.feed-container {
    display: flex;
    font-family: "Open Sans";
    width: 100%;
    height: 100%;
    gap: 2rem;
}
.list-header {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
//    text-align: center;
    margin: 0 auto;
    color: #CAFF34;
    color: #A5A5A5;
    margin-bottom: 1.5rem;
    margin-top: 1.25rem;
}
.rhs, .lhs {
    height: 100%;
}
.list-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
    height: 50%;
    padding-top: 0;
    position: relative;

    //  background: linear-gradient(180deg, rgba(63, 63, 63, 0) 50.04%, #0d1216 100%);
}
.list-bottom {
    display: block;
    height: 50px;
    width: 100%;
    background-color: transparent;
    opacity: 0.5;
  }
.genre:hover {
    cursor: pointer;
    color: #CAFF34;
}
</style>