<template>
    <div class="portrait" >
        <div class="portrait__first" @click="closeClicked()"> <close-icon /> </div>
        <div class="play_more" @click="nextGame()">
            <img :src="game_image" class="play_more__img" alt="">
            <p>Play More</p>
            <img src="@/assets/play-icon-black.png">
        </div>
        <div v-if="false"> <jackpot-size-label :size="game_size"/> <hr /> </div>
        <VoteResults class="card2" :tracks="tracks" :game_expired="gameIsOver(game)" @ctaClicked="seeRecommended()" />
    </div>
    

</template>

<script>

import { gameLogic } from "@/mixins";
import VoteResults from '@/components/GameComplete/VoteResults'
import Leaderboard from '@/components/GameComplete/Leaderboard'
import JackpotSizeLabel from '@/components/GameComplete/JackpotInfo/JackpotSizeLabel.vue';
import CloseIcon from '@/components/SVGs/Close.vue'
export default {
  name: "GCPortrait",
  props: ["game", "tracks"],
  mixins: [gameLogic],
  components: {
    VoteResults,  JackpotSizeLabel, CloseIcon
  },
  computed: {
    game_size() { return this.game ? this.game.tokens : "" },
    // return game image
    game_image() { return this.game?.image }
  },
  methods: {
    nextGame() {
        this.$emit('nextGame')
    },
    closeClicked() {
        this.$emit('closeClicked')
    }
  }
};


</script>


<style lang="scss" scoped>

hr {
    width: 38%;
    margin: 29px auto;
    background-color:  #505050;
    size: 1;
    

}

.bg_image {
    position: absolute;
    z-index: 0;
    bottom: 12vh;
    left: 0;
    width: 100%;
    right: 0;
    //background-image: url('src/assets/GameCompleteBG.png');
    //background: black;
}


.card2 {
    width: 100%;
    margin: 0 auto;
    border-radius: 22px;
    max-height: 52vh;
    overflow: scroll;
    color: white;
  }

.portrait {
    display: flex;
    flex-direction: column;
    padding-top: 5.4%;
    padding-right: 3.3%;
    padding-left: 3.3%;
    &__first {
        display: flex;
        justify-content: flex-end;
    }
}


.play_more {
    background: white;
    color: #1f1f1f;
    display: flex;
    width: 100%;
    padding: 0.75rem 1rem;
    justify-content: space-between;
    margin: 5rem auto;
    border-radius: 22px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

    &__img {
        height: 29px;
        width: 29px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    }
}


</style>

