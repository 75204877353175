<template>
    <div class="navbar-brond" v-if="!hideLogo">
        <a class="navbar-item grow logo" href="#" @click="logoClicked()">
            {{logo}}
        </a>
        <NavBarSearch v-if="false && !$isMobile()"/>
    </div>
</template>
<script>
import NavBarSearch from '@/components/Search/NavBar.vue';

export default {
    name: 'NavBarLeftSide',
    components: { NavBarSearch },
    props: {
        hideLogo: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        logo() {
            return `Snipit.FM` //HOW WELL DOES SPOTIFY KNOW ME?" //this.$store.state.logo
        },

    },
    methods: {
        logoClicked() {
            if (!["Streaming", "Poll"].includes(this.$route.name)) { 
                this.$router.push({name: "Browser"})
            }
            this.$router.push({name: "Browser"})

           // this.$store.dispatch('modals/SHOW_GENRE_MODAL')
        },
    }
}
</script>

<style lang="scss" scoped>

.navbar-brond {
  display: flex;
  color: white;
  align-items: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 2px;
}
// .version-status {
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   background: red;
//   border-radius: 50%;
//   width: 10px;
//   height: 10px;
// }
.navbar-item:hover {
  background-color: transparent;
  color: white;
  cursor: pointer;
}
.navbar-item:focus {
  background-color: rgba($color: #fff, $alpha: 0.5);
//  background-color: #424752;
  border-radius: 8px;
  color: white;
}
.logo {
    color: rgb(146, 145, 145);
}
.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }
</style>