<template>
    <div class="login"></div>

</template>
<script>
import { mapActions, mapGetters } from "vuex";
const HOME_GENRE_SELECTION_ID = 2
export default {
    name: "Login",
    created() {
      const {
        access_token,
        refresh_token,
        expires_in,
        error
      } = this.$route.query;
      if (error) {
        console.error("LOGIN ERROR - ", error);
      } else if (access_token && refresh_token && expires_in) {
        console.log("SETTING ACCESS TOKENS", access_token);
        this.setAccessToken(access_token);
        this.setRefreshToken(refresh_token);
        this.setExpiryTime(expires_in);
      }
      if (this.redirect) {
        console.log("REDIRECTING TO GAME ", this.redirect)
        this.$router.push({ name: "ConfirmationPage", params: { id: this.redirect }})
        this.$store.commit("auth/SET_REDIRECT", {redirect: null})
      } else {
        console.log("NO REDIRECT FOUND. GOING HOME")
        this.$router.push({ name: "Home", params: { id: HOME_GENRE_SELECTION_ID } });
      }
    },
    computed: {
      ...mapGetters({
        redirect: 'auth/getRedirect'
      })
    },
    methods: {
      ...mapActions("auth", [
        "setAccessToken",
        "setRefreshToken",
        "setExpiryTime"
      ])
    },
    
}
</script>