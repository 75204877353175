<template>
    <div class="buttons">
        <button class="buttons__button" 
                :class="{'buttons__isActive': show_personal}"
                @click="toggleBtn('personal')">Your Ranking</button> 
        <button class="buttons__button"
                :class="{'buttons__isActive': show_community}"
                @click="toggleBtn('community')">Community Ranking</button>
    </div>
</template>

<script>
export default {
    name: "ResultsToggle",
        props: {
            active: {
                default: "personal"
            },
        },
    computed: {
        show_personal() { return !this.active || this.active == "personal" },
        show_community() { return this.active == "community"}
    },
    methods: {
        toggleBtn(str) {
            console.log("ResultsToggle.toggleBtn() ", str)
            this.$emit('toggleBtn', str)
        }
    }
}
</script>

<style lang="scss" scoped>
.buttons {
    display: flex;
    position: relative;
    flex-direction: row;
    margin-top: 1rem;
    z-index: 1;
    &__button {
        color: white;
        background: transparent;
        border: 0;
        border-radius: 6px;
        width: 50%;
        height: 41px;
        font-weight: 600;
    }

    &__button:hover {
        cursor: pointer;
    }
    &__isActive {
        color: #000000;
        background: white;
    }  
    &__isActive:hover {
        cursor: default;
    }      

}
</style>