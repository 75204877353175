<template>
    <div class="poll-controls mt-1 mr-3">
        <span v-if="show_poll_controls_header" class="poll-controls-header mb-1"> SKIP POLL </span>
        <img class="poll-btn mr-3" @click.prevent.stop="movePreviousPoll()" :class="{inactive: !prev_poll_enabled, grow: prev_poll_enabled}" src="@/assets/backArrow.png" />
        <img  class="poll-btn " title="Please vote before continuing."
                                alt="Please vote before continuing."
                                @click.prevent.stop="moveNextPoll()" 
                                :class="{inactive: !next_poll_enabled, grow: next_poll_enabled}" 
                                src="@/assets/forwardArrow.png" />      
    </div>
</template>

<script>
import { mapGetters, mapActions} from "vuex";

export default {
    name: "PollControls",
    computed: {
      show_poll_controls_header() { return true },
      ...mapGetters({
          next_poll_enabled: "context/nextPollEnabled",
          prev_poll_enabled: "context/prevPollEnabled",
      }),

    },
    methods: {
       ...mapActions({
        next_poll: "context/NEXT_POLL",
        prev_poll: 'context/PREV_POLL',
       }),
        moveNextPoll() {
          console.log("Footer.nextPoll() called");

            this.next_poll_enabled
            ? this.next_poll() 
            : console.log("Sorry, this button is disabled.")
        },
        movePreviousPoll() {
            console.log("Footer.prevPoll() called");

            this.prev_poll_enabled 
            ? this.prev_poll()
            : console.log("Sorry, this button is disabled.")
        },
    }
}
</script>

<style lang="scss" scoped>


.poll-controls-header {
  display: block;
}
.poll-btn {
  position: relative;
  border-color: white;
  border-width: 1px;
  border-radius: 8px;
  padding: 8px;
  border-style: solid;
  cursor: pointer;

}
grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }
.inactive {
  opacity: 0.3;
}

img[title]:hover::after {
  content: attr(title);
  position: absolute;
  top: -100%;
  left: 0;
  background: white;
  color: black
}

</style>