<template>
    <div class="header">
        <div class="header__row">
            <h2> {{active_tab_name}} </h2>
        </div>
        <div class="header__row">
            <div class="tabs is-toggle">
                <ul>
                    <category-item   v-for="tag in tags" :key="getTagKey(tag)"
                                     :active_tab="active_tab"
                                     :category="tag"
                                     :class="{'is-active': active_tab_id == (tag.id ? tag.id : tag)}"  
                                     @setCategory="setTab"/>
                    <!-- <li @click="setTab(`${tag}`)" :class="{'is-active': tab == 'Games'}" v-for="tag in tags" :key="tag"><a><span>{{tag}}</span></a></li> -->
                    <!-- <li @click="setTab('Games')" :class="{'is-active': tab == 'Games'}"> <a> <span>Games</span></a> </li>
                    <li @click="setTab('Upcoming')" :class="{'is-active': tab == 'Upcoming'}"> <a> <span>Upcoming</span></a> </li>
                    <li @click="setTab('Drafts')"  :class="{'is-active': tab == 'Drafts'}"> <a><span>Drafts</span></a> </li> -->
                    <!-- <li @click="setTab('History')" :class="{'is-active': tab == 'History'}"> <a> <span>History</span></a></li> -->
                </ul>
            </div>
            <!-- <div v-if="!playlist" class="createGame_input ">
                <input type="url" v-model="playlistURL" placeholder="Paste your Spotify Playlist URL ">
                    <a class="createGame_nextBtn" @click="openCreateGameModal()" > Create Game </a> 
                    
        </div> -->
            <button v-show="!$isMobile()"
                    class="create_game green_gradient" @click="openCreateGameModal()"> info</button>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import CategoryItem from '@/components/GameBrowser/Header/CategoryItem/index.vue';
export default {
    name: "DesktopBrowserHeader",
    props: ["active_tab", "tags"],
    components: { CategoryItem },
    data() {
        return {
        }
    },
    computed: {
        isAdmin() { return this.$store.getters['user/isAdmin']},
        active_tab_name() { return this.active_tab ? this.active_tab.name : "" },
        active_tab_id() { return this.active_tab ? this.active_tab.id : "" },
    },
    methods: {
        openCreateGameModal() {
          Vue.set(this.$store.state.modals.white_modal, "show", true)      
        //  this.$store.dispatch("game_preview/reset_game")
        },
        getCategoryID(val) {
            console.log("GetCategoryID()", val)
            // check if val is a string or an object
            return typeof val == "string" ? val : val.id
        },
        setTab(val) {
            console.log("SetTab()", val)
            this.$emit("tabSet", val)
        },
        getTagKey(tag) {
            return typeof tag == "string" ? tag : tag.id
        }
    }
}
</script>
<style lang="scss" scoped>
h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 57px;
  text-align: left;
  margin-bottom: 1rem;
}
.tabs.is-toggle {
    a {
        border-color: #DDD;
        color: #FFF;
        border-style: solid;
        border-width: 1px;
        margin-bottom: 0;
        position: relative;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
    }
    a:hover {
        background-color: #DDD;
        color: black;
        border-color: #b5b5b5;
        z-index: 2;
    }
    li.is-active a {
       // background-color: #DDD;
        //border-color: #DDD;
        //color: #1f1f1f;
        color: white;
        background-color: gray;
       // border-color: gray;
        z-index: 1; 
        font-size: 18px;
        line-height: 21px;
    }
     li:last-child a {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
    li:first-child a {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }
}


.header {
  display: flex;
  flex-direction: column;

  margin-bottom: 1.5rem;

  &__row {
    display: flex;
    justify-content: space-between;
  }
}

.create_game {
    border-radius: 8px;
    font-weight: 500;
    height: 41px;
    padding-left: 16px;
    padding-right: 16px;
    border: 0;
    font-weight: 700;

}
 .green_gradient {
  background: linear-gradient(90.11deg, #CAFF34 0.13%, #9BFF7B 99.95%);
  color: black;
}
.disabled {
        background: gray;
    color: white;
}

.create_game:hover {
    cursor: pointer;
}
.disabled:hover {
    cursor: not-allowed;
}
</style>
