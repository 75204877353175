<template>
    <div class="genreCard" :class="{'is-active': isActive}" disabled @click="select()">
        <img class="genreCard__bg" :src="img" />
        <p class="genreCard__name">{{genre.name}}</p>
    </div>
</template>
<script>


export default {
    name: "GenreCard",
    props: ["genre", "isActive"],
    data() {
        return {
        }
    },
    methods: {
        select() {
            console.log("select", this.genre)
            this.$emit("selectCategory", this.genre)
        }
    },
    computed: {
        img() { return this.genre ? this.genre.icons[0].url : ""},
        name() { return this.genre ? this.genre.name : ""}
    },
    mounted() {
//        this.$bind("genre", this.$store.getters['firebase/categories_ref'].child(this.genre_id))
    },
}
</script>
<style lang="scss" scoped>
.genreCard {
    position: relative;
    width: 20vh;
   // height: 20vh;
    &__name {
        position: absolute;
        bottom: 1rem;
        width: 100%;
        text-align: center;
        color: white;
        font-weight: 700;
    }   
    &__id {
        font-size: 8px;
    }
    &__bg {
        width: 100%;
        height: 100%;
      //  filter: grayscale(100%);
        border-radius: 8px;
    border: white solid 1px;

    }
}
.genreCard:hover {
    cursor: pointer;
}
.is-active {
    border: 2px solid white;
}
</style>