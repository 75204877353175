import api from '@/api';
import { db } from './firebase'
import { firebaseAction } from 'vuexfire'
import game from './game';
import router from '@/router'



const state = {
    landscape_mode: true,
    community_games: [],
    // used to store game data, each key represents a category or genre, and the value is an array of games belonging to that category.
    gamesByCategory: {}, 
    active_tab: null,

};
const getters = {
    getAllGames: state => state.gamesByCategory,
    // TODO: give a better name to the below logic
    getGamesByGenreId: (state) => (id) => { return id ? state.gamesByCategory[id] : [] },
    gamesCurrentTab: (state, getters) => (id) => { return getters.getAllGames[getters.getActiveTab.id].map(a => a) },

    sorted_games: (state, getters, rootState, rootGetters) => (id) => { 
        
        if (getters.getActiveTab.id == "community") {
            return gameSortingAlgorithm(rootGetters['user/id'], getters.getAllGames[id]) 
        } else if (getters.getActiveTab && getters.getAllGames[id] && getters.getAllGames[id].length > 0) {
            return Object.values(getters.getAllGames[id]) 
         } else { return {} }
    },
    // Filter games where current user is in the player attribute
    getFilteredAndSortedGamesById: (state, getters, rootState, rootGetters) => (id) => { 
        return getters.sorted_games(id)// ? filterGames(getters.sorted_games(id), rootGetters['user/id']) : []
    },

    getActiveTab: state => state.active_tab,
    getLandscapeMode: state => state.landscape_mode,
}

const mutations = {

    setLandscapeModal(state, mode) {
        state.landscape_mode = mode;
    },
    SET_SCREEN_ORIENTATION(state, orientation) {
        state.landscape_mode = orientation;
    },
    SET_ACTIVE_TAB(state, tab) {
        console.log("SET_ACTIVE_TAB", tab)
        state.active_tab = tab
    },
    // 
    ADD_PLAYLISTS_TO_GAMES(state, payload) {
        state.gamesByCategory[`${payload.key}`] = payload.playlists
    }
}
const actions = {
    // NOTE: BINDING FIREBASE REF to 'GAMES' REF CRASHES VUE DEVTOOLS 
    // WHEN TOO MANY GAMES SAVED IN DB
    bindCommunityGames: firebaseAction((context) => {
        var ref = db.ref('games').orderByChild('start_date').endAt(new Date().valueOf())
        return context.bindFirebaseRef(`community_games`, ref).then(() => {
            context.commit('ADD_PLAYLISTS_TO_GAMES', {key: "community", playlists: context.state.community_games}) 
        })
    }),
    async playNextGame(context, {game}) {
        console.log('webHQ/playNextGame', game)
        const game_tag = game.tags ? Object.keys(game.tags)[0] : "community"
        if (game.tags) {
            await context.dispatch('spotify/fetchPlaylistsForGenre', {genre: game_tag}, {root: true})
            const games = context.getters['games'](game_tag)
            console.log("NEW GAMES", games)
                console.log("playNExtGame", games)
        
                if (games) {
                    var game_id = Math.floor(Math.random() * games.length - 1)
                    while (game_id == context.rootGetters['context/cGame_id']) {
                        game_id = Math.floor(Math.random() * games.length - 1);
                    }
                    const newGame = games[game_id]
                    context.dispatch('context/quickStart', {playlist: newGame, tags: [game_tag]}, {root: true})
                    console.log('starting game')
                    router.push({name: "Poll", params: { id: game_id }})
        
                }
    
        } else {
            console.log("webHQ?game_tag", game_tag)
        }
    },
    async joinGame(context, {game}) {
        const track_count = 8   
        console.log("ConfirmationPage.joinGame()", game.id, context.rootGetters["user/id"])
        if (!context.rootGetters['auth/user_logged_in']) {
            console.log("User not logged in")
            context.dispatch("auth/login", {game_redirect_id: game.id}, { root: true});
        }
        else if (game.id.charAt(0) == "-") {
            console.log("Joining game")
            context.dispatch('context/joinGame', {game: game}, {root: true})
        } else {
            var tags = context.getters['getActiveTab'] ? [context.getters['getActiveTab'].id] : []
            await context.dispatch("context/quickStart", {playlist: game, tags: tags,  track_count: track_count}, {root: true})
        }
    }
}

const module = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

export default module;
function gameIsOver(game) {
    var ms = millisecondsToGameOver(game)
    return ms ? ms < 0 : false
}

function millisecondsToGameOver(game) {
    var date = new Date();
    if (game && game.expire_date && typeof game.expire_date == "number") {
        return game.expire_date - date.valueOf() 
    } else {
        return null
    }
}

function sortGamesByExpirationDateAscending(gameA, gameB) {
    return gameB.expire_date - gameA.expire_date
}

//filter games where current user is in the player attribute
function filterGames(games, user_id) {
    if (!games || !Array.isArray(games) ) { return [] }
    console.log("filterGames", games)
    return games.filter(game => {
        const userIsInGame = game && game.players && game.players[user_id]
        return (game && !game.players) || !userIsInGame
    })
}

function gameSortingAlgorithm(user_id, games) {
    console.log('gameSortingAlgorithm innit', games)
    if (!games || games?.length == 0) { 
        return games 
    }
    return [...games].sort(function(gameA, gameB) {
                                var gameAIsOver = gameIsOver(gameA)
                                var gameBIsOver = gameIsOver(gameB)
                                //Sort expired games by expiration date ascending (most recent first)
                                if (gameAIsOver && gameBIsOver) {
                                    sortGamesByExpirationDateAscending(gameA, gameB)
                                } 
                                /*Sort active games by 
                                    1. user's own games first
                                    2. then games with highest tokens next 
                                    3. and if tokens are equal, the ones with soonest expiration date
                                */
                                else if (!gameAIsOver && !gameBIsOver) {
                                    var isOwnerGameA = gameA.created_by == user_id
                                    var isOwnerGameB = gameB.created_by == user_id
                                    if ((!isOwnerGameA && !isOwnerGameB) || (isOwnerGameA && isOwnerGameB)) {
                                        return  gameB.tokens - gameA.tokens == 0 
                                                ? gameA.expire_date - gameB.expire_date 
                                                : gameB.tokens - gameA.tokens
                                    } else {
                                        return isOwnerGameB - isOwnerGameA
                                    }
                                }
                                // Show non-expired games before expired games 
                                else   {
                                    return gameAIsOver - gameBIsOver
                                }
                })


}




// function gameSortingAlgorithm(vm, games) {
//     if (games.length == 0) { return games }

//     //filter games where current user is in the player attribute

// //     var filtered_games = games.filter(game => {
// //         return game.players && !game.players[vm.$store.state.user.id]
// // ///        return !Object.hasOwn(game.players, vm.$store.state.user.id)
// //    })
// //    console.log("FILTERED GAMES", filtered_games, games.map(game => game.players[vm.$store.state.user.id] == null))
//     return [...games].sort(function(gameA, gameB) {
//                                 var gameAIsOver = vm.gameIsOver(gameA)
//                                 var gameBIsOver = vm.gameIsOver(gameB)
//                                 //Sort expired games by expiration date ascending (most recent first)
//                                 if (gameAIsOver && gameBIsOver) {
//                                     sortGamesByExpirationDateAscending(gameA, gameB)
//                                 } 
//                             }
//                         }
                                /*Sort active games by 
                                    1. user's own games first
                                    2. then games with highest tokens next 
                                    3. and if tokens are equal, the ones with soonest expiration date
                                
                                    else if (!gameAIsOver && !gameBIsOver) {
                                        var isOwnerGameA = gameA.created_by == vm.user_id
                                        var isOwnerGameB = gameB.created_by == vm.user_id
                                        if ((!isOwnerGameA && !isOwnerGameB) || (isOwnerGameA && isOwnerGameB)) {
                                            return  gameB.tokens - gameA.tokens == 0 
                                                    ? gameA.expire_date - gameB.expire_date 
                                                    : gameB.tokens - gameA.tokens
                                        } else {
                                            return isOwnerGameB - isOwnerGameA
                                        }
                                    }
                                    // Show non-expired games before expired games 
                                    else   {
                                        return gameAIsOver - gameBIsOver
                                    }
                    })
    }*/
    