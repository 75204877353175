import request from "./request";

// const createRequestInterceptor = (store) => {
//     request.interceptors.request.use(function (config) {
//         if (store.getters["auth/getAccessToken"]) {
//             config.headers.common["Authorization"] = `Bearer ${store.getters["auth/getAccessToken"]}`;
//             config.headers.common["Content-Type"] = 'application/json';
//         }
//         return config;
//     }, null);
    
// };

const createResponseInterceptor = (store) => {
    console.log("wyre() createResponseInterceptor");
    request.interceptors.response.use(null, async (error) => {
        console.log("wyre createResponseInterceptor()")
        if (error.response) {
            console.log("ERROR FOUND", error.message);
            const { status, data } = error.response;

            if (status === 403 && data.error.reason === "PREMIUM_REQUIRED") {
                store.dispatch("notification/add", {
                    type: "warning",
                    message: "You need to have premium account to access this feature."
                });
            }
        
            if (status === 401){
                store.dispatch("notification/add", {
                    type: "error",
                    message: "Wyre Unauthorized. If you're seeing this error, please refresh your page!",
                    duration: 100
                });
            }
            if (status !== 200){
                store.dispatch("notification/add", {
                    type: "error",
                    message: "If you're seeing this error, please refresh your page!"
                });
            }
            if (status === 404) {
                store.dispatch("notification/add", {
                    type: "error",
                    message: "Wyre not found. If you're seeing this error, please refresh your page!",
                    duration: 5000
                });
            }
            if (status === 400) {
                store.dispatch("notification/add", {
                    type: "error",
                    message: "Wyre Bad Request. If you're seeing this error, please refresh your page!",
                    duration: 5000
                });
            }
            if (status === 500) {
                store.dispatch("notification/add", {
                    type: "error",
                    message: "Wyre Internal Server Error. If you're seeing this error, please refresh your page!",
                    duration: 5000
                });
            }
            // reject promise if custom error
            if (status !== 401 && status !== 400) {
                console.log("Returning now", status)
                return Promise.reject(error);
            }
            console.log("Will attempt to refresh token")
            try {
                store.dispatch("auth/refreshToken");
            } catch (e) {
                console.error(e);
            }
        }

    });
};

const plugin = (store) => {
//    createRequestInterceptor(store);
    createResponseInterceptor(store);
};

export default plugin;
