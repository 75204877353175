<template>
    <nav class="navbar" :class="show_bg ? 'navbar-dark' : 'navbar-transparent'" role="navigation" aria-label="main navigation">
        <nav-bar-left-side v-if="!hideLogo" /> 
        <div class="navbar-right" >          
          <!-- <button v-if="!userHasWallet" class="green-button navbar-item mr-4" @click="createWallet()">
            Claim Wallet
          </button> -->
          <navbar-token-balance :show_tokens="show_tokens" v-if="show_tokens" />
          <div v-if="showDoor" class="navbar-item door" @click="goToStreaming()" >
            <close-icon />
          </div>
          <pfp-menu v-if="!$isMobile() || true" />
        </div> 
      
    </nav>

</template>

<script>

//import { mapGetters } from "vuex";
import CloseIcon from '@/components/SVGs/Close.vue';
import { mapGetters } from 'vuex'
import { db } from '@/store/modules/firebase';
import '@/assets/css/profile.scss';
import NavBarLeftSide from './LeftSide.vue';
import NavbarTokenBalance from './TokenBalance.vue';
import PfpMenu from './PfpMenu.vue';
export default {
  name: 'NavBar',
  data() {
    return {
      query: "",
      artists: [],
    }
  },
  components: { CloseIcon, NavBarLeftSide, PfpMenu, NavbarTokenBalance },
  firebase: {
        latest_version: db.ref('latest_version')
  },
  mounted() {
    this.getWalletBalance()
    console.log("mounted, balance: ", this.balance)
  },
  computed: {
    show_bg() {             return this.$route.name != 'ProfilePage' },
    showTransparent() {     return this.$route.name != 'ProfilePage' },
    showDoor() {            return this.$isMobile() && ((this.$route.name == "Poll" && !this.showPortraitModal) || this.$route.name == "GameComplete") },
    show_tokens() {         return ["Browser", "ConfirmationPage", "Poll", "GameComplete", "ProfilePage", "Streaming"].includes(this.$route.name) }, // || this.$store.getters['modals/show_game_complete_modal']},
    show_middle() {         return this.$route.name != "ProfilePage" && !this.$isMobile() || false },
    userHasWallet() {       return !!this.$store.getters['user/wallet'] },
    hideLogo() {            return (this.$isMobile() && this.$route.name == "GameComplete") },
    showPortraitModal() {   return this.$route.name == "Poll" && this.$isMobile() && !this.landscape_mode}, //TO DO - Fix
  },
  methods: {
    goToStreaming() {
      this.$router.push({name: "Streaming"})
    },
    goToProfile() {
        if (!this.$store.getters['user/is_logged_out']) {
        this.$router.push({name: "UserProfile"})
        }
    },
    createWallet() {
      console.log("create wallet")
      this.$store.dispatch('user/createWallet')
    },
    getWalletBalance() {
      console.log("get wallet balance!!!!")
      this.$store.dispatch('user/getWalletBalance')
    },  
  }
}
</script>

<style lang="scss" scoped>
nav {
  /* height: 5.625rem; 10vh; */
  padding-left: 12px;
//  z-index: 1; 
  display: flex;
  align-items: center;
  position: relative;
}
.navbar-dark {
  background: #16171B;
}
.navbar-transparent {
  background: transparent;
}

.navbar-middle {
  display: flex;
  position: absolute;
  top: 50%;
  left: 40%;
}
.middle-button {
  padding: 1rem;
  margin: 0 5px;
  /* z-index: 10; */
  /* background-color: rgba(51, 51, 51, 0.2); */
  color: white;
  background-color: transparent;
  border-width: 0;
  font-weight: 600;

}
.navbar-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.door {
  display: flex;
  &__svg {
    height: 41%;
    width: 8.7%;
    margin-left: auto;
  }
  &__svg:hover {
    cursor: pointer;
  }
}
.navbar-dropdown {
  background-color:#16171B;
  display: none
}
.search-results {
  background-color: rgba($color: #fff, $alpha: 0.02);
}
.navbar-link:hover {
    background-color:#16171B;
}
.navbar-item:hover {
  background-color: transparent;
  color: white;
  cursor: pointer;
}
.navbar-item:focus {
  background-color: rgba($color: #fff, $alpha: 0.5);
//  background-color: #424752;
  border-radius: 8px;
  color: white;
}
a {
  color: white;
}

.v-center {
  margin-right: 1rem;
  top: 50%; 
  transform: translateY(-50%);
}
.relative {
  position: relative;
}

.navbar-middle {
  top: auto;
}
</style>