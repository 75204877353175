import spotify from "./spotify";
import auth from "./auth";
import wyre from "./wyre";
import payments from "./payments";
export default {
    spotify,
    auth,
    wyre,
    payments
};
