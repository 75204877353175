<template>
    <div class="questionaire">
        <div id="recaptcha-container" class="justify-center flex"></div>    
        <h1>SnipitFM</h1>
        <div v-if="!otpSent" class="primary-text-wrapper">
            <p class="emoji">📲</p>
            <p class="primary-text"><span class="prefix">What’s your</span> <em class="suffix">number</em></p>
            <div class="inpField">
                <input type="text" v-model="phNo" name="phoneNumber" id="phoneNumberInp" placeholder="(123)-456-7890">
                <button @click="sendOtp()">Send Code</button>
            </div>
            <p class="disclaimer"> Phone logins prevent trolls and ensure you’re here with real people.</p>
        </div>
        <div v-else>
            <p class="emoji">📲</p>
            <p class="primary-text motto"><span class="prefix">Enter your</span> <em class="suffix">code</em></p>
            <div class="inpField">
                <input type="text" v-model="otp" name="phoneNumber" id="phoneNumberInp">
                <button class="verify-btn" @click="verifyOtp()">Confirm</button>
            </div>
            <p class="disclaimer"> We just sent a code to your phone number.</p>
        </div>
    </div>
</template>
<script>
import firebase from 'firebase/compat/app'
//Source: https://github.com/devatquarxss/phone-password-authentication-firebase/blob/master/src/components/signup.vue

export default {
    name: "PhoneSignIn",
    data() {
        return {
            otpSent: false,
            phNo: "",
            otp: "",
            appVerifier: '',
        }
    },
    mounted() {
        //this.$store.dispatch("firebase/recaptchaVerifierInvisible")
        this.initReCaptcha()
    },
    methods: {
       phoneSignIn() {

       },
        sendOtp(){
            if(this.phNo.length != 10) {
            alert('Invalid Phone Number Format !');
            } else {
            //
            let countryCode = '+1' //india
            let phoneNumber = countryCode + this.phNo
            //
            let appVerifier = this.appVerifier
            let vm = this
            //
            firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
                .then(function (confirmationResult) {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                vm.otpSent = true
                }).catch(function (error) {
                // Error; SMS not sent
                // ...
                alert('Error ! SMS not sent')
                console.log(error)
            });
            }
        },
        verifyOtp(){
            if(this.phNo.length != 10 || this.otp.length != 6){
            alert('Invalid Phone Number/OTP Format !');
            }else{
            //
            let vm = this
            let code = this.otp
            //
            window.confirmationResult.confirm(code).then(function (result) {
                // User signed in successfully.
                var user = result.user;
                console.log("User Signed In Successfully", user)
                // ...
                //route to set password !
                vm.$emit("phoneSignInClosed")
                //vm.$router.push({name:'Browser'})
            }).catch(function (error) {
                console.log("Error", error)
                // User couldn't sign in (bad verification code?)
                // ...
            });
            }
        },
        initReCaptcha() {
            console.log("INIT RECAPTCHA")
            setTimeout(()=>{
                console.log("STARTING I THINK")
                window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': function(response) {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    // ...
                    console.log("RESPONSE: ", response)
                    },
                    'expired-callback': function() {
                        console.log("ERROR: ")
                        // Response expired. Ask user to solve reCAPTCHA again.
                    // ...
                    }
                });
                //
                this.appVerifier =  window.recaptchaVerifier
                console.log(window.recaptchaVerifier)
            },1000)
        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-size: 32px;
    font-weight: 700;
}
input {
    background: rgba(255, 255, 255, 0.65);
    box-shadow: 0px 4px 16px rgba(255, 255, 255, 0.44);
    border-radius: 13px;
    width: 83vw;
    height: 8.3vh;
    border: 0;
}
input[type="text"] {
    color: #505050;
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
}
input[type="text"] {
    border-color: #D9FF7B;
}
.questionaire {
    display: flex;
    flex-direction: column;
  //  justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
   // gap: 10vh;
    background: rgba(31, 31, 31, 0.28);
    padding-top: 3rem;
}
.buttons {
    display: flex;
}
.buttons-grid {
    display: grid;
    grid-template-columns: 42.5% 42.5%;
    width: 90%;
    gap: 4%;
    flex: 2 1 0%;
    margin: 0 auto;
    justify-content: center;
}
.question {
    font-size: 3rem;
}
button {
    background: linear-gradient(270deg, #9BFF7B 0%, #D9FF7B 100%);
    border: 0;
    font-size: 2.5rem;
    border-radius: 2.5rem;
    height: 5rem;
    font-weight: 600;
 //   box-shadow: rgb(66 55 45 / 16%) 0px 4px 16px;
}
.primary-text-wrapper {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 77px;
    // line-height: 78px;
}

.question {
    line-height: 58px;
    padding: 0 16px;
}
.emoji {
    font-size: 7rem;
}
.primary-text {
   font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 4rem;

}
.prefix {
    font-weight: 600
}
.suffix {
    font-weight: 700
}
.disclaimer {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-top: 6vh;
}
@media only screen and (min-width: 1440px) {
    input[type="text"] {
        width: 50vw;
    }
}
@media only screen and (max-width: 480px) {
    button {
        height: 4rem;
        font-size: 2rem;
        padding: 0 2rem;
    }
    .verify-btn {
        margin-top: 1.5rem;
    }
    .primary-text {
    font-size: 2rem;

    }
}
</style>