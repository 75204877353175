<template>
    <div>
        <div class="tokens">
            <div class="lhs">
                <p>You Won</p>
                <token-balance :show_tokens="true" />
            </div>
            <p v-if="game.expire_date">{{  minutesUntil(game.expire_date) }}</p>
        </div>    
    </div>
</template>

<script>
import { gameLogic } from "@/mixins";
import TokenBalance from '@/components/TokenBalance';

export default {
    name: "JackpotInfo",
    components: { TokenBalance },
    mixins: [gameLogic],
    props: ["game"],
    computed: {
        jackpot() { return this.game ? this.game.tokens : 0 },
        date_str() { return this.game.expire_date ? new Date(this.game.expire_date).toDateString() : '' },
        countdown_label() { return this.hoursMinutesAndSecondsToGameOverFormatted(this.game) },
        userTimeZone() {
            return new Intl.DateTimeFormat('en-us', { timeZoneName: 'short' })
                    .formatToParts(new Date())
                    .find(part => part.type == "timeZoneName")
                    .value
        },
        expireDate_label() {
            if (this.game?.expire_date) {
                var expire_date = new Date(this.game.expire_date)
                var dateString = expire_date.toDateString()
                var timeString = expire_date.toLocaleTimeString()
                var timezone = this.userTimeZone
                var time = `${timeString.substring(0, 5)}${timeString.substring(8)}`
                return `${dateString} at ${time} ${timezone}`
            } else { 
                return ''
            }
        },

    },
    methods: {
        endGame() {
            this.$store.dispatch("game/endGame", this.game)
        },
        minutesUntil(datetimeString) {
            console.log("MINUTES UNTIL", datetimeString)
            if (!datetimeString) { return "" }
            const now = new Date();
            const futureTime = new Date(datetimeString);
            const millisecondsUntil = futureTime.getTime() - now.getTime();
            const minutesUntil = Math.round(millisecondsUntil / (1000 * 60));

            if (minutesUntil < 60) {
                // If less than an hour, just return the number of minutes
                return minutesUntil.toString().padStart(2, "0") + "m";
            } else {
                // If an hour or more, format as "Xh Ym"
                const hoursUntil = Math.floor(minutesUntil / 60);
                const minutesLeft = minutesUntil % 60;
                const formattedTime = hoursUntil.toString() + "h " + minutesLeft.toString().padStart(2, "0") + "m";
                return formattedTime;
            }
        },
    }

    
}
</script>
<style lang="scss" scoped>
.tokens {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    width: 100%;
    margin-top: 16px;
    max-width: 300px;
    font-family: 'Rubik-Bold';
    color: #E0E0E0;

    &__label {
        font-family: 'Rubik-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
    }
}
.lhs {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.jackpot {
    color: #E0E0E0;
    &__date {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        margin-bottom: 29px;
    }
    &__date_label {
        font-weight: 400;
                margin-bottom: 6px;

    }
    &__size_label {
        
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        font-size: 18px;
        line-height: 21px;
    }
    &__size {
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        margin-top: 1rem;    
    }
}

hr {
    width: 38%;
    margin: 29px auto;
    background-color:  #505050;
    size: 1;
    

}
button {
    background: transparent;

    border-radius: 8px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    width: 76%;
    padding: 15px 0;
    border: 0;
    margin-top: 2rem;
}
button:hover {
    cursor: pointer;

}
button:disabled {
    opacity: 50%;
}
</style>