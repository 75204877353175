<template>
  <div class="section" v-if="!$isMobile()">
    <img class="back_btn" @click.prevent.stop="unselectItem()" src="@/assets/rewindWhiteFilled.png" />
    <div class="confirmation_container">
        <div class="lhs">
            <img class="game_image" :src="game_image" alt="">
        </div>
        <div class="rhs">
            <p v-if="creator" class="creator_label">created by <strong>{{creator_username}}</strong></p>
            <h1 class="game_name_label mb-3 mt-3">{{game_name}}</h1>
            <span v-for="tag in game_tags" class="genre_tag" :key="tag" > {{tag}} </span> <br />
            <!-- Box 1 -->
            <div class="time_left">
                <img class="time_left__clock_icon mr-1" src="@/assets/clock_icon.png" />
                <p v-if="!game_started" class="time_left__label">Game starts <span class="time_left__countdown_timer">{{startDate_label}}</span></p>
                <p v-else-if="game_started && !game_expired" class="time_left_label"><span v-if="expireDate_label.length > 0"> Game ends in</span> <span v-else>This game has not been started yet</span> <span class="countdown_timer">{{countdown_label}}</span> <span v-if="expireDate_label.length > 0">({{expireDate_label}})</span></p>
                <p v-else class="time_left__label" > Game ended {{expireDate_label}} </p>

            </div>
            <!-- Box 2 -->
            <div class="game_info mt-3">
                <p><span class="game_info__description">Players</span> <span class="game_info__data">{{playerCount}}</span></p>
                <p><span class="game_info__description">Entrance Fee:</span> <span class="game_info__data">{{game_fee || "5000"}}</span></p>
                <p><span class="game_info__description">Jackpot:</span> <span class="game_info__data">{{jackpot}}</span></p>
            </div>
            <div v-if="game_started">
                <button class="green_gradient" :class="{'pulse': shouldStartPulsing}" @click="joinGame()">{{cta_label}}</button>
                <div class="disclaimer"></div>
                <!-- <p class="disclaimer_text">To start playing {{fee}} tokens will be deducted from your account. </p> <p class="disclaimer_text">All sales are final </p> -->
            </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
    name: "DesktopConfirmationPage",
    props: ["game", "game_name", "game_tags", "game_image", "game_started", "game_expired", "creator", "playerCount", "fee", "cta_label", "countdown_label"],
    methods: {
        joinGame() { this.$emit("joinGame")     }
    },
    data() {
        return {
            shouldStartPulsing: false
        }
    },
    mounted() {
        setTimeout(() => {
            this.shouldStartPulsing = true
        }, 700)
    },
    computed: {
        game_fee() { return this.game?.fee || ""},
        jackpot() { return this.game?.tokens || 0 },
        userTimeZone() {
            return new Intl.DateTimeFormat('en-us', { timeZoneName: 'short' })
                    .formatToParts(new Date())
                    .find(part => part.type == "timeZoneName")
                    .value
        },
        expireDate_label() {
            if (this.game?.expire_date) {
                var expire_date = new Date(this.game.expire_date)
                var dateString = expire_date.toDateString()
                var timeString = expire_date.toLocaleTimeString()
                var timezone = this.userTimeZone
                var time = `${timeString.substring(0, 5)}${timeString.substring(8)}`
                return `${dateString} at ${time} ${timezone}`
            } else { 
                return ''
            }
        },
        startDate_label() {
            if (this.game?.start_date) {
                var start_date = new Date(this.game.start_date)
                var dateString = start_date.toDateString()
                var timeString = start_date.toLocaleTimeString()
                var timezone = this.userTimeZone
                var time = `${timeString.substring(0, 5)}${timeString.substring(8)}`
                return `${dateString} at ${time} ${timezone}`
            } else {
                return ``
            }
        },
        creator_username() {
            return this.creator?.username || "SnipitFM"
        }
    }
}
</script>

<style lang="scss" scoped>
.confirmation_container {
    display: flex;
    text-align: left;
    margin-top: 1rem;
}
.back_btn {
    max-width: 40px;
    height: auto;
}
.game_image {
    max-width: 83%;   
}
.time_left {
    //border: 1px white solid;
     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #2C2929;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 8px;
    width: 600px;
    align-items: center;
    display: flex;
    &__clock_icon {
        display: inline;    
    }
    &__label {
        display: inline;
    }
    &__countdown_timer {
        font-weight: 600;
    }
}
.game_info {
    margin-top: 11px;
    display: flex;
    justify-content: flex-start;
    width: 600px;
    //border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #2C2929;
    gap: 9.5%;
    padding-top: 1rem;
    padding-bottom: 19px;
    padding-left: 12px;
    &__row {
        display: flex;
        justify-content: space-between;
        padding-right: 66px;
        margin-bottom: 20px;
    }
    &__description {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: flex-end;
        color: rgba(255, 255, 255, 0.68);
    }
    &__data {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: flex-end;
        color: #FFFFFF;
    }
}
.pulse {
   //animation: animate-shake 2s ease-in-out infinite
   animation: growWiggleShrink infinite 2s;
}

@keyframes growWiggleShrink {
    0%, 100% {
        transform: scale(1);
    }
    25%, 75% {
        transform: scale(1.3);
    }
    //down
    35% {
        transform: scale(1.3) rotate(5deg);
    }
    //up
    40% {
        transform: scale(1.3) rotate(-5deg);
    }
    //down
    45% {
        transform: scale(1.3) rotate(5deg);
    }
    //up
    50% {
        transform: scale(1.3) rotate(-5deg);
    }
    //down
    55% {
        transform: scale(1.3) rotate(5deg);
    }
    //up
    60% {
        transform: scale(1.3) rotate(-5deg);
    }  //down
    65% {
        transform: scale(1.3) rotate(eg);
    }

  

}
.creator_label {
    font-size: 14px;
    line-height: 17px;
}
strong {
    color: white;
}
.game_name_label {
    font-weight: 600;
    font-size: 2rem;
    line-height: 36px;
}
.popularity_label {
    font-weight: 600;
    font-size: 1rem;
    line-height: 17px;
}
.green_gradient {
    background: linear-gradient(90deg, #CAFF34 0%, #9BFF7B 104.09%), #FFFFFF;
    border-radius: 20px;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    width: 257px;
    height: 53px;
    line-height: 19px;
    margin-top: 2.5rem;
    border: 0;
}
.green_gradient:hover {
    cursor: pointer;
}
.rhs {
    color: white;
}
</style>