<template>
    <section class="mobile-section">
    <div class="foreground">
        <span class="app_name">SnipitFM</span>
        <h1 class="artist_name"> {{artist_name}}'s Version</h1>
        <div class="pfp-container">
            <img class="pfp pfp-mobile" :src="artist_image" alt="">
            <div class="glow"></div>
        </div>
        <div class="socials">
            <img class="" src="" alt="">
            <img class="" src="" alt="">
            <img class="" src="" alt="">
        </div>
        <h2 class="playlists_title"> Featured in</h2>
        <ul class="playlists">
            <li class="playlist" @click="joinGame(playlist)"  v-for="playlist in playlists" :key="playlist.id">
                <img class="playlist__cover" :src="playlist.images[0].url" alt="">
                <h3 class="playlist__name">{{playlist.name}}</h3>
            </li>
        </ul>
    </div>
    <div class="background gradient"></div>

</section>
</template>
<script>
import '@/assets/css/profile.scss';

export default {
    name: "MobileProfile",
    props: {
        artist_name: {
            type: String,
            required: true
        },
        artist_image: {
            type: String,
            required: true
        },
        playlists: {
            type: Array,
            required: true
        },
    },
    methods: {
        joinGame(playlist) {
            this.$emit('joinGame', playlist)
        }
    }
}
</script>

