<template>
  <section class="topbar" :class="{'is-transparent': landscape_mode}">
    <div v-if="true" class="left">
        {{playlist_name}}
        <a class="ml-2 mr-2" v-if="false" @click="finishGame()" >✌️</a> <a @click="skipPlaylist()" v-if="false">  👉</a>
    </div>
    <div v-else class="left streaming">
      <div class="streaming__btns">
        <div class="streaming__btn1 btn">Streaming Mode</div>
        <div class="streaming__btn2 btn" @click="showStreamingModal()">Play</div>
      </div>
    </div>
  <div class="center">
    {{  progress_text }}
  </div>
  <div v-if="showShuffleButton" @click="shufflePlaylist()" class="right">
    <span>Shuffle</span>
    <shuffle-icon />
  </div>
  <div v-else class="right">
    <!-- <div class="dropdown is-hoverable is-right" v-if="true">
        <div class="dropdown-trigger">{{tag.name || "Rap"}}</div>
        <div class="dropdown-menu ">
          <div class="dropdown-content"><a class="dropdown-item" v-for="tag in tags" :key="tag.id" @click="setCurrentTag(tag)"> {{tag.name}} </a></div>
        </div>
    </div> -->
  </div>

  </section>
</template>


<script>
import Vue from "vue"
import { gameLogic } from "@/mixins";
import { mapGetters } from "vuex";
import ShuffleIcon from "@/components/SVGs/Shuffle.vue";
export default {
  name: 'TopBar',
  components: {
    ShuffleIcon
  },
  props: {
    streaming_mode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tag: this.$store.state.context.current_tag,
    }
  },
  mixins: [gameLogic],

  computed: {
    total_polls() { return this.$store.state.context.game_mode == 0 ? (Object.keys(this.game_tracks).length - 1) || 0 : "∞" }, // the amount of polls in the game is the amount of tracks - 1
    playlist_name() { return this.game?.name || ""},
    tags() { return this.$store.state.context.tags},
    
    playlist_image() { return this.game ? this.game.image : null},
    showShuffleButton() { return !this.$isMobile() }, //this.$store.getters['modals/streamingModalVisible '] },
    ...mapGetters({
      isAdmin: "user/isAdmin",
      game: 'context/cGame',
      poll_index: 'context/cPoll_index',
      game_tracks: 'context/cGame_tracks',
      poll_ids: 'context/cPoll_ids',
      landscape_mode: 'webHQ/getLandscapeMode'
    }),
    progress_text() {
      return `${this.poll_index + 1} / ${this.total_polls}` 
    }
  },
  methods: {
    finishGame() {
      Vue.set(this.$store.state.context, "cPoll_index", this.total_polls)
    }, 
    shufflePlaylist() {
      this.$store.dispatch('context/shufflePlaylist')
    },


  }
}


</script>

<style lang="scss" scoped>
.topbar {
  overflow: initial;
  background:rgba(0,0,0, 0.50);
  font-size: 16px;
  width: 100%;
  display:flex;
  align-items: center;
  color: white;
  
}
a { 
  color: white;
}
.left { 
  /* background:green; */
  width:33.33vw;
  padding-left: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

}
.streaming {
  padding-left: 0;
  position: relative;
  &__btns {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;

  }
  &__btn1 {
    width: 12vw;
    padding: 0.5rem 1.5rem;
    font-weight: 700; 
    background: #CAFF34;
    color: black;
    border-radius: 0 8px 0 0;
    position: relative;
    z-index: 3;
  }
  &__btn2 {
    width: 12vw;
    padding: 0.5rem 1.5rem;
    background: #2F2F2F;
    color: white;
    border-radius: 8px 8px 0 0;
    position: relative;
    z-index: 2;
    margin-left: -0.5rem;
  }
}
.center{
  /* background:gold; */
   width:33.33vw;
     height: 100%;
     display: flex;
    align-items: center; /* Vertical center alignment */
    justify-content: center; /* Horizontal center alignment */
    font-weight: 500;

}

.right{
  /* background:gray; */
  width:33.33vw;
  height: 100%;
  text-align: right;
  padding-right: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
}
.right:hover {
  cursor: pointer;
}

.dropdown-content {
  background:rgba(0,0,0, 0.50);
}
.dropdown-menu {
  height: 70vh;
  overflow: scroll;
}
.dropdown-item {
  color: white;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn:hover {
  cursor: pointer;
}
.is-transparent {
  background: transparent;
}
@media screen and (max-width: 479px) {

}
</style>
