<template>
    <div class="navbar-item has-dropdown is-hoverable">
        <img v-if="showProPic" class="navbar-link propic"  :src="profile_pic" style="padding: 0;" />
        <div class="navbar-dropdown is-right" >
            <hr class="navbar-divider">
            <div class="navbar-item">
                Version {{ version_number }} ({{ env_name }})
            </div>
        </div>
    </div>
</template>
<script>
import '@/assets/css/profile.scss';
import { db } from '@/store/modules/firebase'
export default {
    name: 'PfpMenu',
    data() {
        return {
            version: 1.1,
            latest_version: 2.0,    
            env: null
        }
    },
    firebase: {
        env: db.ref('env')
    },
    computed: {
        showProPic()  {         return true                                                                   },
        profile_pic() {         return  this.$store.state.user._profile?.image || require("@/assets/PFP.png") },
        version_number() {      return this.$store.getters.appVersion                                         },
        env_name() {            return this.env ? this.env.name : ""                                          }
    }
}
</script>

<style lang="scss" scoped>
.propic {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    height: 1.9rem;
    width: 1.9rem;
    padding: 0;
}
.navbar-link:hover {
    background-color:#16171B;
}
.navbar-item:hover {
    background-color: transparent;
    color: white;
    cursor: pointer;
}
.navbar-item:focus {
    background-color: rgba($color: #fff, $alpha: 0.5);
    //  background-color: #424752;
    border-radius: 8px;
    color: white;
}
.navbar-dropdown {
    background-color:#16171B;
    display: none
}
</style>