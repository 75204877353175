<template>
          <div class="navbar-item has-dropdown is-hoverable">
            <div class="tokens_container" @click.prevent="openBuyTokensModal()" >
              <img v-if="show_tokens" :src="token_icon" style="padding: 0;" class='mr-1' />
              <div class="relative">
                <a v-if="show_tokens" class="token-button navbar-item grow " > {{Math.round(tokens)}} </a>
                <span v-if="show_tokens && showNotification" class="numberCircle"><span></span> </span>
              </div>
            </div>
          </div>
</template>

<script>
import api from '@/api';

export default {
    name: 'NavbarTokenBalance',
    props: {
        show_tokens: {
            type: Boolean,
        }
    },
    data() {
        return {
            showNotification: false,
            
        }
    },  
    computed: {
        balance() {             return this.$store.getters['user/balance'] || 0 },
        tokens() {              return this.$store.getters['user/profile']?.tokens || 0 },
        token_icon() {          return require("@/assets/coins.png") },  
        green_token_icon() {    return require("@/assets/green-token-sfm.png") },
    },
    methods: {
    //     async openBuyTokensModal() {
    //         console.log("open buy tokens modal")  
		// 	const reservation = await api.wyre.createWalletOrderReservation();
    //         console.log("response: ", reservation)
    //         //const reservation = response.data.response;
		// 	if (reservation) {
		// 		const cwidget = new window.Wyre.Widget({
		// 			env: 'test',
		// 			reservation: reservation,
		// 			operation: {
		// 				type: 'debitcard-hosted-dialog'
		// 			}
		// 		});
		// 		cwidget.on("paymentSuccess", function(e) { console.log(e);});
		// 		cwidget.on("close", function(e) { console.log(e);});
		// 		cwidget.open();
		// 	} else {
		// 		console.log("reservation creation error. check server");
		// 	}
		// }
    }
}
</script>

<style lang="scss" scoped>

.tokens_container {
  display: flex;

}
.tokens_container:hover {
  cursor: pointer;
}
.token-button {

  color: white;
  background: #282828;
  
  border-radius: 0 8px 8px 0;
  font-size: 12px;
  line-height: 14px;
  padding: 0.5rem 0.5rem;
  

}
.numberCircle {
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
  border: 2px solid white;// #bbbbbb;
  background-color: #6080F1;
  font-size: 12px;
      position: absolute;
      top: 0;
      right: 0;
 
}

.numberCircle span {
  color: white;
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 8px;
  margin-right: 8px;
}
.relative {
  position: relative;
}
/* a:hover {
    background-color: rgba(0, 0, 0, 0) !important;
} */.numberCircle {
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
  border: 2px solid white;// #bbbbbb;
  background-color: #6080F1;
  font-size: 12px;
  position: absolute;
  top: -8px;
  right: 0;
}

.numberCircle span {
  color: white;
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 8px;
  margin-right: 8px;
}

.navbar-item:hover {
  background-color: transparent;
  color: white;
  cursor: pointer;
}
.navbar-item:focus {
  background-color: rgba($color: #fff, $alpha: 0.5);
//  background-color: #424752;
  border-radius: 8px;
  color: white;
}
.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }
</style>