<template>
    <div class="modal__frame">
        <div class="modal__dialog">
            <div class="modal__header">
                <p class="title">Welcome to Secret App</p>
            </div>
            <div class="modal__body">
                <p class="subtitle">Rotate your Screen to Begin.</p>
                <img src="@/assets/landscape_icon.png" alt="" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "PortraitModeModalContent",
}
</script>

<style lang="scss" scoped>
.title {
    font-weight: 500;
    font-size: 18px;
    color: white;
    margin-bottom: 8vh;
    margin-top: 1rem;
}
.subtitle {
    font-weight: 500;
    font-size: 16px;
    color: #9BFF7B;
}
</style>