<template>
    <div class="image image-container" @click="playIfMobile()"               
            @mouseleave="revealMenuUpdated(false)"
            @mouseenter="revealMenuUpdated(true)">
        <img style="width=100%; height=100%"
            class="cover-album"
            v-bind:src="img_src"
            data-content="Here is a caption"
            alt="">
        <div :class="{'menuHidden': !revealMenu, 'menu': revealMenu}">
            <div class="icon-container">
                <div v-if="show_side_icons" class="icon-container__side cursor" >
                    <track-addition class="heart-btn" :track="track" :heart="true" />
                </div>
                <PlayButton class="icon-container__middle ml-3 mr-3"  :track="track" :preview="true" :isPng="true" />
                <div v-if="show_side_icons" class="icon-container__side cursor">
                    <!--This code needs to become a function, not a link, that favorites the song and links to the user's spotify playlist -->
                    <a :href="spotify_url"  target="_blank"><img  class="spotify-btn"  src="@/assets/spotify_icon.png" /></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TrackAddition from '@/components/TrackAddition'
import PlayButton from '@/components/PlayButton/'
export default {
    name: "CoverAlbum",
    props: ["img_src", "track", "spotify_url", "revealMenu"],
    components: {TrackAddition, PlayButton},

    computed: {
        show_side_icons() {
            return !this.$isMobile()
        }
    },
    methods: {
        playIfMobile() { 
            console.log("playIfMobile()")
            if (this.$isMobile()) {
                this.$store.dispatch('audioPlayer/toggle', {track: this.track })//, use_preview: true}) 
            }
            // analytics.logEvent("play", { name: this.track.name, artist: this.artist})
        },
        revealMenuUpdated(bool) {
            if (!this.$isMobile())            this.$emit('revealMenuUpdated', bool)
        },

    }
}
</script>

<style lang="scss" scoped>
.cover-album {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition-timing-function: ease;
   // width: MAX(39vh, 25vw);
}

.image-container {
  /* height: auto; */
  //  width: MAX(39vh, 25vw);
  //width: 18.75rem;
  /* border-radius: 1.5rem; */
    position: relative;
    display: inline-block;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition-timing-function: ease;
    margin: 0 auto;

}
.image-container .after {
//    display: none;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    /* border-r adius: 1.5rem; */
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
      transition-timing-function: ease;
      margin: 0 auto;

    /* color: #FFF; */
}
.image-container:hover .after {
    display: block;
    visibility: visible;
    background: rgba(0, 0, 0, 0.6);
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition-timing-function: ease;


}

.icon-container {
    font-size: 28px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__side {
        width: 2.25rem;
        height: 2.25rem;
        border-radius: 40px;
        display: inline-block;
        position: relative;
    }
    &__middle {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 40px;
        display: inline-block;
        position: relative;
    }
}

.force-show {
    display: block !important;
    visibility: visible !important;
}
.red {
    background: rgba(255, 0, 0, 0.6); 
}



// .image-container .after {
//     display: none;
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     width: 100%;
//     height: 100%;
//     /* border-r adius: 1.5rem; */
//     transition: all 0.5s;
//     -webkit-transition: all 0.5s;
//       transition-timing-function: ease;
//       margin: 0 auto;

//     /* color: #FFF; */
// }
.menu {
    display: block;
    background: rgba(0, 0, 0, 0.6);
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition-timing-function: ease;
   // width: MAX(39vh, 25vw);
    width: 100%; 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.menuHidden {
    display: none;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition-timing-function: ease;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

</style>