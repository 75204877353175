import { db } from './firebase'
import { vuexfireMutations, firebaseAction } from 'vuexfire'
import router from '@/router/index'
// initial state
const NORMAL_MODE = 0
const SEARCH_MODE = 1
// const BRACKET_MODE = 2
// const GAME_MODE = 3

const state = () => ({
    _game_progress: null,
    game_mode: 0, //0 = normal, 1 = search
    // The ID of the current user's most recently chosen game. Used to bind to firebase
    // == "GameComplete"window.localStorage.getItem('_game_id') ? window.localStorage.getItem('_game_id') : null,
    _game_id: null,
    unfinished_games: [],
    _feed_mode_enabled: false,
    _current_game: null,
    _poll_ids: {},
    _poll_index: 0,
    _current_poll: {},
    _option_one: null,
    _option_two: null,
   // _vote_queue: {},
    _game_results: null, //indexes the game's current votes by track. More info in firebase/game_results_ref
    current_tag: "",
    tags: null,
    nextVoteDelay: 500, //3500, //1 second
    nextPollTimer: 0,
    poll_controls_enabled: true,
    preview_mode: false,

})
const getters = {
    cGame: (state) => { return state._current_game || null },
    feedModeEnabled: (state) => { return state._feed_mode_enabled },
    cGame_players: (state, getters) => { return getters.cGame ? getters.cGame.players : null },
//    cGamePlayers
    userIsPlayerInCurrentGame: (state, getters, rootState, rootGetters) => { return getters.cGame_players ? !!getters.cGame_players[`${rootGetters['user/id']}`]  : false },
    cGame_id: (state) => { return state._game_id },
    cPoll: (state) => { return state._current_poll },
    cPoll_index: (state) => { return state._game_progress?.poll_index || 0 },
    cPoll_ids: (state) => {
        //Expected format - { PollID: Index }
        //TO DO: REMOVE EVERYTHING AFTER "||" ITS FOR QUICKLY GETTING UP STREAMING MODE. 
        // SHOULD BE: poll_ids = streaming_Mode ? state._game_progress[user].polls : state._game._progress?.polls
        var poll_ids = state._game_progress ? state._game_progress.polls  : (state._current_game?.polls || {})
        console.log("cPoll_ids: ", state._game_progress, state._current_game, poll_ids)
        // Sort PollIDs by Index in Ascending order
        return sortKeysByValAscending(poll_ids) 
    },
    cGame_track_ids: (state, getters) => { return getters.cPoll_id ? Object.keys(getters.cPoll_id) : [] },
    cPoll_id: (state, getters) => { return getters.cPoll_ids.length > 0 ? getters.cPoll_ids[getters.cPoll_index] : "4wGJ5Q2l1J5Kjrzr5vFp3q,6rPO02ozF3bM7NnOV4h6s2" },
    //Returns the IDs of the two tracks in the poll as an array.
    cPoll_tracks: (state, getters) => { return getters.cPoll_id?.split(",") || [] },
    cGame_tracks: (state, getters) => { return getters.cGame?.tracks || {} },
    cPoll_options: (state, getters) => { return (getters.option_one != null && getters.option_two != null) ? [getters.option_one, getters.option_two] : null},
    option_one: (state) => { return state._option_one },
    option_two: (state) => { return state._option_two },
    prevPollEnabled: (state, getters) => { return getters['cPoll_index'] > 0 },
    nextPollEnabled: (state, getters) => { return (getters['cPoll_index'] < getters['cPoll_ids'].length) && getters["userHasVoted"] },
//    userIsPlayerInCurrentGame: (state, getters, rootState, rootGetters) => { return getters.cGame_players ? !!getters.cGame_players[`${rootGetters['user/id']}`]  : false },
    userHasVoted: (state, getters, rootState, rootGetters) => { return JSON.stringify(getters.cPoll)?.indexOf(`"${rootGetters["user/id"]}":`) != -1 || false },
    userHasReachedEndOfPolls: (state, getters) => {
        return (getters.cPoll_ids.length > 0) && (getters.cPoll_index >= getters.cPoll_ids.length - 1)
    },
    userHasFinishedAllPolls: (state, getters) => {
        return getters.userHasVoted && (getters.cPoll_index == getters.cPoll_ids.length - 1) && (getters.cPoll_ids.length == getters.cGame_track_ids.length - 1) 
    },
    //sort the votes in ascending order from oldest to newest
    // https://forum.freecodecamp.org/t/arr-sort-a-b-a-b-explanation/167677
    vote_queue: (state) => {
        return state._game_progress?.vote_queue ? Object.values(state._game_progress.vote_queue || {}).sort((a, b) => { new Date(a.voted_at).getTime() - new Date(b.voted_at).getTime() }) : []
    },
    game_results: (state) => { return state._game_results },
    /*
    @TODO:           This is a hack to get the tracks to show up without excessive DB requests. 

    Description:    This function creates an array of tracks from the game object 
                    and adds the voting and metadata

    Steps:          1. Create empty array
                    2. Iterate on each track in game, key is track ID
                    3. for every track in game, add votes and track data
                    4. return array
    */
    tracks: (state, getters) => {
        if (!getters.cGame) { return []; }
        const arr = [];
        const gameTracks = getters.cGame.tracks;
        const gameResults = getters.game_results || {};
        const keys = Object.keys(gameTracks);
        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];
          const track = gameTracks[key].item || gameTracks[key];
          const songID = track.id;
          const trackItem = track;
          const voters = gameResults[songID];
          arr.push({ ...voters, ...{ item: trackItem } });
        }
        return arr;
      },


}
const actions = {
    async quickStart(context, {playlist, tags, track_count}) {
        console.log("quickStart", tags)
        // 1. Create a game using the default settings
        const SONGS_COUNT = track_count || 8
        const game =  await context.dispatch("game/createGameUsingDefaultSettings", {playlist, tags, songs_count: SONGS_COUNT}, {root: true})
        if (!game.id) {
            console.log("no game found")
            return
        }
        // 2. Load the game
        await context.commit("SET_GAME_ID", {id: game.id})
        await context.dispatch("bindCurrentGame")
        // Load the user's polls 
        await context.dispatch('setGameProgress', {index: 0, polls: game.polls})
        await context.dispatch('bindCurrentPoll')
        // Load the game's current votes
        await context.dispatch("bindGameResults")
        // 3. Add the game to the user's playlist index
        await context.dispatch('firebase/addGameToPlaylistIndex', { game: game}, {root: true})   
        // 4. Add the user to the game's player index
        context.dispatch('joinGame', {game: game}, {root: false})

    },
    async quickStartInvestorDemo(context, {playlist, track_count, category}) {
        console.log("context.js quickStartInvestorDemo updateplaylist", playlist, track_count, category)
        // 1. Create a game using the default settings
        const SONGS_COUNT = track_count || 8
        const game =  await context.dispatch("game/createGameInvestorDemo", {playlist, songs_count: SONGS_COUNT}, {root: true})
        if (!game.id) {
            console.log("no game found")
            return
        }
        playlist.game_id = game.id
        console.log("game created", game, game.id)
        // 2. Load the game
        await context.commit("SET_GAME_ID", {id: game.id})
        await context.dispatch("bindCurrentGame")
        // Load the user's polls 
        await context.dispatch('setGameProgress', {index: 0, polls: game.polls})
        await context.dispatch('bindCurrentPoll')
        // Load the game's current votes
        await context.dispatch("bindGameResults")
        // 3. Add the game to the user's playlist index
        await context.dispatch('firebase/addGameToPlaylistIndex', { game: game}, {root: true})   
        // 4. Add the user to the game's player index
        await context.dispatch('firebase/newGame', {game: game}, {root: true})
        context.dispatch('joinGame', {game: game}, {root: false})
        context.dispatch('firebase/updatePlaylistWithGameID', {playlist_id: playlist.id, game_id: game.id, category_key: playlist.category}, {root: true})
    },

    async joinGame(context, {game}) {
        console.log("context.joinGame() user finish all polls?", game) // context.getters['userHasFinishedAllPolls'])
        const game_id = game.id || game.game_id
        //await db.ref("games").child(game.id).child("players").update({ [context.rootGetters['user/id']]: true })
        // await db.ref("games").child(game_id).once("value").then((snapshot) => {
        //     console.log("GAME VAL BEFORE", snapshot.val())
        // })
        await context.dispatch("payment/createTransactionPlayerToGame", 
                  { player: context.rootGetters['user/profile'], 
                    game: game }, { root: true}).then((transaction) => {
                        console.log("transaction", transaction)

                        if (transaction.status != 1) {
                            console.log("transaction failed")
                                console.log(transaction.error)
                                context.dispatch("notification/add", {
                                    type: "warning",
                                    message: transaction.error,
                                    duration: 5000
                                    }, {root: true});
                                
                        } else {
                            console.log("transaction success, going to game")
                            context.dispatch("goToGame", {game_id: game_id})
                        }
                        })

    },

    /*
    *   Use the game_id to load the game and the user's progress
    *   @param {Object} game_id
    *   
    */
    goToGame(context, {game_id}) {
        console.log("context.goToGame()", game_id)
        router.push({ name: "Poll", params: { id: game_id } })
        context.commit("SET_GAME_ID", {id: game_id})
        context.dispatch("loadGame")
    },
    async shufflePlaylist(context) {
        console.log("context.shufflePlaylist()", context.rootState.gameCreation.query)
        var game = await context.dispatch('gameCreation/createGameFromSearchForPlaylists', {query: context.rootState.gameCreation.query}, {root: true})
        console.log("Modal.selectCategory", game)
        context.dispatch('joinGame', {game: game})
    },

    loadGame: firebaseAction(async (context) => {
        console.log("context.loadGame()")
        setTimeout( async () => {
            await context.dispatch("bindGameResults")
            await context.dispatch("bindCurrentGame")
            console.log("game bounded, binding game progress", context.getters.cGame, context.getters.cGame_players)
            await context.dispatch("bindGameProgress")
            await context.dispatch('bindCurrentPoll')
        }, 1000)
//        await context.dispatch("bindGameProgress")
        console.log("binding current poll and gme results")

    }),
    bindCurrentGame: firebaseAction((context) => {
        var ref = context.rootGetters['firebase/games_ref'].child(context.getters.cGame_id)
        console.log("context.bindCurrentGame()", ref)
        return context.bindFirebaseRef('_current_game', ref).then(() => {
            console.log("game bounded", context.getters.cGame)
        }).catch((error) => {
            console.log("error binding game", error)
        })

    }),
    /**
        * Summary: Binds the 3 state variables that are required to identify the current poll: the id of the poll, and the id of the two tracks in the poll 
        * in option 1 and option 2 variables
        * 
        * After this function is called, the 3 state varibles in context.js will be updated when the firebase ref updates they are binded to updates.
        *
        * Description:
        *   Instead of having three separate binding functions of similar code, we execute the three binds in a loop because they are always executed together. 
        * 
     */
    bindCurrentPoll: firebaseAction(async (context) =>  {
        console.log("bindCurrentPoll", context.getters.cPoll_tracks[0], context.getters.cPoll_tracks[1])
        if (context.getters.cPoll_tracks?.length == 2) {
            const variablesToBind = [
                ["_current_poll",    context.rootGetters["firebase/current_poll_ref"]],
                ["_option_one",      db.ref(`tracks/${context.getters.cPoll_tracks[0]}`)],
                ["_option_two",      db.ref(`tracks/${context.getters.cPoll_tracks[1]}`)],
            ]
            for (const ind in variablesToBind)  {
                const key = variablesToBind[ind][0]
                const ref = variablesToBind[ind][1]
                await context.bindFirebaseRef(key, ref).catch((err) => { console.log("ERR:", err)}) 
            }
            // if (!context.getters.cPoll) {
            // context.dispatch("notification/add", {
            //     type: "error",
            //     message: "If you're seeing this error, please refresh your page!"
            // }, {root: true});   
            
        } 
        else { console.log("Error binding poll - ", context.getters.cPoll_tracks) }
    }),
    bindGameProgress: firebaseAction((context) => {
        var ref = context.rootGetters['firebase/game_progress_ref']
        const game_id = context.getters.cGame_id
        const user_id = context.rootGetters['user/id']  
        const game_players = context.getters.cGame.players
        const game_polls = context.getters.cGame.polls
        const STARTING_INDEX = 0
        console.log("context.bindGameProgress()", user_id,  " game id - ",  game_id, game_players, ref)
        if (!(user_id && game_id)) {
            console.log("Could not bind game progress. Game or user not set", user_id,  " game id - ",  game_id)
            return
        }
        if (!ref) {
            if (game_players && user_id in game_players) {
                console.log("Could not bind game progress. Player has not started game, but user has paid. Creating game progress ref")
                ref = context.rootGetters['firebase/user_progress_ref'].child("active_games").child(`${game_id}`)
            } else {
                console.log("Could not bind game progress. Player has not started game and has not paid. Redirecting to payment page")
                return 
            }
        } 
        return context.bindFirebaseRef('_game_progress', ref).then(async () => {
            //If user has no game progress, start them from the initial set of polls
            if (Object.keys(context.state._game_progress).length == 0) {
                console.log("User has no game progress, starting from initial polls")
                // router.push({ name: "ConfirmationPage", params: { id: context.getters.cGame_id } })
                await context.dispatch("updatePollIndex", { new_index: STARTING_INDEX, REF: ref })
                console.log("updating current game polls", game_polls)
                await context.dispatch('updateCurrentGamePollIDs', { polls: game_polls })
                console.log("SUCCESS BINDING GAME PROGRESS")
            }
        })
        
    }),
    bindGameResults: firebaseAction((context) => {
        console.log("bindGameResults()")
        return context.bindFirebaseRef('_game_results', context.rootGetters['firebase/game_results_ref'])
    }),

    // bindGameProgress binds "_game_progress" to firebase
    //It is possible that updatePollIndex and updateCurrentGamePollIDs are redundant after binding Game progress as these two variables are child of state._game_progress
    setGameProgress: firebaseAction(async (context, {index, polls}) => {
        console.log("Setting game Progress", index, polls)
        const i = index || 0
        const p = polls || context.getters.cGame.polls 
        await context.dispatch('bindGameProgress')
        context.dispatch("updatePollIndex", {new_index: i} )
        context.dispatch('updateCurrentGamePollIDs', { polls: p })

    }), 

    updateCurrentGamePollIDs(context, { polls, user_id, game_id }) {
        console.log("context.updateCurrentGamePollIds()", polls)
        var user = user_id || context.rootGetters["user/id"]
        var game = game_id || context.rootGetters['context/cGame_id']
        var ref = db.ref(`user_progress/${user}/active_games/${game}/polls`)
        ref.update(polls)

    },
    async updatePollIndex(context, { new_index, REF }) {
        const ref = REF || context.rootGetters['firebase/game_progress_ref']
        console.log("context.updatePollIndex()", new_index, ref)
        if (!ref) {
            console.log("Could not update poll index. Game progress ref not set")
            return
        }
        ref.update({ poll_index: new_index}).then(() => {
            console.log("SUCCESS UPDATING POLL INDEX")
            ref.once('value', (snapshot) => {
                console.log("POLL INDEX UPDATED TO ", snapshot.val())
            })
        })
    },
    async NEXT_POLL(context) {
        console.log("context.next_POLL()")
        //IF the poll_index == poll array length, then don't bind to switch the poll. 
        //The purpose of letting the poll_index to still increment is to show the game_over_modal shows
        const cIndex = context.getters['cPoll_index']
        if (context.getters['cPoll_ids'] && !context.getters['userHasReachedEndOfPolls']) {
            console.log("context.next_POLL()", context.getters['cPoll_index'], context.getters['cPoll_ids'].length)
            var new_index = cIndex + 1

            await context.dispatch('updatePollIndex', { new_index }).then(async () => {
                console.log("context.next_POLL() - dispatching bindCurrentPoll", cIndex,  new_index)
                await context.dispatch("bindCurrentPoll")
                return
            })
        } else {
            console.log("context.next_POLL() - user has reached end of polls or no polls", context.getters['cPoll_ids'], context.getters['userHasReachedEndOfPolls'])
        }
    },
    async PREV_POLL(context) {
        var new_index = context.getters['cPoll_index'] - 1
        console.log("context.prev_POLL()", new_index)
        if (new_index >= 0) {
            await context.dispatch('updatePollIndex', { new_index }).then(async () => {
                context.dispatch("bindCurrentPoll")
                return
            })
        }
    },
    ENABLE_FEED_MODE(context) {
        console.log("game.ENABLE_FEED_MODE()")
        context.commit('SET_FEED_MODE', true)
    },
    DISABLE_FEED_MODE(context) {
        console.log("game.DISABLE_FEED_MODE()")
        context.commit('SET_FEED_MODE', false)
    }
}
    

const mutations = {
    SET_GAME_ID(state, {id}) {
        console.log("SET_GAME_ID", id)
        if (!id) {  
            console.log("No id paramater. SETTING GAME ID FROM ROUTER")
            if (router.currentRoute.name && router.currentRoute.params.id && ["GameComplete", "Poll", "ConfirmationPage"].includes(router.currentRoute.name)) {
                console.log("SETTING GAME ID FROM ROUTER", router.currentRoute.name, router.currentRoute.params.id)
                state._game_id = router.currentRoute.params.id || "Pizza" 
            } 
            else {
                console.log("No Router params. SETTING GAME ID FROM LOCAL STORAGE", window.localStorage.getItem('_game_id'))
                state._game_id = window.localStorage.getItem('_game_id') || router.currentRoute || null
            }
        } else {
            console.log("SETTING GAME ID", id)
            state._game_id = id
            localStorage.setItem('_game_id', id)
        }
    }, 
    enablePollControls(state) {
        state.poll_controls_enabled = true
    },
    disablePollControls(state) {
        state.poll_controls_enabled = false
    },
  
    SET_FEED_MODE(state, bool) {
            state.feed_mode_enabled = bool
    },

  //  ...vuexfireMutations
}

function sortKeysByValAscending(dict) {
    return dict ? Object.entries(dict).sort(([, a], [, b]) => a - b).map(item => item[0]) : []
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}