import api from "@/api";
import router from "@/router";
const state = {
    accessToken: window.localStorage.getItem('accessToken') ? window.localStorage.getItem('accessToken') : null,
    refreshToken: window.localStorage.getItem('refreshToken') ? window.localStorage.getItem('refreshToken') : null,
    expiryTime: window.localStorage.getItem('expiryTime') ? window.localStorage.getItem('expiryTime') : null,
    redirect: window.localStorage.getItem('redirect') ? window.localStorage.getItem('redirect') : null,
};

const getters = {
    getAccessToken: (state) => state.accessToken,
    getRefreshToken: (state) => state.refreshToken,
    getExpiryTime: (state) => state.expiryTime,
    getRedirect: (state) => state.redirect,
    user_logged_in: (state, getters, rootState, rootGetters) => !!getters.getAccessToken && !!rootGetters['user/id'],
    user_authorized: (state, getters, rootState, rootGetters) => !!getters.getAccessToken && !!getters.getRefreshToken && !!getters.getExpiryTime 
};

const mutations = {
    SET_ACCESS_TOKEN(state, token) {
        state.accessToken = token;
        localStorage.setItem('accessToken', token)
    },

    SET_REFRESH_TOKEN(state, token) {
        console.log("SET_REFRESH_TOKEN", token)
        state.refreshToken = token;
        localStorage.setItem('refreshToken', token)

    },

    SET_EXPIRY_TIME(state, time) {
        console.log("SET_EXPIRY_TIME", time)
        state.expiryTime = time;
        localStorage.setItem('expiryTime', time)
    },
    SET_REDIRECT(state, redirect) {
        console.log("SETTING REDIRECT", redirect)
        if (redirect) {
            state.redirect = redirect;
            localStorage.setItem('redirect', redirect)
        } else {
            state.redirect = null
            localStorage.removeItem('redirect')
        }
    }
};

const actions = {
    async login(context, {game_redirect_id}) {
        console.log("auth.LOGIN() ", game_redirect_id );

        try {
            const response = await api.auth.getUserAuthURL();

            console.log("Response:");
            console.log("LOGIN RESPONSE BITCH: ", response.data);
            if (response.data) {
                window.location.href = response.data; //redirect the page to the User Auth URL
            }
            
            context.commit('SET_REDIRECT', game_redirect_id)
        } catch (e) {
            console.log("ERROR");

            console.log(e);
            context.dispatch("notification/add", {
                type: "warning",
                message: "Server appears to be sleeping 😴. Please refresh and try again!"
            }, { root: true });
        }
    },

    refreshToken: async function ({ commit, state, dispatch }) {
        console.log("refreshToken() called")
        try {
            if (state.refreshToken) {
                const response = await api.auth.refreshToken(state.refreshToken);
                commit("SET_ACCESS_TOKEN", response.data.access_token);

                const accessToken = response.data.access_token;

                dispatch("setAccessToken", accessToken);
                console.log("accessTokenSet")
                return response;
            }
        } catch (e) {
            console.log(e);
        }
    },

    logout: function () {
        let script = document.createElement("script");

        script.src = "https://www.spotify.com/logout/";
        document.getElementById("app").appendChild(script);

        window.localStorage.clear();
        window.sessionStorage.clear();

        setTimeout(() => {
            location.reload();
        }, 1000);
    },

    setAccessToken({ commit }, token) {
        console.log("auth/setAccessToken() called - ", token)
        commit("SET_ACCESS_TOKEN", token);
    },

    setRefreshToken({ commit }, token) {
        commit("SET_REFRESH_TOKEN", token);
    },

    setExpiryTime({ commit }, time) {
        commit("SET_EXPIRY_TIME", time);
    }
};

const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default module;
