<template>
    <svg :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3357 10.4849L13.1263 11.4813C12.8832 11.6816 12.8484 12.0417 13.0487 12.2849C13.2491 12.528 13.6092 12.5628 13.8523 12.3625L16.2825 10.3599C16.4137 10.2514 16.4902 10.0899 16.4902 9.91921C16.4902 9.74863 16.4137 9.58712 16.2825 9.47865L13.8523 7.47604C13.6092 7.27572 13.2491 7.31045 13.0487 7.55363C12.8484 7.79672 12.8833 8.15686 13.1263 8.35717L14.3231 9.34339H11.8884C11.7188 9.34339 11.5584 9.26806 11.45 9.13797L6.427 3.11105C6.10169 2.72071 5.62005 2.4947 5.11151 2.4947H1.08051C0.765497 2.4947 0.509766 2.75034 0.509766 3.06535C0.509766 3.38046 0.765497 3.63609 1.08051 3.63609H5.11151C5.28105 3.63609 5.44134 3.71143 5.54982 3.84151L10.5726 9.86892C10.898 10.2594 11.3797 10.4848 11.8882 10.4848L14.3357 10.4849ZM1.08078 10.4849H5.13135C5.62271 10.4849 6.09065 10.2737 6.41595 9.90496C6.41661 9.90439 6.41718 9.90326 6.41775 9.90269C6.71625 9.5603 7.19562 9.00437 7.79713 8.28183C7.99915 8.03988 7.96611 7.67974 7.72407 7.47829C7.48211 7.27684 7.12198 7.30931 6.92053 7.55136C6.32694 8.26362 5.85378 8.81264 5.55878 9.15107C5.45031 9.27323 5.29512 9.34336 5.13132 9.34336H1.08075C0.765734 9.34336 0.510002 9.5991 0.510002 9.91411C0.510002 10.2291 0.765734 10.4849 1.08075 10.4849L1.08078 10.4849ZM14.3231 2.49488H11.8884C11.3798 2.49488 10.8982 2.72087 10.5728 3.11122L9.2031 4.75494C9.00108 4.9969 9.03412 5.35703 9.27616 5.55848C9.51812 5.7605 9.87825 5.72746 10.0797 5.48542L11.45 3.8417C11.5584 3.71162 11.7188 3.63628 11.8883 3.63628H14.3357L13.1263 4.63277C12.8832 4.83309 12.8484 5.19322 13.0487 5.43631C13.249 5.67948 13.6092 5.71423 13.8523 5.51391L16.2825 3.51129C16.4137 3.40282 16.4902 3.2413 16.4902 3.07073C16.4902 2.90006 16.4137 2.73854 16.2825 2.63008L13.8523 0.627458C13.6092 0.427138 13.249 0.461972 13.0487 0.705055C12.8484 0.94822 12.8832 1.30838 13.1263 1.50869L14.3231 2.49488Z" :fill="color"/>
    </svg>

</template>

<script>
export default {
    name: 'ShuffleIcon',
    props: {
        color: {
            type: String,
            default: '#fff'
        },
        height: {
            type: String,
            default: '13'
        },
        width: {
            type: String,
            default: '17'
        }
    }
}
</script>