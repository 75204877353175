<template>
    <svg width="44" height="22" viewBox="0 0 44 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path :class="{'left-arrow': direction == 'left'}" d="M30.8334 7.00384H1.17857C0.527704 7.00384 0 7.54061 0 8.20267V13.7972C0 14.4593 0.527704 14.9961 1.17857 14.9961H30.8334V19.5975C30.8334 21.7336 33.3724 22.8034 34.8574 21.2929L43.3096 12.6954C44.2301 11.759 44.2301 10.2409 43.3096 9.3046L34.8574 0.707056C33.3725 -0.803376 30.8334 0.266385 30.8334 2.40251V7.00384Z" :fill="color"/>
    </svg>

</template>
<script>
export default {
    name: "RightArrow",
    props: ["color", "direction"],
}
</script>

<style lang="scss" scoped>
.left-arrow {
    transform: rotate(180deg);
      transform-origin: 50% 50%;

}
</style>