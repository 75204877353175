<template>
    <div>
        <div v-if="show_pause" @click.prevent.stop="pause" class="playContainer"   >
            <img v-if="isPng"  src="@/assets/pause_icon.png" />
            <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
            <path d="M6.001 0H2.0635V15.75H6.001V0Z" fill="#8EFF35"/>
            <path d="M13.6865 0H9.74899V15.75H13.6865V0Z" fill="#8EFF35"/>
            </g>
            
            </svg>

        </div>
        <div class="playContainer" v-if="show_play" @click.prevent.stop="play">
            
            <img v-if="isPng"  src="@/assets/play_icon.png" />
            <svg v-else width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0V15.75L12.375 7.875L0 0Z" fill="#8EFF35"/>
            </svg>
        </div>
    </div>
        
</template>

<script>
//import { analytics } from '../store/modules/firebase'
import { mapGetters } from "vuex";


export default {
    name: "PlayButton",
    props: {
        track: {
            required: false
        },
        preview: { //BOOLEAN
            required: false
        },
        isPng: {
            required: false,
            default: true
        }
    },
    data() { 
        return {
            playerInitiated: false
        }
    },
    computed: {
        ...mapGetters("audioPlayer", {
            playback: "getPlayback",
            isPlaying: "isPlaying",
        }),
        isPlayingPreview() { return  this.$store.state.audioPlayer.isPlayingPreview },
        TRACK() { return this.track == null ? this.playback : this.track },
        USE_PREVIEW() { return this.preview == null ? this.isPlayingPreview : this.preview //this.preview IS A BOOLEAN
        },
        playback_null() {
            return Object.entries(this.playback).length === 0
        },
        trackPropNull() { return this.track == null },
        playBackEqualsTrackProp() { return !this.trackPropNull && this.playback?.id == this.track.id },
        show_pause:   function() { 
            if (this.trackPropNull || this.playBackEqualsTrackProp) {
                return this.isPlaying
            } else {
                return false
            }
        },
        show_play() {            
            if (this.trackPropNull || this.playBackEqualsTrackProp) {
                return !this.isPlaying
            } else {
                return true
            }
     
        }
    },
    methods: {
        async play() {           
            console.log("Playbutton.playing()") 
            if (!this.playerInitiated) {
                 await this.$store.dispatch("player/init", null, { root: true });
            }
            this.$store.dispatch('audioPlayer/play', {track: this.TRACK, use_preview: this.USE_PREVIEW }) 
        },
        pause() {
            this.$store.dispatch('audioPlayer/pause')
        }
    }
}
</script>

<style lang="scss" scoped>
    img {
        width: auto;
        height: 100%;
    }
    img:hover {
        cursor: pointer;
    }
    .playContainer {
            height: 100%;
    display: flex;
    justify-content: center;
    width: auto;
    }
</style>
