<template>
    <li class="tab_item"  @click="setCategory(`${category_id}`)">
        <a><span>{{category_name}}</span></a>
    </li>

</template>

<script>
import { db} from '@/store/modules/firebase';
export default {
    name: "MobileCategoryItem",
    data() {
        return {
            category: {}
        }
    },

//    props: ["category_id"],
    mounted() {
        console.log("MOUNTED - CategoryItem2: ", this.category_id)
        if (this.category_id != "community") {
            this.$bind('category', db.ref('categories').child(this.category_id))
        } else {
            this.category = {name: "Community", id: "community"}
        }
    },
    computed: {
        category_name() { 
            console.log("CATEGORY NAME - ", Object.keys(this.category), this.category.name)
            return this.category_id == "community" ? "Community" : this.category.name 
        },
        category_id() { return this.category ? this.category.id : "community" },
        isEditButton() { return this.category_id == "edit"}
    },
    methods: {
        setCategory() {  
            this.$emit("setCategory", this.category)
        },
        goToEdit() {
            this.$emit("goToEdit")
        }
    }
}
</script>

<style lang="scss" scoped>
a, a:visited, a:active {
    text-decoration: none;
    color: white;
}
a:hover, .is-active {
    font-weight: 600;
    color: white;
}
.tab_item {
    min-width: fit-content;
    &__edit {
        background: #1f1f1f;
    }
}
@media only screen and (min-width: 480px) {
    .tab_item {
        background: #3c3c3c;
        padding: 0.3rem 1rem;
        border-radius: 8px;
        color: white;
    }
}
</style>