<template>
    <Mobile-Browser-Header @tabSet="setTab" :active_tab="active_tab" :tags="categories" v-if="$isMobile()" />
    <Desktop-Browser-Header @tabSet="setTab" :active_tab="active_tab" :tags="categories" v-else />
</template>

<script>
import MobileBrowserHeader from '@/components/GameBrowser/Header/Mobile.vue'
import DesktopBrowserHeader from '@/components/GameBrowser/Header/Desktop.vue'
import { db } from '@/store/modules/firebase';
import { mapGetters } from 'vuex';
export default {
    name: "BrowserHeader",
    props: ["active_tab", "categories"],
    components: { MobileBrowserHeader, DesktopBrowserHeader },
    data() {
        return {
            tags_obj2: {},
        }
    },

    async mounted() {
        // console.log("MOUNTED - User ID: ", this.userID)
        // this.$bind("tags_obj2", db.ref('users').child(this.userID).child('categories'))
        // console.log("TAGS: ", this.tags_obj)
    },
    computed: {
        // tags() { return ["community", ...Object.values(this.tags_obj) ] }, 
        // tags_obj() { return this.userProfile?.categories || {} },
        ...mapGetters({
            userID: "user/id",
            userProfile: "user/profile"
        })
    },
    methods: {
        setTab(val) {
            console.log("Mobile.SetTab()", val)
            this.$emit("tabSet", val)
        }
    },
    
}
</script>


<style>

</style>