<template>
    <div class="navbar-item has-dropdown is-hoverable navbar-search">

        <div class="search-bar ">

            <input class="search-bar-inp" type="text" placeholder="Search for an artist" @keyup="search()" v-model="query" />
            <SearchIcon class="search-icon" />
        </div>
        <div class="navbar-dropdown search-results is-left">
            <a  v-for="artist in artists_limited" 
                :key="artist.id" 
                @click.prevent="selectArtist(artist)"
                class="navbar-item navbar-item2" >
            {{artist.name}}
            </a>x
        </div>
    </div>
</template>
<script>
import SearchIcon from '@/components/SVGs/Search.vue'
import api from '@/api'
export default {
    name: "NavBarSearch",
    data() {
        return {
            query: "",
            artists: []
        }
    },
    
    components: {
        SearchIcon
    },
    computed: {
        artists_limited() {
            return this.artists.slice(0,3)
        }
    },
    methods: {
        async search(){
            //console.log("searching for: ", this.query)
            var response = await api.spotify.search.search(this.query,"artist")
            console.log("response: ", response)
            this.artists = response.data.artists.items
            //this.$store.dispatch('spotify/search', {query: this.query})
        }, 
        selectArtist(artist) {
            console.log("selecting artist: ", artist.name, artist.id)
            this.$router.push({name: 'ProfilePage', params: {id: artist.id}, replace: this.$route.name == "ProfilePage" })
            this.query = artist.name
        }
    },
}
</script>
<style lang="scss" scoped>
.search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5vh;
//    border: 1px solid rgba($color: #fff, $alpha: 0.5);
    background-color: rgba($color: #fff, $alpha: 0.02);
    border-radius: 10px;
    padding: 0 1vw;

    input {
        width: 80%;
        height: 100%;
        color: #fff;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 1.5vh;
    }
    img {
        width: 2vw;
        height: 2vw;
    }
    ::placeholder {
        color: #fff;
        opacity: 50%;
    }
}
.search-icon {
    opacity: 50%;
}
.artist_name {
  width: 100%;
  margin: 0;
}
.search-results {
    background: rgba($color: #fff, $alpha: 0.02);
    color: white;
}
.navbar-item2 {
    color: white;
}
</style>