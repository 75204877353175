<template>
     <mobile v-if="$isMobile()" 
                @toggleBtn="toggleBtn"
                :tracks="ordered_tracks()" 
                :game_expired="game_expired" 
                :votes_count="total_votes_count"
                :active="active"
                @artistSelected="artistSelected" />
     <desktop-vote-results v-else 
                @toggleBtn="toggleBtn"
                :tracks="ordered_tracks()" 
                :votes_count="total_votes_count"
                :game_expired="game_expired" 
                :active="active"/>
</template>

<script>

import Mobile from "./Mobile.vue"
import DesktopVoteResults from "./Desktop.vue"
import Vue from 'vue';

import { mapGetters } from 'vuex';
export default {
    name: "VoteResults",
    props: ["tracks", "game_expired", "ordered_tracks_all_users", "ordered_tracks_current_users"],
    data() {
        return {
            active: "community",
            orderedly_tracks: []
        }
    },
    async mounted() {
        var default_mode = this.game_expired ? "community" : "personal"
        Vue.set(this, "active", default_mode)
        
    },
    components: { DesktopVoteResults, Mobile },
    computed: {
        show_community() {
            return this.active === "community"
        },
        // ordered_tracks_for_all_users() { 
        //     var arr = this.tracks
        //     console.log("ordered-tracks-for-all-user", arr.sort((a,b) => this.countVotesForTrack(b) - this.countVotesForTrack(a)).map(a => this.countVotesForTrack(a)))
        //     this.ordered_tracks = arr.sort((a,b) => this.countVotesForTrack(b) - this.countVotesForTrack(a)).map(a => this.countVotesForTrack(a))

        //     return arr.sort((a,b) => this.countVotesForTrack(b) - this.countVotesForTrack(a)) 
        // },
        // ordered_tracks_for_current_user() {
        //     var arr = this.tracks || []
        //     console.log("ordered-tracks-for-current-user", arr.sort((a,b) => this.countVotesForTrack(b, this.user_id) - this.countVotesForTrack(a, this.user_id)).map(a => this.countVotesForTrack(a, this.user_id)))
        //     this.ordered_tracks = arr.sort((a,b) => this.countVotesForTrack(b, this.user_id) - this.countVotesForTrack(a, this.user_id)).map(a => this.countVotesForTrack(a, this.user_id))
        //     return arr.sort((a,b) => this.countVotesForTrack(b, this.user_id) - this.countVotesForTrack(a, this.user_id)) 
        // },
        // count votes of each song 
        total_votes_count() {
            console.log("VoteResults. TOTAL VOTES COUNT: ", this.tracks)
            var dict = {}
            this.tracks.forEach(track => {
                if (track) {
                    if (!track.voters) { dict[track.item.id] = 0  }
                    else {
                        let totalVotes = (this.active == "community") ? this.countVotesForTrack(track) : this.countVotesForTrack(track, this.user_id)
                        dict[track.item.id] = totalVotes  
                    } 
                }
            })
            return dict
        },


        ...mapGetters({
            user_id: "user/id",
        })
    },
    methods: {
        toggleBtn(str) {
            Vue.set(this, "active", str)
            console.log("VoteResults.toggleBtn()", str, this.active)
        },
        artistSelected(artist) {
  //          this.$router.push({ name: "ProfilePage", params: { artist_id: artist.id } })
        },
        ordered_tracks() {
            console.log("ORDERED TRACKS:", this.active)
            return this.active == "personal" //(this.preview_mode || !this.game_expired) 
                    ? this.ordered_tracks_for_current_user()
                    : this.ordered_tracks_for_all_users()
        },
        ordered_tracks_for_all_users() { 
            var arr = this.tracks
            console.log("ordered-tracks-for-all-user", arr.sort((a,b) => this.countVotesForTrack(b) - this.countVotesForTrack(a)).map(a => this.countVotesForTrack(a)))
            this.orderedly_tracks = arr.sort((a,b) => this.countVotesForTrack(b) - this.countVotesForTrack(a)).map(a => this.countVotesForTrack(a))

            return arr.sort((a,b) => this.countVotesForTrack(b) - this.countVotesForTrack(a)) 
        },
        ordered_tracks_for_current_user() {
            var arr = this.tracks || []
            console.log("ordered-tracks-for-current-user", arr.sort((a,b) => this.countVotesForTrack(b, this.user_id) - this.countVotesForTrack(a, this.user_id)).map(a => this.countVotesForTrack(a, this.user_id)))
            this.orderedly_tracks = arr.sort((a,b) => this.countVotesForTrack(b, this.user_id) - this.countVotesForTrack(a, this.user_id)).map(a => this.countVotesForTrack(a, this.user_id))
            return arr.sort((a,b) => this.countVotesForTrack(b, this.user_id) - this.countVotesForTrack(a, this.user_id)) 
        },
        // 
        userVotedForTrack(track, user_id) {
            return (track.voters  && user_id  && track.voters[this.user_id])
        },
        countVotesForTrack(track, user_id) {
            if (user_id) {
                return this.userVotedForTrack(track, user_id) ? Object.keys(track.voters[user_id]).length : 0
            } else {
                // add up votes for a track
                // key represents a userID. track[key] is a dictionary. it should have 1 key per vote by the userID
                // we want to aggregate the total votes by adding 1 for every subkey
                var sum = 0
                if (track.voters) {
                    for (let userID in track.voters) {
                        let votes = track.voters[userID]
                        var votes_count = Object.keys(votes).length
                        sum += votes_count
                    }
                }
                return sum
            }
        }
    },
    watch: {
        active(newVal) {
            this.ordered_tracks()
        }
    }
    
}
</script>

<style lang="scss" scoped>


</style>
