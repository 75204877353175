<template>
    <section >
        <h1>Stellar Work!</h1>
        <div class="dots_container">
            <img class="dots" src="@/assets/GameCompleteBG.png" alt="">
        </div>
        <div class="info_boxes" v-if="!show_recommended">
                <JackpotInfo class="info_boxes__box" :game="game"/>
                <VoteResults class="info_boxes__center" :tracks="tracks" 
                                                        :ordered_tracks_all_users="ordered_tracks_all_users" 
                                                        :ordered_tracks_current_users="ordered_tracks_current_users" 
                                                        :game_expired="gameIsOver(game)" 
                                                        @ctaClicked="seeRecommended()" />



                <!-- <Leaderboard class="info_boxes__side"   :game="game" 
                                                        :tracks="tracks.slice(0, 3)"
                                                        :ordered_tracks_all_users="ordered_tracks_all_users.slice(0,3)" 
                                                        :ordered_tracks_current_users="ordered_tracks_current_users.slice(0,3)"  />  -->
                <!-- <VoteResults class="info_boxes__side" :tracks="tracks" 
                :ordered_tracks_all_users="ordered_tracks_all_users" 
                :ordered_tracks_current_users="ordered_tracks_current_users" 
                :game_expired="gameIsOver(game)" 
                @ctaClicked="seeRecommended()" /> -->
        </div>
        <!-- <Recommended class="recommended" @goBack="hideRecommended()" v-else /> -->
    </section>
</template>

<script>
import JackpotInfo from '@/components/GameComplete/JackpotInfo'
import { gameLogic } from "@/mixins";

import VoteResults from '@/components/GameComplete/VoteResults'
import Leaderboard from '@/components/GameComplete/Leaderboard'
// import Recommended from '@/components/GameComplete/Recommended'
import { db } from '@/store/modules/firebase'


export default {
    name: "GCDesktop",
    mixins: [gameLogic],

    components: { 
        JackpotInfo, 
        VoteResults, 
      //  Leaderboard
    },
    props: ["game", "tracks", "ordered_tracks_all_users", "ordered_tracks_current_users"],

    data() {
        return {
            show_recommended: false,
            game_results: null
        };
    },
  

}
</script>

<style lang="scss" scoped>
section {
    height: 100%;
    width: 100%;
    color: white;
    overflow: hidden;
}
.dots_container {
    position: absolute;
    z-index: 0;
    bottom: 12vh;
    left: 0;
    height: 54%;
    width: 100%;
    right: 0;

}

.dots {
    height: 100%;
    width: 100vw;
    position: absolute;
    left: 0;
    bottom: 0;
    background: transparent;
}

.info_boxes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
   // padding-left: 6vw;
    //padding-right: 4vw;
    margin: 0 auto;
    height: 64%;
    position: relative;

    z-index: 1;
    &__box {
        width: 34.4%;
    }
    &__whole {
        width: 61.5%;
        height: 82.5%;
        background: rgba(0, 0, 0, 0.3);

    }
    &__side { 
         background: rgba(0, 0, 0, 0.3);
//        width: 26%;
        width: 34.4%;
        min-height: 55%;//45%;
        max-height: 87%;
        height: 55%;
        //height: max-content;
        //margin-top: 27.7vh;
      //  margin-bottom: 30vh;
        border-radius: 22px;
         padding-top: 2rem;
         padding-bottom: 62px;
    }
    &__center {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 22px;
        width: 34.4%;

        //margin-top: 2rem;
      //  margin-top: -3rem;
        //padding-top: 2%;//2rem;
        padding-bottom: 6%;
        overflow: hidden;
        height: 100%;

    }
}

.recommended {
    height: 81.4%;
    width: 61.5%;
    background: rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    position: relative;
    margin-top: 2.7%;
    z-index: 1;
    border-radius: 22px;
}

</style>