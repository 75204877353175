<template>
  <section class="login-screen" :style="cssProps">

     <div>
      <questionaire v-if="showQuestionaire" @questionaireClosed="nextPage()" />
      <phone-sign-in v-else-if="showPhoneSignIn" @phoneSignInClosed="nextPage()" />
      <spotify-sign-in v-else-if="showSpotifySignIn" @spotifySignInClosed="nextPage()" />
    <genre-selection v-else-if="show_genre_selection" @genreSelectionClosed="close()" /> 
    </div> 

    <!-- <home-mobile  /> -->
  </section>
</template>


<script>
import PhoneSignIn from '@/components/Home/PhoneSignIn/index.vue';
import GenreSelection from '@/components/GenreSelection.vue'
import SpotifySignIn from '@/components/Home/SpotifySignIn/index.vue';
import api from '@/api';
import Questionaire from '@/components/Home/Questionaire/index.vue'
import { mapGetters, mapActions } from "vuex";
import HomeMobile from '@/components/HomeMobile/index.vue'
const QUESTIONAIRE_ID = 0
const SPOTIFY_SIGN_IN_ID = 1
const GENRE_SELECTION_ID = -2 //make positive to reintroduce genre selection
const PHONE_SIGN_IN_ID = -1
export default {
  name: 'Home',
//  components: { HomeMobile},
  components: { Questionaire, PhoneSignIn, SpotifySignIn},
  data () {
    return {
      cssProps: {
        backgroundImage:  `url(${require('@/assets/bgMay2022.png')})`,// `url(${require('@/assets/background.png')})`,
        backgroundSize: 'cover'
      },
      index: 0
      // showQuestionaire: true,
      // show_genre_selection: false,
      // showPhoneSignIn: false,
      // showSpotifySignIn: false,

    }
  },
  computed: {
    ...mapGetters({
      userIsAuthorized: 'user/isAuthorized',
      user_id: 'user/id',
      users_music_categories: 'user/music_categories',
    }),
    showQuestionaire() { return this.index === QUESTIONAIRE_ID },
    showSpotifySignIn() { return this.index === SPOTIFY_SIGN_IN_ID },
    show_genre_selection() { return this.index === GENRE_SELECTION_ID },
    showPhoneSignIn() { return this.index === PHONE_SIGN_IN_ID },
  },
  async mounted() {
    console.log("Home Mounted - PARAMS ID", this.$route.params.id)
    if (this.userIsAuthorized && !this.user_id) {
      await this.getUserProfile()
    } 
    if (this.$route.params.id == GENRE_SELECTION_ID) {
      this.index = GENRE_SELECTION_ID
    } 
  },
  methods: {
    ...mapActions({
      getUserProfile: 'user/fetchUserSpotifyProfile',
    }),
    async nextPage() {
      if (this.index == QUESTIONAIRE_ID && this.$store.getters["auth/getAccessToken"]) {
        await this.getUserProfile()
        // if (!this.users_music_categories || false) {
        //   this.index = GENRE_SELECTION_ID
        // } else {
        //   this.close()
        // }
        this.close()
        return
      } 
      this.index++
    },
    close() {
      //this.$router.push({name: 'Streaming'})
       this.$router.push({name: 'Browser'})
    },
    /**
     * @description
     * This method is called when the questionaire is closed.
     * It sets the showQuestionaire to false. 
     * This will hide the questionaire and show the phone sign in.
     */
    questionaireClosed() {
      this.showQuestionaire = false
      if (!this.$store.getters["auth/getAccessToken"]) {
        this.showSpotifySignIn = true
      } 
      else if (this.users_music_categories?.length === 0) {
      //  this.show_genre_selection = true
        this.$router.push({name: 'Streaming'})
      } else {
    //    this.show_genre_selection = true

        this.$router.push({name: 'Streaming'})
      }
    },
    phoneSignInClosed() {
      this.showPhoneSignIn = false
      this.showSpotifySignIn = true
    },
    spotifySignInClosed() {
        this.showSpotifySignIn = false
        //this.show_genre_selection = true
        this.$router.push({name: 'Streaming'})

    },
    genreSelectionClosed() {
      console.log("Home.genreSelectionClosed()");
      // this.$router.push({name: 'Browser'})
      this.$router.push({name: 'Streaming'})

    },

  }
}
</script>
<style scoped lang="scss">
  .login-screen {
    position: absolute;
    width: 100vw;
    height: 100%;
    background-size: "cover";
    background-position: center center;
    overflow: hidden;
    color: white;
  }


</style>

