<template>
  <div id="app">
      <Notification  />
      <app-layout />  
      <ClaimWalletButton />
  </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import api from '@/api';
import { mapGetters } from "vuex";
import {screenOrientationLogic} from '@/mixins';
import Notification from "@/components/Notification/";
import ClaimWalletButton from '@/components/ClaimWalletButton'
import Vue from "vue";
export default {
  name: 'App',

  data() {
    return {
      landscape_mode: false,
      inputValue: ""
    }
  },
  mixins: [screenOrientationLogic ],
  components: {
    AppLayout,
    Notification,
    ClaimWalletButton
  },
  computed: {
    showPortraitModal() { return this.$route.name == "Poll" && this.$isMobile() && !this.landscape_mode},
    ...mapGetters({
      cGame: 'context/cGame',
      balance: 'user/balance',
    })
  },
  async mounted() {
    console.log("App.mounted()")
    // if (!window.matchMedia('(display-mode: standalone)').matches) {
    //   alert('Please install this website as a PWA for an improved experience!');
    // }
    // let script = document.createElement("script");

    // script.src = "https://verify.sendwyre.com/js/verify-module-init-beta.js";
    // script.async = true;

    // document.head.appendChild(script);
    if (!["Home"].includes(this.$route.name)) { //} && this.$store.getters['user/id'] && !this.$store.getters['user/name']) {
//      this.inputValue = 
      console.log("INPUT VALUE", this.inputValue)

    

    }
    const signIn = true
    if (!["Home", "ConfirmationPage"].includes(this.$route.name) || signIn) {
      console.log("App.mounted() - User is on Poll, Browser, or Results page. Checking if user is logged in.")
      if (!this.$store.getters['auth/user_authorized']) {
        console.log("Access token not found. Retrieving from auth/login")
        await this.$store.dispatch("auth/login", {guest_email: null});
      } else if (!this.$store.getters['user/id']) {
          await this.$store.dispatch('user/fetchUserSpotifyProfile')
      } else {
        const wallet = this.$store.getters['user/wallet'].message
        console.log("User is logged in.", this.$store.getters['user/profile'], wallet)
      }
    }
    else if (!this.$store.getters['user/id'] && this.$store.getters["auth/getAccessToken"]) {
      console.log("App.mounted() - User is logged in, now fetching user profile.")
      await this.$store.dispatch('user/fetchUserSpotifyProfile')
      console.log("User profile fetched.")
    }
    if (this.$store.getters['user/profile']) {
      console.log("App2.mounted() - User is logged in. Fetching user wallet.")
      const wallet = this.$store.getters['user/wallet'].message
      console.log("Now accessing wallet", this.$store.getters['user/profile'], wallet)
      await this.getWalletBalance()
      console.log("User wallet fetched ", this.$store.getters['user/id'], this.$store.getters['user/balance'])
    } else {
      console.log("App2.mounted() - User was unable to be loggged in")
    }



    this.setDocumentTitle()
    this.listenForScreenOrientation()   

    var doc = window.document;
	
	// If there's a hash, or addEventListener is undefined, stop here
	if(!window.navigator.standalone && !location.hash && window.addEventListener ){
		
		//scroll to 1
		window.scrollTo( 0, 1 );
		var scrollTop = 1,
			getScrollTop = function(){
				return window.pageYOffset || doc.compatMode === "CSS1Compat" && doc.documentElement.scrollTop || doc.body.scrollTop || 0;
			},
		
			//reset to 0 on bodyready, if needed
			bodycheck = setInterval(function(){
				if( doc.body ){
					clearInterval( bodycheck );
					scrollTop = getScrollTop();
					window.scrollTo( 0, scrollTop === 1 ? 0 : 1 );
				}	
			}, 15 );
		
		window.addEventListener( "load", function(){
			setTimeout(function(){
				//at load, if user hasn't scrolled more than 20 or so...
				if( getScrollTop() < 20 ){
					//reset to hide addr bar at onload
					window.scrollTo( 0, scrollTop === 1 ? 0 : 1 );
				}
			}, 0);
		}, false )
  }
   // this.initializePlayer()     
  }, 
  methods: {
    saveUsername(name) {
      console.log("SAVING USERNAME", name)
      this.$store.dispatch('user/saveUsername', {name: name})
    },
      setDocumentTitle() {
        console.log("SETTING DOCUMENT TITLE FUNCTION", !!this.$store.getters['context/cGame'], this.$store.getters['context/cGame']?.name)
        // if (this.$store.getters['context/cGame']) {
        //   document.title = `${this.$store.getters['context/cGame'].name}${this.$route.name == "GameComplete" ? ` | Results` : "" }`
        // } else {
        //   console.log( "NO GAME SET")
        // }
        document.title = "SnipitFM"
      },

      enableFeedMode() {
        if (["Home"].includes(this.$route.name)) {
          this.$store.commit("context/enableFeedMode")
          this.$router.push({name: "Poll"})
        } else if (["Poll"].includes(this.$route.name) && !this.$store.getters['context/cGame']) {
          this.$store.commit("context/enableFeedMode")
        }
      },
      initializePlayer() {
         // if (!this.playerInitiated) {
          this.$store.dispatch("player/init", null, { root: true });
           // }
      },
      getWalletBalance() {
        console.log("get wallet balance!!!!")
        this.$store.dispatch('user/getWalletBalance')
      },    
      listenForScreenOrientation() {
        console.log("listening for screen orientation")
        var landscape = this.getScreenOrientation(this.$isMobile())
        this.$store.commit('webHQ/SET_SCREEN_ORIENTATION', landscape == "landscape")
//        Vue.set(this, "landscape_mode",  landscape == "landscape" )

        var orientationQuery = window.matchMedia('(orientation: portrait)')
        try {
          // Chrome & Firefox
          orientationQuery.addEventListener('change', () => { 
            this.$store.commit('webHQ/SET_SCREEN_ORIENTATION', !event.matches)
            Vue.set(this, "landscape_mode",!event.matches) });
        } catch (e1) {
            try {
              // Safari
             orientationQuery.addListener(() => {  
              this.$store.commit('webHQ/SET_SCREEN_ORIENTATION', !event.matches)

              Vue.set(this, "landscape_mode", !event.matches) });
              
            } catch (e2) {
            console.error(e2);
            }
        }
      },      
  },
  watch: {
    cGame(newVal) {
      this.setDocumentTitle()
    },

  }
}
</script>
<style>
@font-face {
  font-family: "Futura-Black";
  font-display: auto;
  src: url('./assets/webFonts/Futura-Black/unicode.futurabb.ttf') format('woff2')
}
@font-face {
  font-family: "Futura";
  font-display: auto;
  src: url('./assets/webFonts/Futura/Futura-Medium.ttf') format('woff2')
}
@font-face {
  font-family: "Futura-Condensed";
  font-display: auto;
  src: url('./assets/webFonts/Futura/Futura-Condensed-Medium.ttf') format('woff2')
}
@font-face {
  font-family: "Rubik";
  font-display: auto;
  src: url('./assets/webFonts/Rubik/Rubik-Regular.ttf') format('woff2')
}
@font-face {
  font-family: "Rubik-Bold";
  font-display: auto;
  src: url('./assets/webFonts/Rubik/Rubik-Bold.ttf') format('woff2')
}
@font-face {
  font-family: "Rubik-Medium";
  font-display: auto;
  src: url('./assets/webFonts/Rubik/Rubik-Medium.ttf') format('woff2')
}
@font-face {
  font-family: "Rubik-Light";
  font-display: auto;
  src: url('./assets/webFonts/Rubik/Rubik-Light.ttf') format('woff2')
}
@font-face {
  font-family: "Rubik-Italic";
  font-display: auto;
  src: url('./assets/webFonts/Rubik/Rubik-Italic.ttf') format('woff2')
}
@font-face {
  font-family: "Rubik-Bold-Italic";
  font-display: auto;
  src: url('./assets/webFonts/Rubik/Rubik-BoldItalic.ttf') format('woff2')
}
@font-face {
  font-family: "Rubik-Medium-Italic";
  font-display: auto;
  src: url('./assets/webFonts/Rubik/Rubik-MediumItalic.ttf') format('woff2')
}
@font-face {
  font-family: "OpenSans";
  font-display: auto;
  src: url('./assets/webFonts/OpenSans/OpenSans-Regular.ttf') format('woff2')
}
@font-face {
  font-family: "OpenSans-Bold";
  font-display: auto;
  src: url('./assets/webFonts/OpenSans/OpenSans-Bold.ttf') format('woff2')

}
#app {
  font-family: "Rubik", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;/*add This*/ 
  overflow-x: clip;
  background: #1f1f1f;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}



html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  overflow: hidden;/*add This*/ 
}
html {
overflow: hidden;
width: 100%;
}
body {
height: 100%;
position: fixed;
overflow-y: scroll;
-webkit-overflow-scrolling: touch;
padding-bottom: env(safe-area-inset-bottom);

}
</style>
