export const screenOrientationLogic = {
    methods: {
        getScreenOrientation(isMobile = true) {
            if (!isMobile) { return "desktop" }
            if (window.innerHeight > window.innerWidth) {
                return "portrait";
            } else {
                return "landscape";
            }
        }
    }
}