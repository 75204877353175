<template>
    <div class="modal" v-show="show">
      <div class="modal__backdrop" />
        <div class="modal__wrapper" @click.prevent.self="closeModal()">
          <PortraitModeModalContent v-if="show_portrait_mode" />
          <PulseLoaderModalContent v-else-if="isLoading" />
          <SignInModalContent v-else-if="!user_logged_in" />
          <GenreModalContent v-else @close="closeModal"  />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import '@/assets/css/modals.scss';
import api from '@/api';

import { mapGetters } from "vuex";
import SignInModalContent from '@/components/Modals/Genres/SignIn.vue'
import GenreModalContent from '@/components/Modals/Genres/Genres.vue'
import PulseLoaderModalContent from '@/components/Modals/Genres/PulseLoader.vue'
import PortraitModeModalContent from '@/components/Modals/Genres/PortraitMode.vue'
export default {
  name: "GenreModal",
  components: {
    SignInModalContent,
    GenreModalContent,
    PulseLoaderModalContent,
    PortraitModeModalContent
  },
  data() {
    return {
        isLoading: true,
    };
  },

  async mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 1500);
  },

  computed: {

    show: {
        get() {
            return this.$store.getters['modals/genreModalVisible']
        },
        set(value) {
            if (value) {
                this.$store.dispatch('modals/SET_GENRE_MODAL_SHOW')
            } else {
                this.$store.dispatch('modals/HIDE_GENRE_MODAL')
            }
        }
    },
    user_logged_in() { return this.$store.getters['user/user_logged_in'] },
    show_portrait_mode() { return this.$isMobile() && !this.landscape_mode },
    ...mapGetters({
      userIsAuthorized: 'user/isAuthorized',
      user_id: 'user/id',
      users_music_categories: 'user/music_categories',
      landscape_mode: 'webHQ/getLandscapeMode',

    }),
  },

 
  methods: {
   async start_search(query) {
      console.log("Modal.start_search", query)
      if (this.isSearching) return
      this.isSearching = true
      setTimeout( async () => {
        var game = await this.$store.dispatch('gameCreation/createGameFromSearchQuery', {query: query, query_type: "artist"})
        console.log("Modal.start_search", game)
        this.$store.dispatch('context/joinGame', {game: game})
        this.isSearching = false
      }, 450);
    },
    async search() {
        console.log(`SearchModal.start() called`, this.query)
        //this.showLoadingIcon() 
        var response = await api.spotify.search.search(this.query, "artist")
        return response.data.artists?.items[0]
    },
    closeModal() {
        this.$store.dispatch('modals/HIDE_GENRE_MODAL')
    },
    showLoadingIcon() {
        Vue.set(this,"searchStatus", 1)
    },
    errorLoadingIcon() {
        Vue.set(this,"searchStatus", 0)
    },
    hideLoadingIcon() {
        Vue.set(this,"searchStatus", 0)
    },


  }
};
</script>


<style lang="scss" scoped>
.modal {
    display: block;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 32px;
        width: 66%;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 1.5rem;
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        width: 66%;
        height: 100%;
        text-align: left;
    }
    &__frame {
      height: 36vh;
    }
}
 

@media only screen and (max-width: 1000px) and (orientation: landscape) {
    .modal {
      &__frame {
       height: 70vh;
     //  top: 6%;
      }
      &__body {
        width: 100%;

      }
    }
  }
</style>

