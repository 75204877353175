<template>
    <div class="playlist-card" @click="start" :class="cardClass" >
        <div class="card-content">
            <div v-if="hasImage" class="playlist_details__cover">
                <img :src="playlistImage" alt="" />
            </div>
            <div class="playlist_details  ml-4">
                <p class="playlist_details__name">{{playlistName}}</p>
                <p v-if="true" class="playlist_details__description">{{playlistDescription}} | <span>hide</span></p>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/api';
import Vue from 'vue';
import { db } from '@/store/modules/firebase'
export default {
    name: "PlaylistCard",
    props: {
        game_id: {
            type: String,
            required: true
        }
    },

 
    data() {
        return {
            selected_game: null,
            playlist: {},
            user_progress: {},
        }
    },
    mounted() {
//        db.ref('games').remove()
        this.$bind("playlist", db.ref(`games/${this.game_id}`))
    },
    computed: {
        userHasStartedGame() { return !!this.$store.getters['user/active_games'][this.game_id] },
        cardClass() {
            if (this.start_date > this.end_date ) {  return 'card-content-error'; }  //error case

            const currentDate = new Date().getTime();
            if (currentDate > this.end_date) {
                return 'card-content-complete'; // voting is complete
            } else if (currentDate < this.start_date) {
                return 'card-content-inactive'; // voting is not started
            } else { // voting is in session
                //if user has not yet voted, apply no class
                //if user has voted, apply active class
                return this.userHasStartedGame ? 'card-content-active' : 'card-content-nothing'
           
            }
        },
        start_date() {
            return this.playlist.start_date
        },
        end_date() {
            return this.playlist.expire_date
        },
        hasImage() { return !!this.playlist.image },
        playlistImage() { return this.hasImage ? this.playlist.image : "" },
        playlistName() { return this.playlist.name },
        playlistCurator() { return this.playlist.created_by },
        playlistDescription() {
            const voteCount = Math.ceil(Math.random() * 200) + 10
            var description = ""
            // check if playlist start date is in the future
            if (this.playlist.start_date > Date.now()) {
                var start_date = new Date(this.playlist.start_date)
                description = `Come back ${formatDate(start_date)}` // at ${start_date.toLocaleTimeString()}`
            }
            // if playlist start_date is in the past 
            else {
                description = `Voting ends ${formatDate(new Date(this.playlist.expire_date))}`
//                description = `${voteCount} votes - curated by ${this.playlistCurator}`
            }
            return description

        }
    },
    methods: {
        // getDescription(playlist)  {
        // },
        start() {
            this.$emit('start', this.playlist)
        }
    }
}

/*
    Summary: 
        - This function takes a date and returns a string in the format of "Wednesday 12:00 PM"
        - If the date is today, it returns "Today 12:00 PM"
        - If the date is tomorrow, it returns "Tomorrow 12:00 PM"

    Parameters:
        - date: Date milliseconds since 1970
    
    Returns:
        - string: formatted date
*/
function formatDate(date) {
  const currentDate = new Date(); // This is the current date
  let formattedDate = '';

  if (date.getDate() === currentDate.getDate() + 1) {
    formattedDate += 'Tomorrow';
  } else if (date.getDate() === currentDate.getDate() && date.getTime() > currentDate.getTime()) {
    formattedDate += 'Today';
  } else {
    formattedDate = date.toLocaleString('en-US', {weekday: 'long'});
  }

  // get the time on 12hr format
  var time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  formattedDate += ` ` + time

  return formattedDate;
}

</script>
<style lang="scss" scoped>
.playlist-card {
  // padding: 30px 0;
  font-family: 'OpenSans-Bold';
  margin: 0 auto;
  display: flex; 
  color: white; 
  width: 100%;
  //height: 110px;
//  height: 50px;
 // border: 2px solid rgba(22, 23, 27, 0.1);
  box-shadow: 0px 2px 16px rgba(22, 23, 27, 0.1);
  border-radius: 20px;
  font-family: 'Rubik';
}
.playlist-card:hover {
    cursor: pointer;
}
.card-content {
  display: flex;
  justify-content: center;
    
  padding: 0;
}

.playlist_details {
    display: inline-flex;
    flex-direction: column;
    //color: white;
    font-size: 0.75rem;
    height: 66px;
    &__name {
        font-weight: 700;
    }
    &__description {

    }
    &__cover {
        width: 2.25rem;
        height: 2.25rem;
        display: inline-block;

    }
    
}
.playlist_details:hover {
    color: white;
}

.card-content-complete {
    color: #7EFF7E;
}
.card-content-active {
    //color: white;
    color: #FFF27E;
}
.card-content-inactive {
    color: #595959;
}

.card-content-error {
    color: #FF7E7E;
}
</style>