<template>
    <MobileGameCard     v-if="$isMobile()"
                        :game="game"
                        :game_tags="game_tag_str"
                        :game_name="truncated_name"
                        :game_image="game_image"
                        :isFirebaseGame="isFirebaseGame"
                        :playerCount="playerCount"  /> 
    <DesktopGameCard    v-else 
                        :game="game" 
                        :game_tags="game_tag_str"
                        :game_name="truncated_name()"
                        :game_image="game_image"
                        :isFirebaseGame="isFirebaseGame"
                        :playerCount="playerCount" 
                        :show_countdown="show_countdown"
                        @optionSelected="optionSelected"
                        :creator="creator" 
                        :isDraft="false" 
                        :isAdmin="false"  />
</template>
<script>
import DesktopGameCard from '@/components/GameBrowser/GameCard/Desktop.vue'
import MobileGameCard from '@/components/GameBrowser/GameCard/Mobile.vue'
import { gameLogic } from "@/mixins";
import { db } from '@/store/modules/firebase'
import { createBlock } from '@vue/runtime-core';
import { mapGetters } from "vuex";
function truncate(str, n) { return (str?.length > n) ? str.substr(0, n-1) + '...' : str }

//import Vue from "vue";
export default {
    name: "GameCard",
    props: ["playlist"],
    data() {
        return {
            creator: {},
            GAME: null,
            playlistRef: db.ref('playlists').child(`${this.playlist.category}`).child(`${this.playlist.id}`),
            gameRef: db.ref('games').child(`${this.playlist.game_id}`).child(`${this.playlist.game_id}`)
        }
    },
    mixins: [gameLogic],
    components: { DesktopGameCard, MobileGameCard },
    async mounted() {
        //this.$bind("creator", this.users_ref.child(`${this.game.created_by}`))
        await this.$bind("GAME", this.games_ref.child(`${this.playlist.game_id}`))
        console.log("MOUNTED", this.game.id[0] == "-")
        // setTimeout(() => {
        //     console.log("GameCard Minutes Remaining: ", this.minutesRemaining)
        //     if (this.minutesToGameOver() < 0 ){ this.playlistRef.child('game_id').remove() } 
    
        // }, 1000)
        //console.log("GameCard.mounted()", this.GAME.expire_date, Date.now().time)
        //if (this.GAME?.expire_date > Date.now().time) { this.playlistRef.child('game_id').remove() }
    },
    computed: {
        game() { return this.isFirebaseGame ? this.GAME : this.playlist},
     //   truncated_name() { return truncate(this.game.name, 26)}, 
        show_countdown() { return this.game && this.GAME && (this.game.game_id || this.game.id[0] == "-" )}, // && this.game.expire_date && this.minutesToGameOver(this.game) <= 60},
        game_image() { return this.getImageFromGame() },
        isFirebaseGame() {
            // If the Firebase reference has been loaded and is not empty, assume the game is from Firebase
            return (this.GAME && Object.keys(this.GAME).length > 0)
        },        
        game_tags() { return this.game.tags ? Object.keys(this.game.tags).slice(0,2) : [] },
        game_tag_str() { return `${this.game.tags} • by Spotify ` },
        minutesRemaining() { return this.minutesToGameOver(this.game) },
        ...mapGetters({
            users_ref: "firebase/users_ref",
            games_ref: "firebase/games_ref"
      }),
    },
    watch: {
        GAME: {
            handler: function (val, oldVal) {
                if (val && val.expire_date && 
                    (new Date(val.expire_date).getTime() < Date.now()) ) {
                    this.playlistRef.child('game_id').remove()
                }
            },
            deep: true
        }
    },

    methods: {
        optionSelected(str) {
            console.log(`GameCard.${str}() called`)
            this[str]()
        },
        getImageFromGame() {
           return this.game.image ? this.game.image :  this.game.images ? this.game.images[0].url : ""
        },
        truncated_name() { return truncate(this.game.name, 26)}, 
        minutesToGameOver() {
            // Get current date and time
            var now = new Date();
            
            // Convert timestamp to date object
            var date = new Date(this.game.expire_date);
            
            // Calculate the difference in minutes
            var diff = now - date;
            var diffMinutes = Math.floor(diff / 1000 / 60);
            var result = diffMinutes * -1;
      
            return result;
            
        }, 
        playerCount() { return this.game.players ? Object.keys(this.game.players).length : 0 },

    }

}
</script>