export const gameLogic = {
    methods: {
        gameIsOver(game) {
            var ms = this.millisecondsToGameOver(game)
            return ms ? ms < 0 : false
        },
        gameStarted(game) {
            var ms = this.millisecondsToGameStart(game)
            return ms ? ms <= 0 : false
        },
        millisecondsToGameOver(game) {
            var date = new Date();
            if (game && game.expire_date && typeof game.expire_date == "number") {
                return game.expire_date - date.valueOf() 
            } else {
                return null
            }
        },
        millisecondsToGameStart(game) {
            var date = new Date();
            if (game.start_date && typeof game.start_date == "number") {
                return game.start_date - date.valueOf()
            } else {
                console.log("Game's start date is invalid", game)
                return null
            }
        },
        secondsToGameOver(game) {
            return this.millisecondsToGameOver(game) / 1000
        },
        minutesToGameOver(game) {
            return this.secondsToGameOver(game) / 60
        },
        hoursToGameOver(game) {
            return this.minutesToGameOver(game) / 60
        },
        hoursAndMinutesToGameOver(game) {
            var hours = this.hoursToGameOver(game)
            if (hours) {
                var rhours = Math.floor(hours);
                var minutes = (hours - rhours) * 60;
                var rminutes = Math.round(minutes);
                return [rhours, rminutes]
            } else { return []}
        },
        hoursMinutesAndSecondsToGameOver(game) {
            var hours = this.hoursToGameOver(game)
            if (hours) {
                var rhours = Math.floor(hours);
                var minutes = (hours - rhours) * 60;
                var rminutes = Math.floor(minutes);
                var seconds = (minutes - rminutes) * 60;
                var rseconds = Math.round(seconds);
                return [rhours, rminutes, rseconds]
            } else { return [] }
        },
        hoursMinutesAndSecondsToGameOverFormatted(game) {
            var time = this.hoursMinutesAndSecondsToGameOver(game)
            if (time.length == 3) {
                var str = ""
                time.forEach(num => {
                    var el = num == 0 
                             ? "00" 
                             : num < 10 
                             ? `0${num}`
                             : num
                    str += el
                    str += ":"
                             
                })
                return str.slice(0, -1)
            } else { return `` }
        },
        formatNumber(num) {
            return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
        },

        
    }
};
