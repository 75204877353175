<template>
    <div class="modal__frame">
        <div class="modal__dialog">
            <div class="modal__header">
            <p>What kind of music would you like to listen to?</p>
            </div>
            <div class="modal__body">
            <div class="categories-container">
                <genre-card v-for="category in categories" :key="category.id" :genre="category" class="category-card" @selectCategory="selectCategory" />
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import { db } from '@/store/modules/firebase'
import GenreCard from '@/components/Modals/Genres/GenreCard.vue'
import '@/assets/css/modals.scss';

export default {
    name: 'GenreModalContent',
    components: {
        GenreCard
    },
    data() {
        return {
            selectedCategory: null,
            categories_obj: null
        }
    },
    methods: {
        async selectCategory(category) {
            console.log("Modal.selectCategory", category.name )
            if (this.isLoading) return
            this.isLoading = true
    //        var game = await this.$store.dispatch('gameCreation/createGameFromCategory', {category: category})
            var game = await this.$store.dispatch('gameCreation/createGameFromSearchForPlaylistsFirebase', {query: category.name})
            console.log("Modal.selectCategory GAME CREATED", game)
            this.$store.dispatch('context/joinGame', {game: game})
            this.isLoading = false
            this.closeModal()
        },
        closeModal() {
            this.$emit('close')
        }
    },
    computed: {
        categories() {  return  this.categories_obj ? Object.values(this.categories_obj).filter(category => !category.hidden) : [] },
    },
    firebase: {
        categories_obj: db.ref('categories')
    },
}
</script>
<style lang="scss" scoped>
.modal {
    display: block;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 32px;
        width: 66%;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 1.5rem;
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        height: 100%;
        text-align: left;
    }
    &__frame {
      height: 36vh;
    }
}
.categories-container {
  display: flex;
  gap: 1.6vw;
  margin-bottom: 7.3%;

    justify-content: center;
}
.category-card {
  width: 10.5vw;
  height: 16vh;
}
.category-card:hover {
  cursor: pointer;
}
// Mobile - Landscape 
@media only screen and (max-width: 1000px) and (orientation: landscape) {

    .categories-container {
      width: 100%;
    }
    .category-card {
      width: 32vh;
      height: 32vh;
    }
}
</style>