import users from "./users"
import playlists from "./playlists"
import search from "./search"
import personalization from "./personalization"
import recommendations from "./recommendations"
import player from './player'
import browse from './browse'
import artists from './artists'
export default {
    users,
    playlists, 
    search,
    personalization,
    player,
    browse,
    artists,
    recommendations
}