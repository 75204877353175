<template>
    <div class="modal__frame">
        <div class="modal__dialog">
            <div  class="spinner-container">
                <pulse-loader />
            </div>
        </div>
    </div>
</template>
<script>
import PulseLoader from '@/components/SVGs/PulseLoader.vue';
import '@/assets/css/modals.scss';

export default {
    name: "PulseLoaderModalContent",
    components: {
        PulseLoader
    },
}
</script>