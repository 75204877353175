<template>
    <div class="more_menu is-hoverable dropdown" >
        <div class="dropdown-trigger">
                <img src="@/assets/more_icon_black.png" alt="more" class="more_menu__icon">
        </div>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
                <a v-if="!isAdmin && isDraft" class="dropdown-item" @click.prevent.stop="optionSelected('submitPreviewForApproval')" > Submit Game </a>
                <a v-if="isAdmin && isDraft" class="dropdown-item" @click.prevent.stop="optionSelected('approveGameDraft')" > Approve Game </a>
                <a v-if="isDraft" class="dropdown-item" @click.prevent.stop="optionSelected('openGamePreview')" > Open Preview </a>
                <a v-if="isDraft" class="dropdown-item" @click.prevent.stop="optionSelected('editDetails')" > Edit Details </a>
                <a v-if="show_delete_btn" class="dropdown-item" @click.prevent.stop="optionSelected('removeGame')" > Delete </a>
                <a v-if="!gameOver && !isDraft && isAdmin" class="dropdown-item" @click.prevent.stop="optionSelected('endGame')" > End Game </a>
            </div>
        </div>
    </div>    
</template>

<script>
export default {
    name: "DropdownMenu",
    props: ["isAdmin", "isDraft", "gameOver"],
    methods: {
        optionSelected(str) {
            this.$emit('optionSelected', str)
        }
    },
    computed: {
        show_delete_btn: function() {
            return (this.$store.getters["user/isAdmin"] || (this.isDraft && this.$store.getters["user/id"] == this.game.created_by))
        },
        show_dropdown_menu: function () {
            return (this.$store.getters['user/isAdmin'] || (this.isDraft && this.$store.getters['user/id'] == this.game.created_by)) 
        },
    }
}
</script>

<style lang="scss" scoped>
.more_menu {
  border: 0;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;

  &__icon {
    width: 32px;
    height: auto;
    //background: rgba(0, 0, 0, 1)
    border-radius: 50%;
        background: linear-gradient(270deg, #9BFF7B 0%, #D9FF7B 100%);

  }
}

.dropdown-menu {
  z-index: 20;
}
.dropdown-trigger:hover {
  cursor: pointer;
}
</style>