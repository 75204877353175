<template>
  <div class="player" >
    <div v-if="true" class="player__inner">
        <current-track v-show="show_current_track"  class="player__left" :class="{'isDesktop__left': !$isMobile()}" />

        <div class="player__center">
          <PlayerPlayback  v-if="!show_game_complete_modal && audioPlayerInitiated" />
          <PlayButton v-if="show_play_button" class="play-btn"  />
        </div>

        <div class="player__right" v-if="showPollControls">
            <PollControls />
        </div>

    </div>

  
        

  </div>
</template>


<script>
//import CurrentTrack from './CurrentTrack'
import CurrentTrack from './CurrentTrack'
import PlayerPlayback from "./PlayerPlayback";
import PollControls from "./PollControls";
import PlayButton from '@/components/PlayButton'
import { mapGetters } from "vuex";
import { gameLogic } from "@/mixins";

//Vue.use(XnsSeekBar)


export default {
  name: 'Footer',
  data: function() {
    return {
      timer: "5",
      progressColor: "#21FB92",
      progressBgColor: "#ffffff",
      hidePlayer: true, // for persistent player
      progressHeightMain: 8,
      volumeHeightMain: 8,
      progressHeightPersistent: 15,
      volumeHeightPersistent: 5,
      
    }
  },
  components: {
    CurrentTrack,
    PlayButton,
    PlayerPlayback,
    PollControls
  },
  mixins: [gameLogic],
  computed: {
    showPollControls() {
      return this.$route.name == "Poll" && !this.show_game_complete_modal && false
    },
    show_current_track() { 
      return !this.show_game_complete_modal  && false;
    },
    show_play_button() { 
      return !this.show_game_complete_modal && this.audioPlayerInitiated && false
    },
    ...mapGetters({
        prev_poll_enabled: "game/prevPollEnabled",
        preview: "audioPlayer/current_preview",
        playing: "audioPlayer/isPlaying",
        game: 'context/cGame',
        show_game_complete_modal: "modals/show_game_complete_modal"
      }),
      audioPlayerInitiated() { return this.$store.state.audioPlayer.initiated},

    song:           function() { return this.$store.state.audioPlayer.current_song },
    message:        function() {
      return this.$store.state.game.poll_controls_enabled ? "SKIP POLL" : "NEXT POLL IN"
    },
    show_game_complete_modal: function() {  return this.$store.getters['modals/show_game_complete_modal'] },
    hasPreview:     function() { return this.song ? this.song.preview_url != null : false },
    progressStyle:  function() { return { width: `${this.progress}%` }},

    show_poll_controls: function() { return this.$store.state.game.poll_controls_enabled },
    

    progress: function() {
      //NOTE TO SELF: DELETE THIS COMMENT IF ALL IS WORKING
      //var percentage = (this.song.preview.seek()/this.song.preview.duration())/100
      var percentage = (this.preview.preview.seek()/this.preview.preview.duration())/100

      console.log(`SONG PROGRESS: ${percentage}`);
      return percentage

    },
  
    
    // show_play_button: function() { return !this.playing },
    show_pause_button: function() { return this.playing },

    duration: function() {
      return this.song ? this.song.duration_ms : null// convertMillisecondsToDigitalClock(this.song.duration_ms) : null
    },
    duration_label: function() {
      return this.song ? formatTime(this.song.duration, "seconds") : "uh oh"
    },


    song_name: function() {
      return this.song ? this.song.name : ""
    },
    song_artist: function() {
      return this.song ? this.song.artists[0].name : ""
    },
    song_cover_album: function() {
      return this.song ? this.song.album?.images[0].url : ""
    },
  },
  methods: {

    
      favorite:         async function() { 
        console.log("ENTERS HERE");
        await this.$store.dispatch('user/favorite_song', this.song)
      },
     
  },

}


function formatTime(num, units) {
  var secs = null
  if (units == "milliseconds") {
    secs = Math.round(num / 1000) 
  } else {
    secs = num
  }
  var minutes = Math.floor(secs / 60) || 0;
  var seconds = Math.floor(secs - minutes * 60) || 0;
  // console.log(num);
  // console.log(minutes + ':' + (seconds < 10 ? '0' : '') + seconds);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}


</script>

<style lang="scss" scoped>

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.player {
  background: #16171B;
  color: white;
  position: relative

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    height: 100%;
    padding-bottom: 20px;
  }
  &__left {
    min-width: 180px;
    margin: 16px;
 //   filter: blur(9px);
  }
  .isDesktop {
    &__left {
      width: 29%;
    }
  }
  &__left:hover {
    cursor: pointer;
  }
  &__right {
    width: 29%;
    min-width: 180px;
    display: flex;
    justify-content: flex-end;
    padding-right: 36px;
    position: absolute;
    right: 8px;
    bottom: 0;
    top: 0;
  }
  &__center {
      width: 42%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      height: 100%
  }

}



.play-btn {
  width: auto;
  height: 40%;
  margin: 0 auto;
}
.play-btn > * > img {
   height: 100%;
    display: flex;
    justify-content: center;
    width: auto;
}

p {
  color: white;
  text-align: left;

}
.song-controls-container {
  /* width: 41%; */

  width: 9%;
  height: 100%;
  margin: 0 auto;

}

.progress-bar {
  background: rgba(173, 173, 173, 0.4);
  opacity: 0.8;
  border-radius: 10px;
  height: 4px;
}
.progress-val {
  background: #FFFFFF;
  border-radius: 10px;
  height: 4px;
  /* width: 65px; */
  /* margin-top: -4px; */
}
.left {
  float: left;
}
.right {
  float: right;
}
.duration-text {
  font-size: 12px;
  line-height: 14px;

  color: #FFFFFF;
  opacity: 0.6;
}








</style>