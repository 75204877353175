<template>
    <div class="landscape"  v-if="!claimed">
        <div class="claim">
            <h1 class="claim__title">STELLAR WORK!</h1>
            <div class="tokens">
                <p class="tokens__label">You've Won</p>
                <token-balance />
            </div>
            <button class="claim__btn" @click="claim()">Click to claim 🤑</button>
        
        </div>
        <div class="landscape__rhs" @click="closeClicked()">
            <close-icon />
        </div>
    </div>
    <div class="landscape" v-else>
        <div class="landscape__lhs">
            <VoteResults class="mobile__card" :tracks="tracks" :game_expired="gameIsOver(game)" @ctaClicked="seeRecommended()" />
            <!-- <jackpot-size-label :size="game.tokens" />
            <hr>
            <jackpot-timer /> -->
            <!-- <JackpotInfo class="mobile__jackpot_card" :game="game"/> -->
        </div>
        <div class="landscape__center">
            <invite-friends class="mobile__invite" />
        </div>
        <div class="landscape__rhs" @click="closeClicked()">
            <close-icon />
        </div>
    </div>
    

</template>

<script>
import Vue from 'vue';

import { gameLogic } from "@/mixins";
import { db } from '@/store/modules/firebase'
import { mapGetters, mapState } from "vuex";
import JackpotInfo from '@/components/GameComplete/JackpotInfo'
import VoteResults from '@/components/GameComplete/VoteResults'
import InviteFriends from '@/components/GameComplete/InviteFriends'
import JackpotSizeLabel from '@/components/GameComplete/JackpotInfo/JackpotSizeLabel.vue';
import JackpotTimer from '@/components/GameComplete/JackpotInfo/JackpotTimer.vue';
import CloseIcon from '@/components/SVGs/Close.vue';
import TokenBalance from '@/components/TokenBalance'

export default {
  name: "GCLandscape",
  mixins: [gameLogic],
  data() {
    return {
        claimed: false,
    }
  },
  components: {
       VoteResults, CloseIcon, 
       InviteFriends,  
         TokenBalance,
       //JackpotSizeLabel, JackpotTimer
  },
  props: ["game", "tracks"],
  methods: {
      closeClicked() {
          console.log("Landscape.closeClicked()")
          this.$emit('closeClicked')
      },
        claim() {
            this.claimed = true
        }
  },

};


</script>


<style lang="scss" scoped>

hr {
    width: 38%;
    margin: 29px auto;
    background-color:  #505050;
    size: 1;
    

}
.landscape {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 100vh; //THIS WILL PROB NEED TO CHANGE
    &__lhs {
        height: 92vh;
        margin-top: 8vh;
        width: 62vw;    }
    &__center {
        height: 100%;
        overflow: scroll;
        margin-left: 8%;
        display: flex;
        align-items: center;
    }
    &__rhs {
        align-self: flex-start;
        margin-top: 3.3%;
    }
}
.claim {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    &__title {
        font-family: 'Futura-Black';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 53px;
    }
    &__btn {
        margin-top: 17vh;
        background: #FFFFFF;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
        padding: 0.5rem 1rem;

    }
}
.tokens {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    width: 100%;
    margin-top: 7vh;

    max-width: 300px;
    font-family: 'Rubik-Bold';
    &__label {
        font-family: 'Rubik-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    }
}



 .mobile {
    &__invite {
        background-color: rgba(255,255,255, 0.2);
        height: 56vh;
        border-radius: 8px;
        width: 27.25vw;
    }
 }
</style>

