<template>
        <Desktop    :track="track" 
                    :index="index" 
                    :votes_count="votes_count" 
                    :header="header" 
                    v-if="!$isMobile()"
                    @artistSelected="artistSelected" />
  
        <Mobile :track="track" 
                :index="index" 
                :votes_count="votes_count" 
                :header="header" 
                @artistSelected="artistSelected"
                v-else />
</template>


<script>
import Desktop from './Desktop.vue'
import Mobile from './Mobile.vue'
export default {
    name: "Track",
    props: ["track", "index", "votes_count", "header"],
    components: { Desktop, Mobile },
    methods: {
        artistSelected(artist_id) {
            console.log("artistSelected", artist_id)
            this.$router.push({ name: "ProfilePage", params: { id: artist_id } })
            this.$emit("artistSelected", artist_id)
        }
    }

}
</script>
