import request from "./../request";

export default {
    getUserProfile(userID) {
        console.log("api.spotify.users.getUserProfile() called 2");
        if (userID) {
            return request.get(`users/${userID}`);
        } else {
            return request.get("me");
        }
    }
    
};
