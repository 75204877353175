import { Howl } from 'howler';
import Vue from 'vue';
import api from '@/api';

// initial state
const state = () => ({
    current_song: null,
    tracks: {},
    previews: {},
    switch_songs_enabled: true, //this should be enabled before the user plays their first song of the session
    isPlayingPreview: false,
    initiated: false
})

// getters
const getters = {
    isPlaying(state, getters, rootState, rootGetters) { return state.isPlayingPreview ? getters.current_preview?.playing() : rootGetters["player/isPlaying"] },
    getPlayback(state, getters, _, rootGetters) { return state.isPlayingPreview ? 
            {preview: getters.current_preview, ...state.current_song } : (rootGetters["player/getPlayback"].item || null) },

    current_preview(state) { return state.current_song ? state.previews[state.current_song.id] : null },
    preview_seek(state) { return state.previews[state.current_song.id].seek() },
    preview_duration(state) { return state.previews[state.current_song.id].duration() }

}


// actions
const actions = {

    async pause(context) {
        console.log("Pause called for ", context.state.current_song?.name);
        if (context.getters.getPlayback != null) {
            if (context.state.isPlayingPreview) {
                console.log("Trying to pause current preview");
                await context.getters.current_preview?.pause() 
                console.log("Pause success!");
            }
            else {
                console.log("Trying to pause full song");
                await api.spotify.player.pause()
            }
            console.log("PAUSED SUCCESS?", !context.getters.isPlaying);
            return !context.getters.isPlaying
        }


    },
    async toggle(context, {track}) {
        const cSong = context.getters.getPlayback
        if (cSong && cSong.id == track.id && context.getters.isPlaying){
            await context.getters.current_preview?.pause() 
        } else {
            context.dispatch('play', {track: track})
        }
    },

    async play(context, {track, use_preview} ) {
        const song = track || context.state.current_song
        var preview = use_preview || context.state.isPlayingPreview
        console.log(`audioPlayer.play() PREVIEW ${preview} || SONG: `, song)

        if (preview) {
            console.log("Playing Preview")
            await context.dispatch("findOrCreatePreview", {track: song})
            await context.dispatch("pause")
            context.commit("setCurrentSong", {track: song})
            console.log("song set to ", context.state.current_song)
            try {
                console.log("PLAYING PREVIEW", context.getters.current_preview)
                context.getters.current_preview.play()

                context.commit('setIsPlayingPreview', true)
    
            } catch {
                context.dispatch("notification/add", {
                    type: "error",
                    message: "It seems there was an error playing your song. Please refresh the page"
                }, {root: true});
            }
        } 
        else { 
            console.log("PLAYING FULL SONG");               
            if (context.getters.isPlaying && context.state.isPlayingPreview) { await context.dispatch("pause") }
            context.commit('setIsPlayingPreview', false)

            //var uris = songs.map(track => { return `spotify:track:${track.id}` })
            var uris = [`spotify:track:${song.id}`]
            context.commit("setCurrentSong", song)
            console.log("playing: ", uris);
            try {
                api.spotify.player.play(uris);                    
            } catch {
                context.dispatch("notification/add", {
                    type: "error",
                    message: "If you're seeing this error, please refresh your page!"
                });
            }
        }

        Vue.set(context.state, "switch_songs_enabled", false)
        Vue.set(context.state, "initiated", true)
        return
    },
    findOrCreatePreview(context, {track}) {
        console.log("findOrCreatePreview for ", track.id, Object.keys(context.state.tracks))
        var preview_exists = context.state.tracks[`${track.id}`] != null
        if (!preview_exists) {
            console.log("NO PREVIEW EXISTS, CREATING PREVIEW", context.state.tracks)
            var preview_sound = createPreview(track, context)
            Vue.set(context.state.previews, track.id, preview_sound)
            var preview = {meta: track, preview: preview_sound}
            Vue.set(context.state.tracks, track.id, preview)
        }
    },

}

// mutations
const mutations = {

    setCurrentSong(state, {track}) {
        //console.log(`SETCURRENTSONG() -- ${song.name}`)
        console.log("setCurrentSong", track)
        if (track) {
            console.log(`Changing current song from ${state.current_song?.name} to ${track.name}`)
            Vue.set(state, "current_song", track) 
            console.log("NEW CURRENT SONG: ", state.current_song)
        } else {
            console.log("No track given")
        }
    },
    setIsPlayingPreview(state, bool) {
        console.log("setIsPlayingPreview", bool)
        state.isPlayingPreview = bool
    }
}



function createPreview(song, context) {
    return new Howl({
        src: [song.preview_url],
        html5: true, // A live stream can only be played through HTML5 Audio.
        format: ['mp3', 'aac'],
        onend() {
          //  context.commit('setIsPlayingPreview', false)
        }
        // onplay: function() {
        //     requestAnimationFrame(step)
        // }
    })
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
    