<template>
    <section>
        <div class="poll-container">
          <div class="columns is-gapless is-vcentered mb-0" :class="{'is-mobile': landscape_mode }" >
              <PollOption class="poll-container__option column is-one-half" :class="pollOptionStyles" 
                          v-for="(option, index) in poll_options" 
                          :key="`${option.id}-${componentKey}`"
                          type="track" 
                          :index="index"
                          :track="option" 
                          :landscape_mode="landscape_mode"
                          :isAutoPlaying="isAutoPlaying"
                          @menuRevealed="menuRevealed"
                          @upvote="upvote" />
          </div>
          <div class="shuffle" @click="shufflePlaylist()" v-if="show_shuffle_btn">
            <div class="shuffle__icon">
              <shuffle-icon />
            </div>
            <span class="shuffle__label">Shuffle</span>
          </div>
        </div>
    </section>
</template>

<script>
import PollOption from '@/components/Poll/PollOption/'
import { mapGetters, mapState, mapActions } from "vuex";
import { gameLogic } from "@/mixins";
import ShuffleIcon from '@/components/SVGs/Shuffle.vue';
const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));

export default {
    name: "PollContainer",
    props: ["poll_options", "landscape_mode", "isAutoPlaying"],
    data() {
      return {
        pollOptionStyles: {
          'user-has-voted': true,
        },
        addingPolls: false,
        componentKey: 0
      }
    },
    components: { 
      PollOption,
      ShuffleIcon,
    },
    mixins: [gameLogic],
    computed: {
        show_shuffle_btn() { return this.$store.getters['webHQ/getLandscapeMode'] },
//        nextVoteDelay() { return this.$store.state.timer.default },
        ...mapGetters({
          userHasReachedEndOfPolls: 'context/userHasReachedEndOfPolls',
          poll_ids: 'context/cPoll_ids',
          poll_index: 'context/cPoll_index',
          game: 'context/cGame',
          userHasVoted: 'context/userHasVoted',
          vote_queue: 'context/vote_queue'

        }),
        ...mapState('game', ['nextVoteDelay']),
    },
    methods: {
      ...mapActions({
        next_poll: "context/NEXT_POLL",
        prev_poll: 'context/PREV_POLL',
        emptyVoteQueue: 'firebase/emptyVoteQueue',
      }),
      menuRevealed() { this.$emit('menuRevealed') },
      pollOptionKey(key) { 
        console.log("SUFFIX:", this.pollOptionKeySuffix)
        return key + this.pollOptionKeySuffix 
      },

      async upvote(track_id) {
        var track = this.poll_options.find(option => option.id == track_id)
        const game_id = this.game.id || this.game.game_id
        await this.$store.dispatch('game/upvote', {song: track, poll: this.$store.getters["context/cPoll_id"]})
        await this.$store.dispatch('user/favorite_song', track)

        // if user reaches end of poll
        if (this.userHasReachedEndOfPolls && this.userHasVoted) {
          console.log("USER HAS REACHED END OF POLLS")
          var continuePlaying = await this.replenishPollsFromVoteQueue()
          if (!continuePlaying) {
            // game added to user_progress/completed_games
            this.$router.push({name: "GameComplete", params: { id: game_id}})
          }
        } else {
          console.log("USER HAS NOT REACHED END OF POLLS")
        }
        console.log("USER HAS VOTED", game_id)
        this.$store.dispatch('user/addGameToCompletedGames', {game_id: game_id} )
        this.suspendPollControlsAndNextPoll()
        //if (!this.$store.state.context.preview_mode) { analytics.logEvent("vote", { song_name: track, song_artist: track.artist}) }
      },
      /*
        Summary: Every time a user votes, the track is added to a vote queue. These tracks are then used to create more polls after the user
        reaches the end of a gmae's initial polls. 
        After the new polls are created, the vote queue is emptied.
        And return true to indicate that the action was completed and  game may continue
        If the vote queue has less than 2 songs, than it returns false to indicate that the action was not completed and the game is over.
        This is used to indicate whether the player should be taken to the GameCmopleteView
      */
      async replenishPollsFromVoteQueue() {
          var vote_queue =  this.vote_queue.map( track => track.item)
          if (vote_queue.length > 1) {
            await this.addNewPollsToGameFromVoteQueueTracks(vote_queue)          
            this.emptyVoteQueue({vote_queue: this.$store.getters["firebase/vote_queue_ref"]})
            return true
          } 
          else {
            console.log("No songs in vote queue")
            return false
          }
      },

    

      /*
        Summary: After a vote is registered, we move to the next poll. If user is at end of polls, new polls are created
      */
      async voted() {
          //If game is over - do nothing
          if (this.gameIsOver(this.game)) { console.log("GAME IS OVER. BUTTON DISABLED") }
          else {
              await this.suspendPollControlsAndNextPoll() 
              var vote_queue =  this.vote_queue.map( track => track.item)
              if (this.userHasReachedEndOfPolls && this.userHasVoted) {
                  if (vote_queue.length > 1) {
                      console.log("Tracks length > 1. ADding polls and moving to next poll", vote_queue)
                      this.prev_poll
                      await this.addNewPollsToGameFromVoteQueueTracks(vote_queue)          
                      await this.suspendPollControlsAndNextPoll()
                      this.emptyVoteQueue({vote_queue: this.$store.getters["firebase/vote_queue_ref"]})
                  }
              }
              else {
                  console.log("THIS SHOULD NOT BE VISIBLE IF YOU DON'T SEE GAME COMPLETE MODAL")
                  this.$router.push({ name: "GameComplete", params: { id: this.game.id } })
                  this.$store.dispatch("context/updatePollIndex", {new_index: -1})
              }
          }

          
    },
      async suspendPollControlsAndNextPoll() {
        console.log("Poll.suspendPollControlsAndNextPoll()")
        if (!this.userHasReachedEndOfPolls) {
          this.$store.commit('context/disablePollControls')
          var the = this
          await waitFor(the.nextVoteDelay)
          await the.next_poll()
          the.$store.commit('context/enablePollControls')
        }
        return
      },
      forceRerender() {
        console.log("Desktop.forceRerender()")
        this.componentKey += 1
      },
      shufflePlaylist() {
        this.$store.dispatch('context/shufflePlaylist')

      },
      async addNewPollsToGameFromVoteQueueTracks(tracks) {
        //Add new polls
        if (this.poll_ids) {
          var new_poll_index = Object.keys(this.poll_ids).length 
          console.log("Desktop.addNewPollsToGameFromVoteQueueTracks()", tracks, new_poll_index)
          return await this.$store.dispatch("game/createPollsFromTracks", {tracks: tracks, poll_index: new_poll_index, shuffle: false})
          .then(async (polls) => {
              console.log("Created new polls: ",  polls)
              if (Object.keys(polls).length > 0) {
                console.log("Successful", Object.keys(polls).length)
                await this.$store.dispatch("context/updateCurrentGamePollIDs", {polls: polls})
                return 
              } else {
                console.log("No new polls created")
              }
          })
        } else { console.log("no game polls found")}
    }
    },
   
}
</script>

<style lang="scss">
.poll-container {
  height: 100%;
  background: #1f1f1f;
  overflow-y: hidden;
}
.columns {
  margin-bottom: 0;
  height: 100%;
}
.column {
height: 100%;
}
.shuffle {
  position: absolute;
  z-index: 5;
  right: 3rem;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
//  gap: 4px;
  &__icon {
    //border: 1px solid #fff;
  //  padding: 4px;
    border-radius: 8px;
  }
}
</style>