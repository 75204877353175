import WalletConnectProvider from "@walletconnect/web3-provider";

export const makeProvider = () => {
    return new WalletConnectProvider({
        infuraId: "f59e489d4559427fbae21512c744090c",
        qrcodeModalOptions: {
            desktopLinks: [
                'ledger',
                'tokenary',
            ],
            mobileLinks: [
                "rainbow",
                "metamask",
                "argent",
                "trust",
                "imtoken",
                "pillar",
            ],
        },
    });
}
