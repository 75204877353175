<template>
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_441_143)">
    <path   d="M16.4145 13.5489H15.5834L15.2889 13.2921C16.3198 12.2078 16.9405 10.8001 16.9405 9.26876C16.9405 5.85418 13.8793 3.08636 10.1028 3.08636C6.3263 3.08636 3.26514 5.85418 3.26514 9.26876C3.26514 12.6833 6.3263 15.4512 10.1028 15.4512C11.7964 15.4512 13.3533 14.89 14.5525 13.9579L14.8366 14.2242V14.9756L20.0963 19.7218L21.6637 18.3046L16.4145 13.5489ZM10.1028 13.5489C7.48345 13.5489 5.36903 11.6371 5.36903 9.26876C5.36903 6.90043 7.48345 4.98864 10.1028 4.98864C12.7221 4.98864 14.8366 6.90043 14.8366 9.26876C14.8366 11.6371 12.7221 13.5489 10.1028 13.5489Z" 
            fill="#464545"
            :opacity="opacity"/>
    </g>
    <defs>
    <clipPath id="clip0_441_143">
    <rect   width="22.1218" 
            height="22.8273" 
            fill="#464545" 
            transform="translate(0.5 0.232941)"/>
    </clipPath>
    </defs>
    </svg>
</template>
<script>
export default {
    name: "SearchIcon",
    props: {
        size: {
            type: Number,
            default: 24
        },
        color: {
            type: String,
            default: "#fff"
        },
        opacity: {
            type: Number,
            default: 1
        }
    }

}
</script>