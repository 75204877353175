<template>
  <desktop-confirmation-page
        v-if="!$isMobile()"
        :game="game"
        :game_name="game_name"
        :game_image="game_image"
        :game_tags="game_tags"
        :game_started="game_started"
        :game_expired="game_expired"
        :track_count="track_count"
        :countdown_label="countdown_label"
        :creator="creator"
        :playerCount="playerCount"
        :fee="fee"
        :cta_label="cta_label" 
        @joinGame="joinGame" />
  <mobile-portrait-confirmation-page
        v-else
        :game="game"
        :game_name="game_name"
        :game_image="game_image"
        :game_started="game_started"
        :track_count="track_count"
        :game_tags="game_tags" 
        :cta_label="cta_label"
        :creator="creator"
        @joinGame="joinGame" />

</template>

<script>
import { gameLogic } from "@/mixins";
import { mapActions, mapGetters } from "vuex";
import { db } from '@/store/modules/firebase'
import DesktopConfirmationPage from "@/components/ConfirmationPage/Desktop.vue"
import MobilePortraitConfirmationPage from "@/components/ConfirmationPage/MobilePortrait.vue"
import api from "@/api";

export default {
    name: "ConfirmationPage",
    mixins: [gameLogic],
    components: {DesktopConfirmationPage, MobilePortraitConfirmationPage},
    props: ["GAME"],
    data() {
        return {
            countdown: this.hoursMinutesAndSecondsToGameOver(this.game),// this.secondsToGameOver(this.game),
            fee: this.game ? this.formatNumber(this.game.fee) : "",
            creator: null,
            game2: {},
            isDraft: false,
            track_count: 8
        }
    },
   async mounted() {
        if (this.$route.params.GAME) { console.log("Game found from Browser")  } 
        else if (this.$route.params.playlistID) {
            const playlist_id = this.$route.params.playlistID
            const playlist_category = this.$route.params.playlistCategory
            console.log("Getting playlist id: ", playlist_id);
            //if first character in playlist_id is "-", then it's an active game
            if (playlist_id.charAt(0) === "-") {
                console.log("Binding game", playlist_id);
                this.$store.commit("context/SET_GAME_ID", {id: playlist_id})
                this.loadGame()
                this.$bind('game2', db.ref(`games/${playlist_id}`))
            } else {
                console.log("Not an active game") 
                this.$bind('game2', db.ref('playlists').child(`${playlist_category}`).child(`${playlist_id}`))
                // const fields = "id, name, images, description, owner, followers";
                // var response = await api.spotify.playlists.getPlaylist(playlist_id, fields)
                // var playlist = response.data
                // this.game2 = playlist        

            }
        } else {
            console.log("NO GAME", this.$route.params)
        }
        if(this.countdown > 0) {
            this.countDownTimer()
        }
        this.$bind("creator", db.ref(`users/${this.game.created_by}`))

    },
    computed: {
        game() { return this.GAME || this.game2 },
        playerCount() { return this.game && this.game.players ? Object.keys(this.game.players).length : 0 },
        jackpot() { return this.game ? this.formatNumber(this.game.tokens): "" },

        game_name() { return `${this.game?.name}` || " "},
        game_image() { return this.game?.images ? this.game.images[0].url : (this.game?.image  || "" ) },
        game_expired() { return false },
        game_started() { return true },
        cta_label() { return "Start Playing" },
        userIsInGame() { return this.game.players ? this.game.players[this.$store.getters["user/id"]] : false},


        userTimeZone() {
            return new Intl.DateTimeFormat('en-us', { timeZoneName: 'short' })
                    .formatToParts(new Date())
                    .find(part => part.type == "timeZoneName")
                    .value
        },
        countdown_label() { return this.hoursMinutesAndSecondsToGameOverFormatted(this.game) },
        game_tags() { return this.game?.tags ? Object.keys(this.game.tags) : [] },
        ...mapGetters({
            user_logged_in: 'auth/user_logged_in'
        })

    },
    methods: {
        async joinGame() {
            console.log("ConfirmationPage.joinGame()", this.game.id, this.$store.getters["user/id"])
            if (!this.user_logged_in) {
                console.log("User not logged in")
                this.$store.dispatch("auth/login", {game_redirect_id: this.game.id});
            }
            else if (this.game.id.charAt(0) == "-") {
                console.log("Joining game")
                this.$store.dispatch('context/joinGame', {game: this.game})
            } else {
                // var tags = this.$store.getters['webHQ/getActiveTab'] ? [this.$store.getters['webHQ/getActiveTab'].id] : []
                await this.$store.dispatch("context/quickStartInvestorDemo", {playlist: this.game, track_count: 8, category: this.game.category})
            }

        },
        countDownTimer() {
            if(this.countdown > 0) {
                setTimeout(() => {
                    this.countdown -= 1
                    this.countDownTimer()
                }, 1000)
            } else {
                console.log("NO COUNTDOWN TIMER", this.countdown)
            }
        },
      
        unselectItem() {
            this.$emit('unselectItem');
        },
       ...mapActions({
            loadGame: 'context/loadGame'
        })
    },
}



</script>

<style lang="scss" scoped >
// .section {
//     padding-top: 1.5rem;
//       color: #E8E8E8;
//     background-color: #1f1f1f;
//     width: 100vw;
//     height: 100vh;
// }

.game_image {
    max-width: 83%;
    
}
.creator_label {
font-size: 14px;
line-height: 17px;
}
.game_name_label {
font-weight: 600;
font-size: 2rem;
line-height: 36px;
}
.popularity_label {
font-weight: 600;
font-size: 1rem;
line-height: 17px;
}
.clock_icon, .time_left_label {
    display: inline;
}
strong {
    color: white;
}
.genre_tag {
    background: #4b83d840;
    font-size: 0.75rem;
    margin-right: 8px;
    border-radius: 20px;
    padding: 0.25rem 1rem;
    text-align: center;
    border: 1px solid #FFFFFF;
}
.time_left {
    //border: 1px white solid;
     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #2C2929;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 8px;
    width: 600px;
    align-items: center;
    display: flex;
}
.countdown_timer {
    font-weight: 600;
}
.game_info {
    margin-top: 11px;
    display: flex;
    justify-content: flex-start;
    width: 600px;
    //border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #2C2929;
    gap: 9.5%;
    padding-top: 1rem;
    padding-bottom: 19px;
    padding-left: 12px;
    &__row {
        display: flex;
        justify-content: space-between;
        padding-right: 66px;
        margin-bottom: 20px;
    }
    &__description {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: flex-end;
        color: rgba(255, 255, 255, 0.68);
    }
    &__data {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: flex-end;
        color: #FFFFFF;
    }
}

.green_gradient {
    background: linear-gradient(90deg, #CAFF34 0%, #9BFF7B 104.09%), #FFFFFF;
    border-radius: 20px;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    width: 257px;
    height: 53px;
    line-height: 19px;
    margin-top: 2.5rem;
    border: 0;
}
.green_gradient:hover {
    cursor: pointer;
}
.disclaimer {
    margin-top: 1rem;
}
.disclaimer_text {
    font-size: 12px;
    line-height: 14px;
    color: white;
    opacity: 0.75;
}
.back_btn {
    max-width: 40px;
    height: auto;
}
.back_btn:hover {
    cursor: pointer;
}

@media screen and (max-width: 479px) {
  .game_name_label {
      margin-left: 25px;
  }
  .image {
    min-width: 100%;
    height: 350px;
    object-fit: cover;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 12px;
    display: block;
    overflow: hidden;
  }
  .game_info {
      flex-direction: column;
      width: 93%;
      margin: 0 auto;
      padding-left: 12px;
      &__header {
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        color: #FFF;
        margin-bottom: 20px;
      }
  }
  .green_gradient {
      margin: 0 auto;
      display: block;
      margin-top: 13px;
  }
}
</style>

