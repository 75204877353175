<template>
    <div>

        <div class="item">
            <div class="item__left">
                <!-- <div class="pfp_container">
                    <img class='pfp' :src="pfp" alt="">
                </div> -->
                <p v-if="header">LEADERBOARD</p>
                <p v-else class="username">mcc</p>
            </div>
            <div v-if="header" class="item__right">
                <span class="header__album">🥇<img class="header__album" :src="albumImageForTrack(tracks[0])" alt=""></span>
                <span class="header__album">🥈<img class="header__album" :src="albumImageForTrack(tracks[1])" alt="" /></span>
                <span class="header__album">🥉<img class="header__album" :src="albumImageForTrack(tracks[2])" alt="" /></span>
            </div>
            <div v-else class="item__right">
                <p class="vote-count" v-for="track in tracks" :key="track.item.id"> {{usersVoteForTrack(track)}}</p>
            </div>
            <!-- <p class="tokens" v-if="true"><span>{{rewardStr}}  </span>
                <span class="coins">
                    <GrayCoin />
                </span> 
            </p> -->
        </div>
    </div>
</template>
<script>
import { db } from '@/store/modules/firebase'
import GrayCoin from '../../SVGs/GrayCoin.vue'

var user = db.ref('users')
export default {
    name: "Item",
    props: ["user_id", "_reward", "fee", "tracks", "header", "ordered_tracks_all_users", "ordered_tracks_current_users"],
    data() {
        return {
            user: null
        };
    },
    mounted() {
        this.$bind("user", user.child(`${this.user_id}`));
    },
    computed: {
        username() { return this.user ? `${this.user.username}` : ""; },
        pfp() { return this.user ? this.user.image : ""; },
        reward() { return Math.floor(this._reward); },
        rewardDiff() { return this.reward - this.fee; },
        rewardStr() { return this.rewardDiff > 0 ? `+ ${this.rewardDiff}` : `- ${Math.abs(this.rewardDiff)}`; }
    },
    methods: {
        usersVoteForTrack(track) {
            return track && track.voters && Object.keys(track.voters).includes(this.user_id) ? Object.keys(track.voters[this.user_id]).length : 0
        },
        albumImageForTrack(track) {
            return track && track.item ? track.item.album.images[0].url : ""
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    font-family: 'Futura';
    display: flex;
    justify-content: space-between;
    &__album {
        display: flex;
        align-items: center;
        img {
            width: 1.4vw;
            height: 1.4vw;

        }
    }
}
.item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-bottom: 6.5%;
    &__left {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 33.5%;
        gap: 12px;
    }
    &__right {
        display: flex;
        width: 40%;
        align-items: center;
        justify-content: space-between;
    }
}
.username {
    font-size: 16px;
    line-height: 19px;
    color: #E0E0E0;
    font-weight: 300;
}
.vote-count {
    width: 25.3%;
    text-align: right;
}
// .pfp_container {
//     height: 100%;
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }
// .pfp {
//     border-radius: 50%;
//     height: auto;
//     width: 39px;
// }
// .tokens {
    
//     font-size: 16px;
//     line-height: 19px;   
//     color: #E0E0E0;
//     font-weight: 600; 
//     display: flex;
//     align-items: center;
//     gap: 3px;
// }
// svg {
//     color: #E0E0E0;
// }
</style>