<template>
    <div class="genreCard" @click="select()" >
        <p class="genreCard__name">{{genre.name}}</p>
    </div>
</template>
<script>


export default {
    name: "GenreCard",
    props: ["genre", "isActive"],
    data() {
        return {
        }
    },
    methods: {
        select() {
            console.log("select", this.genre)
            this.$emit("selectCategory", this.genre)
        }
    },
    computed: {
        name() { return this.genre ? this.genre.name : ""}
    },
    mounted() {
//        this.$bind("genre", this.$store.getters['firebase/categories_ref'].child(this.genre_id))
    },
}
</script>
<style lang="scss" scoped>
.genreCard {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 8px;
    width: 70vw;

    // height: 20vh;
    &__name {
        font-family: 'Rubik-Medium';
        border-radius: 8px;
        background: rgba(37, 37, 37, 0.5);
        padding: 0.75rem 16px;
        width: 100%;
        text-align: left;
        color: white;
        font-weight: 500;
        font-size: 20px;
    }   
   
}
.genreCard:hover {
    cursor: pointer;
}
.is-active {
    border: 2px solid white;
}
</style>