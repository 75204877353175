import { db } from './firebase'

// initial state
const state = () => ({
    nextVoteDelay: 500,
    defaultDaysOpen: 1,
})


/*
    What is a preview vs game?
        - previews don't save the results of the vote
        - previews are private
        - previews don't require a fee
        - previews don't have an expire date

*/

const actions = {

    async createGame(context, { game_details }) {
        
    },
    /**
     * Summary: Creates a game from a spotify playlist
     * 
     * Description: 
     *      1. Fetches tracks in the playlists - Assumes no tracks given
     *      2. Uses track to create initial polls 
     *      3. Adds polls & tracks to playlist
     *      4. Gives default Game metadata
     *              - Name is playlist name
     *              - Fee is 5000 tokens
     *              - ID is randomly generated (for playlist reusability)
     *              - Creator is set to the current user
     *              - Image is the playlist's image 
     *      5. Adds playlist to database
     * 
     * @param  {[Object]} playlist The playlist
     * @param  {[Array]} tags the keywords affiliated with playlist
     * 
     */

    async createGameUsingDefaultSettings(context, { playlist, tags, songs_count }) {
        console.log("createGameUsingDefaultSettings", tags)
        const { tracks, polls } = await getTracksAndPollsForGame(context, playlist.id, songs_count)
        const tags_obj = Array.isArray(tags) ? tagsObject(tags) : tags
        console.log("tags as an object", tags_obj, Array.isArray(tags))
        const newGameRef = context.rootGetters["firebase/games_ref"].push()
        const newGameID = newGameRef.key;
        const FEE = 5000
        var new_date = new Date(new Date().setHours(24, 0))
        // Set expire date to midnight of the next day
        const expire_date = new_date.setDate(new_date.getDate() + context.state.defaultDaysOpen)
        console.log("Expire Date", expire_date, context.state.defaultDaysOpen)
        // Set game with metadata
        const image = playlist.image || playlist.images[0].url
        var game = {
            id: newGameID,
            playlist_id: playlist.id,
            name: playlist.name,
            image: image,
            tags: tags_obj,
            fee: FEE,
            expire_date: expire_date,
            created_by: "SnipitFM",// context.rootGetters["user/id"], 
            tokens: 0,
        }
        // Standardized methods for adding polls and tracks to a game (as objects)

        context.dispatch('addTracksToGame', { tracks: tracks, game: game })
        var resetGamePolls = true
        context.dispatch("addPollsToGame", { polls, game, resetGamePolls })
        newGameRef.update(game)
        console.log("Returning GANE FUCK CHRIST", game)
        return game
    },

    async createGameInvestorDemo(context, { playlist, songs_count }) {
        console.log("game/createGameInvestorDemo", playlist)
        const { tracks, polls } = await getTracksAndPollsForInvestorDemo(context, playlist.tracks, songs_count)
        const newGameRef = context.rootGetters["firebase/games_ref"].push()
        const newGameID = newGameRef.key;
        const FEE = 5000
        const image = playlist.image || playlist.images[0].url
        // get date in milliseconds since 1970
        var start_date = new Date().getTime()
        //set expire date 30 minutes from start date
        var expire_date = new Date(start_date + 30 * 60000)
        var game = {
            id: newGameID,
            playlist_id: playlist.id,
            name: playlist.name,
            image: image,
            fee: FEE,
            start_date: start_date,
            expire_date: expire_date,
            category: playlist.category,
            created_by: "SnipitFM",
            tokens: 0,
        }
        // Standardized methods for adding polls and tracks to a game (as objects)
        console.log("game/createGameInvestorDemo", tracks, polls, game)
        context.dispatch('addTracksToGame', { tracks: tracks, game: game })
        var resetGamePolls = true
        context.dispatch("addPollsToGame", { polls, game, resetGamePolls })
        context.dispatch('firebase/newGame', { game }, { root: true })
        return game
    },

    addTracksToGame(context, { tracks, game }) {
        console.log("game.addTracksToGame()", tracks, game)
       // var tracks_obj = Object.assign(...tracks.map(track => ({ [track.id]: { "item": track } }))) 
       // convert tracks to object where key is track id and value is true
         var tracks_obj = Object.assign(...tracks.map(track => ({ [track]: true })))
        game.tracks = tracks//_obj
        return game
    },
    // createPollsFromTracks(context, {tracks, poll_index, shuffle}) {
    //     console.log("game.createPollsFromTracks()", tracks, poll_index)
    //     var start = poll_index
    //     var polls = {}

    //     if (tracks?.length >= 1) {
    //         tracks = shuffle ? shuffleArray(tracks) : tracks
    //         var i = 0
    //         while (i < tracks.length - 1) {
    //             if (tracks[i] && tracks[i + 1]) {
    //                 var id = `${tracks[i].id},${tracks[i + 1].id}`
    //                 polls[id] = poll_index
    //                 poll_index += 1
    //             }
    //             i += 2
    //         }
    //     }
    //     console.log(`game.createPollsFromTracks() called - ${Object.keys(polls).length} new polls. indices ${start} to ${poll_index}`)
    //     return polls
    // },
    createPollsFromTracks(context, {tracks, poll_index, shuffle}) {
        if (!tracks || tracks.length < 1) { return null }
        var polls = {}
        tracks = shuffle ? shuffleArray(tracks) : tracks

        for (let i = 0; i < tracks.length - 1; i += 2) {
            if (tracks[i] && tracks[i + 1]) {
                var track1ID = tracks[i].id || tracks[i]
                var track2ID = tracks[i + 1].id || tracks[i + 1]
                var id = `${track1ID},${track2ID}`
                polls[id] = poll_index
                poll_index += 1
            }
        }
        console.log(`game.createPollsFromTracks() called`, polls,tracks)
        return polls
    },
    addPollsToGame(context, { polls, game, resetGamePolls }) {
        console.log(`game.addPollsToGame(${Object.keys(polls).length}) called`, polls, game, resetGamePolls)
        game.polls = (game.polls == null || resetGamePolls) ? polls : { ...game.polls, ...polls }
    },
    /**
     * Summary: Registers the user's vote with the backend
     * 
     * Description: 
     *      1. Create a vote object with the following properties: the track id, voter id, vote timestamp, poll id
     *      2. Track is added to VOTE QUEUE, which is used to generate the next round of polls
     *      3. We add the song to the user's library @TODO
     *      4. We update the user's daily voting streak, for engagement purposes.
     *      5. Adds playlist to database
     * 
     * @param  {[Object]} song The song
     * @param  {[Array]} tags the keywords affiliated with playlist
     * 
     */

    async upvote(context, { song, poll }) {
        console.log("Game.upvote() called for poll", context.rootGetters['context/cPoll_id']);
        // Define the vote's metadata - used for vote queue
        let vote = {
            item: song,
            voted_by: context.rootGetters["user/id"],
            voted_at: new Date().valueOf(),
            poll: poll
        }
        //Add song to vote queue
        addSongToVoteQueue(context, vote)
        // We track user's daily voting streak for engagement purposes
        updateUserVotingStreak(context.rootGetters["user/id"], vote.voted_at, context.rootGetters["user/last_voted"], context.rootGetters["user/streak"])
        const game_id = 
        await context.dispatch('firebase/newVote', {
            poll_id: context.rootGetters['context/cPoll_id'],
            song_id: song.id,
            user_id: context.rootGetters["user/id"],
            game_id: context.rootGetters["context/cGame_id"]
        }, { root: true })
    }


}
async function getTracksAndPollsForInvestorDemo(vm, playlist_tracks, songs_count) {
    var p_tracks = playlist_tracks.items || playlist_tracks
    var all_tracks = Object.keys(p_tracks).map(key => p_tracks[key].track || p_tracks[key] == true ? key : p_tracks[key])
    var tracks = selectRandomTracks(all_tracks, songs_count)
    console.log("getTracksAndPollsForInvestorDemo", playlist_tracks, p_tracks, all_tracks, tracks, songs_count)
    if (tracks.length < songs_count) { return {id: "NotEnoughTracksValid"} }

    var polls2 = await vm.dispatch('createPollsFromTracks', { tracks: tracks, poll_index: 0 })
    const polls =  polls2  //There seems to be a bug when this line is absent. With this line, context/quickstart gets null object returned when calling this function
    return {tracks, polls}
}

async function getTracksAndPollsForGame(vm, playlist_id, songs_count) {
    var tracks = await getTracksFromSpotifyPlaylist(vm, playlist_id)
    if (tracks.length < songs_count) {
        console.log("Failed to get enough songs from playlist", tracks)
        return {id: "FailedToGetTracksFromSpotifyPlaylist" }
    }
    var { valid_tracks } = filterTracksForPreviewURL(tracks, songs_count) // tracks only with previews
    if (valid_tracks.length != songs_count) {
        console.log("Not Enough Tracks for a game - ", valid_tracks.length, valid_tracks)
        return {id: "NotEnoughTracks"}
    }
    var polls2 = await vm.dispatch('createPollsFromTracks', { tracks: valid_tracks, poll_index: 0, shuffle: false })
    const polls =  polls2  //There seems to be a bug when this line is absent. With this line, context/quickstart gets null object returned when calling this function
    console.log("Polls found: ", polls, valid_tracks)
    return {tracks: valid_tracks, polls: polls}
}


function tagsObject(tags) {
    console.log("tagsObject", tags)
    return tags ? tags.reduce((acc, curr) => (acc[curr] = true, acc), {}) : {}
}

async function getTracksFromSpotifyPlaylist(vm, playlist_id) {
    return await vm.dispatch("spotify/fetchTracksFromPlaylist", { playlist_id: playlist_id }, { root: true })
}


// function filterTracksForPreviewURL(tracks, tracks_limit) { 
//     if (tracks?.length > 0)  {
//         var valid_tracks = []
//         var error_tracks = []
//         var i = 0
//         console.log("TRACKS FOUND", tracks.length)
//         while (i <= tracks.length - 1 && valid_tracks.length < tracks_limit) {
//             var track = tracks[i]
//             track.preview_url != null ? valid_tracks.push(track) : error_tracks.push(track)
//             i += 1
//         }
//         console.log("VALID TRACKS", valid_tracks, error_tracks)
    
//     } else {
//         console.log("no tracks found")
//     }
//     return { valid_tracks, error_tracks }
// }

function filterTracksForPreviewURL(tracks) {
    const valid_tracks = [], error_tracks = [];

    tracks.forEach(track =>
        (track.preview_url ? valid_tracks : error_tracks).push(track)
        );
    console.log("filterTracksForPreviewURL", tracks, valid_tracks, error_tracks)
    return { valid_tracks, error_tracks };
}
function selectRandomTracks(tracks, num) {
    //var {valid_tracks} = filterTracksForPreviewURL(tracks)
    var valid_tracks = tracks
    const shuffledTracks = valid_tracks.sort(() => 0.5 - Math.random());
    console.log("selectRandomTracks", tracks, valid_tracks, shuffledTracks, num)
    return shuffledTracks.slice(0, num);
  }
function shuffleArray(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}
function addSongToVoteQueue(context, vote) {
    console.log("addSongToVoteQueue called", vote, context.rootGetters["context/vote_queue"])
    //Add song to vote queue
    //Immediately update the database with the vote queue - the queue should always have 1 item
    context.dispatch('firebase/updateVoteQueue', { vote: vote }, { root: true })
}

// We keep track of the user's daily vote streak for engagement purposes.
function updateUserVotingStreak(user_id, new_vote_date, last_voted_date, current_streak) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to bottom number to deal with DST.
    var diff_dates = Math.round((new_vote_date - last_voted_date) / (1000 * 60 * 60 * 24))
    var streak = (current_streak != null && diff_dates <= 1) ? current_streak + 1 : 1
    db.ref(`users/${user_id}`).update({ streak: streak })
    db.ref(`users/${user_id}`).update({ last_vote: new_vote_date })
}


export default {
    namespaced: true,
    state,
    actions,
}