<template>
  <section class="container">
      <div class="header">
        <h1 class="header__title">Select a Profile Picture</h1>
        <photo-upload />
        <p class="header__subtitle">Imported from our Butthole</p>
      </div>

      <div class="body">
        <ul class='errors mt-3'>
            <li class="error-label" v-for="(val, key) in errors" :key="key"> {{val}}</li>
        </ul>
        <div class="form">
            <p class="green-text">username</p>
            <input type="text"  ref="" autocomplete="off" placeholder="Reserve a username" class="inp mb-4" v-model="username" name="username">
            <!-- <p class="green-text">wallet address</p>
            <input type="text"  ref="" autocomplete="off" disabled :placeholder="addressPlaceholder" class="inp mb-4" v-model="address" name="username"> -->
            <p class="green-text">instagram handle</p>
            <input type="text"  ref="" autocomplete="off" placeholder="Enter your invite code" class="inp" v-model="passcode" name="passcode">
            <p class="mt-3">Your Wallet Address: 0xfdiunf93u3483h4trnkr</p>
        </div>
        <button class="green_gradient" @click="createAccount()">Continue</button>
      </div>
    </section>

</template>

<script>
import { db } from '@/store/modules/firebase'
import PhotoUpload from '@/components/AccountCreation/PhotoUpload.vue'
export default {
    name: "AccountCreationModal",
    data() {
      return {
        email: '',
        username: '',
        passcode: '',
        game: null,
        categories: [],
        new_user: null,
        userProfileData: {},
        loading_icon_visible: false,
        errors: { 'email': "", 'username': "", 'passcode': "" }
      }
    },
    computed: {
        addressPlaceholder() {
            return `${this.username}.snipit.fm`
        },
        address() { return `${this.username}.snipit.fm` },

    },
    components: {
        PhotoUpload
    },
    methods: {
        async createAccount() {
            var validEmail = this.validateEmail()
            return await this.validatePasscode().then(async (validPasscode) => {
                return await this.validateUsername().then(async (validUsername) => {
                    if (validEmail && validPasscode && validUsername) {
                        console.log("LOGIN SUCCESS")
                        this.new_user = true
                        this.goToGameBrowser()
                    } else {
                        console.log("LOGIN FAIL")
                    }
                })
            })
      },
      validateEmail() {
        this.email = this.email.replaceAll(' ', '').trim()
          var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          //eslint-disable-next-line
            if (!re.test(this.email)) {
              console.log("INVALID EMAIL")
              this.errors['email'] = 'Please enter a valid email address'
              return false
            } else {
              console.log("VALID EMAIL")
              this.errors['email'] = ""
              return true
            }
          
      },
      async validatePasscode() {
        this.passcode = this.passcode.replaceAll(' ', '').trim()
          if (this.passcode.length >= 3)  {
            this.passcode = this.passcode.toUpperCase()
            const snapshot = await db.ref('allowed/' + this.passcode).once('value', snapshot => {
                this.errors['passcode'] = snapshot.exists() ? '' : 'It appears the passcode you have entered is not valid.'         
                return snapshot.exists()
            })
            return snapshot.exists()
          }
          else { return false } 
      },
      async validateUsername() {
        this.username = this.username.replaceAll(' ', '').trim()
        //var re = /^[ A-Za-z0-9_@./#&+-]*$/
        var forbidden = [".", "#", "$", "[", "]"]
        var i = 0
        var test = true
        while (i <= forbidden.length - 1 && test) {
          if (this.username.includes(forbidden[i])) {
            test = false
          }
          i += 1
        }
        if (this.username.length >= 3 && this.username.length <= 36 && test ) {// && re.test(this.username))  {
          const snapshot = await db.ref('usernames/' + this.username).once('value', snapshot => {
              this.errors['username'] = snapshot.exists() ? 'It appears that username is already taken.' : ''
              return !snapshot.exists()
          })
          return !snapshot.exists()
        }
        else { 
          console.log("regex fail")
           this.errors['username'] = 'Please make sure your username is at least 3 characters, less than 36 characters, and contains no special characters' 

          return false } 
      },
    }
}
</script>

<style lang="scss" scoped>
section {
    height: 100%;
    color: #E8E8E8;
    background-color: #1f1f1f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;


  &__title {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: white;
  }
  &__subtitle {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #D9D9D9;
  }

}
.green-text {
  background: linear-gradient(90deg, #CAFF34 3.26%, #9BFF7B 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
}
.orange-text {
  background-image: linear-gradient(
    to right,

    #fd1d1d 73.26%, /* Pink */
    #fcb045 30% /* Orange/Yellow */
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
}
.form {
  width: 40vw;
  text-align: left;
}
.inp {
  display: block;
  background: transparent;
  border:none;
  border: 1px solid #FFFFFF;
  padding: 8px;
  outline: none;
  width: 100%;
  color: rgba(255, 255, 255, 0.67);
  font-size: 17px;

 }
.inp::placeholder {
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.47);
}

[placeholder]:focus::-webkit-input-placeholder {
    transition: text-indent 0.4s 0.4s ease; 
    text-indent: -100%;
    opacity: 1;
    font-size: 17px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.47);
 }


button {

  background: linear-gradient(90.15deg, #CAFF34 0.18%, #9BFF7B 99.94%);
  border-radius: 8px;
  width: 218.09px;
  height: 47px;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  border: 0;
  margin-top: 2.5rem;
}
button:hover {
  cursor: pointer;
}
.error-label {
  color: red;
  font-size: 14px;

}
.errors {
  text-align: left;
    margin-bottom: 2.5rem;

}
</style>