<template>
    <div class="modal" v-show="show">
      <div class="modal__backdrop" />
        <div class="modal__wrapper" @click.prevent.self="closeModal()">
          <div class="modal__frame">
            <div class="modal__dialog">
              <div class="modal__header">
                <p>Who would you like to listen to?</p>
                <p>Suggested artists</p>
              </div>
              <div class="modal__body">
                <div class="artists-container">
                  <artist-card v-for="artist in suggested_artists" :key="artist.id" :artist="artist" class="artist-card" @selectArtist="selectArtist" />
                </div>
                <search class="white-search-bar" @start="start_search" :PLACEHOLDER="'SEARCH FOR ARTIST'" :query="query"/>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import '@/assets/css/modals.scss';
import Search from '@/components/Modals/Playlists/Search.vue';
import api from '@/api';
import { db } from '@/store/modules/firebase'
import ArtistCard from '@/components/Modals/Search/ArtistCard.vue'
const spotifyApi = api.spotify
export default {
  name: "SearchModal",
  components: {
    Search,
    ArtistCard
  },
  data() {
    return {
        isSearching: false,
        isLoading: false,
        hasError: false,
        errorMessage: '',
        isLoaded: false,
        lastUpdated: '',
        favorites: null,
        selectedArtists: null,
        query: '',
        selectedArtist: null

    };
  },

  async mounted() {
    this.updateUserFavorites()
  },

  computed: {
    suggested_artists() {
      return this.favorites && this.favorites.longTermArtists ? this.favorites.longTermArtists?.filter((item) => item.name ? item.name.length <= 10 : false).slice(0,5) : []  
    },
    show: {
        get() {
            return this.$store.getters['modals/SEARCH_MODAL_SHOW']
        },
        set(value) {
            if (value) {
                this.$store.dispatch('modals/SET_SEARCH_MODAL_SHOW')
            } else {
                this.$store.dispatch('modals/HIDE_SEARCH_MODAL')
            }
        }
    }
  },
  firebase: {


  },
 
  methods: {
    async updateUserFavorites() {
      console.log("updateUserFavorites")
      // Get the user's favorite artists and tracks for short-term, medium-term, and long-term
      const shortTermArtists = await spotifyApi.personalization.getMyTopArtists({ time_range: 'short_term' });
      const mediumTermArtists = await spotifyApi.personalization.getMyTopArtists({ time_range: 'medium_term' });
      const longTermArtists = await spotifyApi.personalization.getMyTopArtists({ time_range: 'long_term' });
      const shortTermTracks = await spotifyApi.personalization.getMyTopTracks({ time_range: 'short_term' });
      const mediumTermTracks = await spotifyApi.personalization.getMyTopTracks({ time_range: 'medium_term' });
      const longTermTracks = await spotifyApi.personalization.getMyTopTracks({ time_range: 'long_term' });

      // Update the user's favorites in Firebase Realtime Database
//      const userFavoritesRef = db.ref(`/user_favorites/${this.user.id}`);
      this.favorites = {
        shortTermArtists,
        mediumTermArtists,
        longTermArtists,
        shortTermTracks,
        mediumTermTracks,
        longTermTracks,
        lastUpdated: Date.now(),
      }
      // for each value in this.favorites, set the value to the value.data.items
      this.favorites.shortTermArtists = this.favorites.shortTermArtists.data.items
      this.favorites.mediumTermArtists = this.favorites.mediumTermArtists.data.items
      this.favorites.longTermArtists = this.favorites.longTermArtists.data.items
      this.favorites.shortTermTracks = this.favorites.shortTermTracks.data.items
      this.favorites.mediumTermTracks = this.favorites.mediumTermTracks.data.items
      this.favorites.longTermTracks = this.favorites.longTermTracks.data.items
      

      console.log("FAVORITES THIS s", this.favorites)
      // await userFavoritesRef.set({
      //   shortTermArtists,
      //   mediumTermArtists,
      //   longTermArtists,
      //   shortTermTracks,
      //   mediumTermTracks,
      //   longTermTracks,
      //   lastUpdated: Date.now(),
      // });
    },
    selectArtist(artist) {
      console.log("Modal.selectArtist", artist)
      this.$store.commit('spotify/setQuery', artist.name)
    },

   async start_search(query) {
      console.log("Modal.start_search", query)
      if (this.isSearching) return
      this.isSearching = true
      setTimeout( async () => {
        var game = await this.$store.dispatch('gameCreation/createGameFromSearchForArtist', {query: query, query_type: "artist"})
        console.log("Modal.start_search", game)
        this.$store.dispatch('context/joinGame', {game: game})
        this.isSearching = false
        this.closeModal()
      }, 450);
    },
    async search() {
        console.log(`SearchModal.start() called`, this.query)
        //this.showLoadingIcon() 
        var response = await api.spotify.search.search(this.query, "artist")
        return response.data.artists?.items[0]
    },
    closeModal() {
      this.$store.dispatch('modals/HIDE_SEARCH_MODAL')
    },
    showLoadingIcon() {
        Vue.set(this,"searchStatus", 1)
    },
    errorLoadingIcon() {
        Vue.set(this,"searchStatus", 0)
    },
    hideLoadingIcon() {
        Vue.set(this,"searchStatus", 0)
    },


  }
};
</script>


<style lang="scss" scoped>
.modal {
    display: block;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 32px;
        width: 66%;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 1.5rem;
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        width: 66%;
        height: 100%;
        text-align: left;
    }
    &__frame {
      height: 36vh;
    }
 
}
.white-search-bar {
  background: rgb(253, 247, 247);
  color: #1f1f1f;
  border-radius: 16px;
}
.artists-container {
  display: flex;
  gap: 1.6vw;
  margin-bottom: 7.3%
}
.artist-card {
  width: 10.5vw;
  height: 3.8vh;
}
.artist-card:hover {
  cursor: pointer;
}
</style>

