import api from '@/api';
import { db } from './firebase'
import { firebaseAction } from 'vuexfire'
import { getCreate2Address } from 'ethers/lib/utils';


const state = {
    _profile: null,
    _votes: {},
    _favorites_playlist: {},
    _game_history: {},
//    _active_games: {},
    _user_progress: null
};
const getters = {
    id: state => state._profile?.spotify_id || "",
    name: state => state._profile?.display_name || "",
    image: state => state._profile?.image || "",
    premium: state => state._profile?.premium,
    music_categories: state => state._profile?.categories,
    wallet: state => state._profile?.wallet,
    balance: (state, getters) => getters.wallet?.balance?.balance,
    profile: state => state._profile,
    profile_picture: state => state._profile?.image,
    isAuthorized: (state, getters, rootState, rootGetters) => { return rootGetters['auth/getAccessToken'] != null },
    favorites_playlist_ref: (state, getters) => { return db.ref(`user_favorites_playlists/${getters.id}`) },
    favorites_playlist_root: state => state._favorites_playlist,
    favorites_playlist_id: state => state._favorites_playlist.id,
    favorites_playlist_tracks: state => state._favorites_playlist.tracks || {},
    active_games: (state, getters) => getters.user_progress?.active_games  || {},
    user_progress: state => state._user_progress || null,
    user_logged_in: (state, getters) => getters.profile && getters.isAuthorized
}

const actions = {

    async SET_PROFILE(context, payload) {
        console.log("SET_PROFILE", payload)
        const user_id = payload.user_id
        await context.dispatch("bindCurrentUser", {user_id: user_id})
        await context.dispatch("bindFavoritesPlaylist")
    },

    async saveUsername(context, {name}) {
        console.log("user/saveUsername", name)
        context.getters['firebase/currentUserRef'].update({display_name: name})
    },
    async fetchUserSpotifyProfile(context) { 
        console.log("fetchUserSpotifyProfile")
        try {
            const response = await api.spotify.users.getUserProfile();
            console.log("fetchUserSpotifyProfile response", response, context.rootGetters['auth/getAccessToken'])
            if (!response) { 
                console.log("no response from spotify.getUserProfile")
                return
            } else {
                const spotify_profile = response.data, user_id = spotify_profile.id
                console.log("spotify_profile", user_id, spotify_profile)
                if (user_id == null) { return }
                await context.dispatch("spotify/findOrCreateFavoritesPlaylist", user_id, {root: true})
                const snapshot = await db.ref(`users/${user_id}`).once("value")

                if (!snapshot.exists()) {
                    console.log("user does not exist in db, creating")
                    await context.dispatch('createUserProfileFromSpotifyAndWyre', {profile: spotify_profile, email: spotify_profile.email, username: spotify_profile.display_name})
                }
                console.log("user exists in db, setting profile", snapshot.val())
                context.dispatch('SET_PROFILE', { user_id: user_id})
            }
        }
        catch(e) {
            console.log("error: ", e)
        }
    },
    async createWallet(context) {
        var wallet = await context.dispatch('wyre/makeWallet', {id: `pizzaHope`}, {root: true})

        context.rootGetters['firebase/currentUserRef'].update({wallet: wallet.data})
    },
    async getWalletBalance(context) {
        var wallet = context.getters.wallet
        if (wallet != null) {  
            wallet = wallet.message
            const id = wallet.id
            console.log("user/getWalletBalance", wallet, id)
            var balance = await context.dispatch('wyre/getWalletBalance', {id: id}, {root: true})
            console.log("2 user/getWalletbalance() RESULT", balance)
            context.rootGetters['firebase/currentUserRef'].child('wallet').update({balance: balance.data})
        }
        else {
            console.log("user/getWalletBalance: no wallet found", context.getters.profile)
        }
    },
    async createUserProfileFromSpotifyAndWyre(context, payload) {
        const profile = payload.profile, email = payload.email, username = payload.username
        console.log("user.createUserProfileFromSpotifyData() 222called: ", profile, email, username);
        var user_profile = {
            spotify_id: profile.id,
            display_name: profile.display_name,
            country: profile.country,
            email: profile.email,
            followers: profile.followers?.total,
            image: profile.images?.length > 0 ? profile.images[0]?.url : "",
            premium: profile.product == "premium",
            tokens: 10000, //initial tokens for creating an account
        }   
        await db.ref(`users/${profile.id}`).update(user_profile)
        db.ref(`usernames`).update({ [username]: profile.id })
        return user_profile
    },

    async ADD_TOKENS_TO_CURRENT_USER(context, { newTokensCount }) {
        await context.dispatch("addTokensToCurrentUser", { userID: context.getters.id, newTokensCount: newTokensCount })
    },
    addTokensToCurrentUser: firebaseAction(({ state }, { userID, newTokensCount }) => {

        var totalTokensAmt = state._profile.tokens ? state._profile.tokens + newTokensCount : newTokensCount
        console.log(`Adding ${newTokensCount} to ${userID}`)
        db.ref(`users/${userID}`)
            .update({ tokens: totalTokensAmt })
            .then(() => {
                console.log('AddTokensToCurrentUser() Complete!');
            })
    }),
    bindCurrentUser: firebaseAction((context, {user_id}) => {
        console.log("user.bindCurrentUser() called", user_id);

        var ref = db.ref(`users/${user_id}`)
        return context.bindFirebaseRef('_profile', ref).then(() => {
            console.log("Success binding user, binding user progress", context.getters['id'])
            ref = context.rootGetters['firebase/user_active_games_ref']
            ref = context.rootGetters['firebase/user_progress_ref']
            context.bindFirebaseRef('_user_progress', ref).then(() => {
                console.log("Success binding user progress")
            })
        }).catch(error => {
            console.log("ERROR: ", error)
        })
    }),


    bindFavoritesPlaylist: firebaseAction((context, id) => {
        var ref = id || context.getters.favorites_playlist_ref
        console.log("bindFavoritesPlaylist", ref, context.getters.id, context.getters.favorites_playlist_ref)
       
        return context.bindFirebaseRef('_favorites_playlist', db.ref(`user_favorites_playlists/${context.getters.id}`))
    }),

    bindGameHistory: firebaseAction((context) => {
        return context.bindFirebaseRef('_game_history', context.getters.game_history_ref)
    }),

    async favorite_song(context, song) {
        var userID = context.getters.id

        console.log(`user.favorite_song() called for - ${song.uri}, user: ${userID}`, context.getters.favorites_playlist_id);
        var favorites_playlist_isEmpty = Object.keys(context.getters.favorites_playlist_tracks).length == 0
        if (favorites_playlist_isEmpty || context.getters.favorites_playlist_tracks[song.id] == null) {
            try {
                var uris = [song.uri]
                var response = await api.spotify.playlists.saveTrack(context.getters.favorites_playlist_id, uris)
                console.log("saveTrack response", response)
                return db.ref(`user_favorites_playlists/${userID}/tracks`).child(song.id).set(true).then(() => {
                    console.log(`${song.name} successfully added to User's Spotify Favorites Playlist`)
                })
            } catch (e) {
                console.log(e);
            }

        } else {
            console.log("This song is already in your favorites!")
        }

    },
    async addGameToCompletedGames(context, {game_id}) {
        console.log("user.addGameToCompletedGames() called", game_id)
        var ref = context.rootGetters['firebase/game_progress_ref']//.child(`${game_id}`)
        console.log("ref", ref)
        var snapshot = await ref.once('value')
        var game = snapshot.val()//[`${game_id}`]
        console.log("GAME VAL", game, snapshot)
        game.poll_index = -1 
        game.expire_date = null
        context.rootGetters['firebase/user_completed_games_ref'].update({[game.id]: game})
        // ref.remove()
    
      
    }
};



const module = {
    namespaced: true,
    state,
    actions,
    getters
};

export default module;