<template>
<div class="background" :style="{ backgroundImage: `url(${cover_album})`}" :class="{ 'landscapeMode': $isMobile() && landscape_mode }" > 
  <div class="blur" :class="{ 'blackOverlay': thisSongIsPlaying}">
    <div class="card2 is-vcentered module" :style="cardCSSProps">
        <div >
           <poll-header 
              :track="track"
              @upvote="upvote" />
            <CoverAlbum 
              :img_src="cover_album"
              :track="track"
              :revealMenu="revealMenu"
              @revealMenuUpdated="updateRevealMenu"
              @click.prevent="playIfMobile()"
              :class="{'landscapeCoverAlbumDimensions': landscape_mode, 'desktopCoverAlbumDimensions': !landscape_mode}" />
        </div>
        </div>
    </div>  
</div>
</template>


<script>

import { gameLogic } from "@/mixins";
import { mapGetters, mapState } from "vuex";
import CoverAlbum from "@/components/Poll/PollOption/CoverAlbum.vue"
import PollHeader from './PollHeader.vue';

export default {
  name: 'PollOption',
  props: ['track', 'type', 'spotifyWinner', 'landscape_mode', 'isAutoPlaying', 'index'],
  components: {CoverAlbum, PollHeader },
  mixins: [gameLogic],
  data() {
    return {
      revealMenu: this.$isMobile()//false,

    }
  },
  watch: {
    isAutoPlaying(newVal) {
      console.log("isAutoPlaying commencing", this.name, this.current_song, this.track)
      if (newVal && this.current_song && this.track && !(this.current_song.id == this.track.id)) {
        console.log("ISAUTOPLAYING", this.name, this.thisSongIsPlaying, this.revealMenu)
        this.updateRevealMenu(true)
      }
    },
    revealMenu(newVal) {
      if (newVal) {
        this.$emit('menuRevealed')

      }
      console.log("revealMenu Update", newVal)
    }
  }, 
  computed: {
    game_over:            function() { return this.gameIsOver(this.$store.getters["context/cGame"])},
    name:                 function() { return this.track ? this.track.name : "" },
    artist:               function() { return this.track && this.track.artists ? this.track.artists[0].name : "Shakira Test"},
    cover_album:          function() { return this.track?.album?.images[1].url || "" }, // this.index == 0 ? "https://i.imgur.com/n4HzyFW.jpg" : "https://i.imgur.com/qsQLyFB.jpg" },
    current_poll:         function() { return this.$store.getters['context/cPoll'] || {}},
    // NOTE: MAKE THIS LINK TO THE USER'S NEW PLAYLIST!!! 
    spotify_url:          function() { return this.track ? this.track.external_urls?.spotify : "" },
    poll:                 function() { return this.$store.getters['context/cPoll']},  
    userID:               function() { return this.$store.getters['user/getSpotifyID'] },
    votes_count:          function() { return this.current_poll[`${this.track.id}`] ? Object.keys(this.current_poll[`${this.track.id}`]).length : 0},
    total_votes_count:    function() { return Object.keys(this.current_poll)
                    .map(option => Object.keys(this.current_poll[`${option}`]))
                      .reduce((acc, curVal) => acc.concat(curVal), []).length },
    
    votes_count_percentage: function() { 
      return this.preview_mode 
      ? this.didVoteForThisSong ? 100 : 0
      : this.votes_count == 0 ? 0 : Math.round((this.votes_count / this.total_votes_count) * 100) },
    userHasFavorited:     function() { 

      return this.$store.getters['user/getFavorites'] ? this.$store.getters['user/getFavorites'].tracks[this.track.id] != null : false

    },

    /**
     * THESE COMPUTED PROPERtiES ARE REGARDING SHOWING thE PLAY BUTTON OR PAUSE BUTTON
     * COULD PROBABLY SIMPLIFY
     */
    preview:              function() { return this.track?.preview_url },
    hasSound:             function() { return this.preview != null },
    current_song:         function() { return this.$store.state.audioPlayer.current_song },
    thisSongIsPlaying:    function() { return this.$store.getters["audioPlayer/isPlaying"] && this.current_song.id == this.track.id },
    showPlayButton:       function() { return this.hasSound && !this.thisSongIsPlaying },
    showPauseButton:      function() { return  this.thisSongIsPlaying },
    /*
     * FIN
     */
    showVoteButton:       function() { return (!this.userHasVoted) },

    //userHasVoted:         function() { return JSON.stringify(this.current_poll).indexOf(`"${this.userID}":`) != -1 },
    didVoteForThisSong:   function() { 
      return this.preview_mode 
      ? this.current_poll_winner == this.track.id 
      : this.current_poll[`${this.track.id}`] ? this.$store.getters.current_poll[`${this.track.id}`][`${this.userID}`] : false },

    showThumbsUp:         function() { return (this.userHasVoted && this.didVoteForThisSong) },

    cardCSSProps:         function() { return `{ background-image: url('${this.cover_album}') }`},
     ...mapGetters({
//        show_game_complete_modal: "modals/show_game_complete_modal",
        userHasVoted: 'context/userHasVoted',
        current_poll_winner: 'game_preview/current_poll_winner',
       // landscape_mode: 'webHQ/getLandscapeMode'
     }),
      ...mapState('context', ['preview_mode']),



  },
  methods: {
    playIfMobile() { 
      console.log("playIfMobile()")
      if (this.$isMobile()) {
        this.$store.dispatch('audioPlayer/play', {track: this.track, use_preview: true}) 
      }
        // analytics.logEvent("play", { name: this.track.name, artist: this.artist})
    },

    pause() { this.$store.dispatch('audioPlayer/pause', {song_id: this.track.id}) },    
    async upvote() {
      if (!this.userHasVoted) { this.$emit('upvote', this.track.id) } 
      else { console.log("Sorry, you've already voted.") }
    },
    updateRevealMenu(bool) {
      this.revealMenu = bool
    }
     
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.blur {
  background: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(16px); 
  height: 101%;
	width: 100%;
  display: flex;
  align-items: center;

      transition: all 0.5s;
    -webkit-transition: all 0.5s;
      transition-timing-function: ease;
}

.blackOverlay {
    background: rgba(0, 0, 0, 0.6);
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
      transition-timing-function: ease;
}

.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  color: white;
}

.card2 {
  margin: 0 auto;
}

.song-name {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-top: 20px;
}
.arist {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  opacity: 0.6;
}

h3 {
  margin: 40px 0 0;
}


a {
  color: #42b983;
}


.upvoted-thumbs {
  font-size: 32px;
}



.fa-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
    background: -webkit-linear-gradient(#CAFF34, #8EFF35);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.fa-heart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: -webkit-linear-gradient(#CAFF34, #8EFF35);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.fa-spotify {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: -webkit-linear-gradient(#CAFF34, #8EFF35);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

 


.green-gradient-bg {
    margin-top: 1rem;
    background: linear-gradient(270deg, #9BFF7B 0%, #D9FF7B 100%);
    border-radius: 9px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    /* or 107% */       


    color: #16171B;
}

.cursor:hover {
  cursor: pointer;
}
img {
   margin: 0 auto
 }
 .portraitMode {
  height: 50%;
 }
 .landscapeMode {
  height: 100%;
 }
 .landscapeCoverAlbumDimensions {
    height: 37vh;
    width: 37vh;
 }
 .desktopDimensions {
    width: MAX(39vh, 25vw); //39vh for desktop
    height: MAX(39vh, 25vw);

 }
 @media screen and (max-width: 479px) {
   .router-view {
     height: 100vh;
   }

 }
</style>
