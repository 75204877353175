<template>
    <div>
            <p class="jackpot__size_label">
                    <CoinsIcon :height="28" :width="28" />
                    <span>Jackpot Size</span>
                </p>
                <p class="jackpot__size">{{SIZE}} TOKENS</p>
        </div>
</template>
<script>
import CoinsIcon from '../../SVGs/Coins.vue'
export default {
    components: { CoinsIcon },
    name: "JackpotSizeLabel",
    props: ["size"],
    computed: {
        SIZE() { return this.size || " " }
    }
}
</script>

<style lang="scss" scoped>
.jackpot {
    &__size_label {
        
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        font-size: 18px;
        line-height: 21px;
    }
    &__size {
        font-size: 24px;
        line-height: 28px;
        color: #E0E0E0;
        font-weight: 500;
        margin-top: 1rem;

   
    
    }
}
</style>