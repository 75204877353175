<template>
    <section>

        <div class="spinner-container" v-show="isLoading">
            <img v-if="showMeme" src="@/assets/getInLoserSnipit.png" alt=""> <br/>
            <pulse-loader />
        </div>
        
        <div class="fafo" v-show="!isLoading">
            <div v-if="showInstructions">
                <instructions @close="showBrowser()"/>
            </div>
            <div class="fafo" v-else>
                <browser-header class="header" @tabSet="setTab" :active_tab="tab" :categories="tabs" />
                <!-- <div v-if="$isMobile()" class="hide-scrollbar">
                    <mobile-portrait-game-browser
                        :games="currentTabGames()"
                        :tab="tab"
                        @gameSelected="gameSelected" />
                   </div> -->
                <div>
                    <desktop-game-browser
                        :pageSize="pageSize"
                        :currentPage="currentPage"
                        :showPrevPageBtn="showPrevPageBtn"
                        :showNextPageBtn="showNextPageBtn"
                        :playlists="currentTabGames()"
                        @gameSelected="gameSelected" />
                </div>
            </div>
        </div> 
        <transition name="fade">
            <white-green-modal v-if="show_white_modal" />
        </transition>
    </section>
</template>


<script>
/* eslint-disable vue/no-unused-components */

import { mapGetters } from "vuex";
import DesktopGameBrowser from '@/components/GameBrowser/Desktop.vue'
import PulseLoader from "../components/SVGs/PulseLoader.vue";
import MobilePortraitGameBrowser from '@/components/GameBrowser/MobilePortrait.vue'
import BrowserHeader from '@/components/GameBrowser/Header'
import { gameLogic } from "@/mixins";
import { db } from "../store/modules/firebase";
import WhiteGreenModal from "@/components/Modals/WhiteGreen";
import Instructions from '@/components/GameBrowser/Instructions.vue';
const PAGE_SIZE = 6
const MEME_ODDS = 1//4
export default {
    name: "GameBrowser",
    mixins: [gameLogic],
    
    components: { 
        DesktopGameBrowser, 
        MobilePortraitGameBrowser, 
        Instructions,
        BrowserHeader, 
        WhiteGreenModal,
        PulseLoader
    }, //MobileLandscapeGameBrowser, 
                    
    data() {
        return {
            pageSize: PAGE_SIZE,
            currentPage: 1,
            isLoading: true,
            categories_obj: null,
            games: {},
            showInstructions: false,
        }
    },
    firebase: {
        categories_obj: db.ref('categories'),
        games: db.ref('playlists'),
    },
  
    async mounted() {
        // this.$store.dispatch('webHQ/bindCommunityGames')
        setTimeout(async () => {
            this.isLoading = false
          //  this.$store.dispatch('modals/SHOW_WHITE_MODAL')
            if (this.tabs.length > 0) { this.setTab(this.tabs[0]) }
        }, 2000)
    },
    computed: {
        tabs() { return this.categories_obj ? Object.values(this.categories_obj).filter(category => !category.hidden) : [] },
        tab: {
            get() { return this.$store.getters['webHQ/getActiveTab'] },
            set(val) { if (!this.isLoading) { this.$store.commit("webHQ/SET_ACTIVE_TAB", val) } }
        },
        indexStart() { return (this.currentPage - 1) * this.pageSize },
        indexEnd() { return (this.indexStart + this.pageSize)},
        showPrevPageBtn() { return this.currentPage > 1 },
        showNextPageBtn() {  return (this.indexEnd < this.currentTabGames.length - 1)  }, 
        showMeme() { return Math.floor(Math.random() * MEME_ODDS) == 1; },
        ...mapGetters({
         //   games: 'webHQ/getGamesByGenreId',
            filtered_community_games: 'webHQ/getFilteredAndSortedGamesById',
            show_white_modal: 'modals/whiteModalVisible'
        }),
    },
    methods: {
        currentTabGames() { 
//            console.log("currentTabGames", this.tab.name, this.games, this.games[`${this.tab.name}`])
            return this.tab ? this.games[`${this.tab.name}`] : [] }, 
        gameSelected(game) {
            const game_id = game.game_id || game.id
            this.$router.push({ name: "ConfirmationPage", params: { playlistID: game_id, playlistCategory: game.category } })
        },
        showBrowser() {
            this.showInstructions = false
        },
        setTab(tab) {
            this.tab = tab
        }

    },




}

</script>

<style lang="scss" scoped>
section {
  color: #E8E8E8;
    background-color: #1f1f1f;
    position: relative;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    // padding-top: 3rem;
    
}
div {
    height: 100%;
}
html, body {
	position:relative;
	overflow-x:hidden;
}
.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60%;
}
@media only screen and (min-width: 480px) {
    .header {
        width: 87%;
        margin: 1rem auto;
        margin-bottom: 2rem;
        padding-left: 36px;
        padding-right: 36px;
    }
}
.fafo {
    display: flex;
    flex-direction: column;
}
</style>