import request from './../request.js'

export default {
    getRecommendations(limit = 32, market = "US", seed) {
        const seed_artists = seed.artists?.join(",");
        const seed_genres = seed.genres?.join(",");
        const seed_tracks = seed.tracks?.join(",");
        console.log("api.getRecommendations", seed_genres)
        return request.get("recommendations", {
            params: {
                limit,
                market,
                seed_artists,
                seed_genres,
                seed_tracks,
            }
        });
    },


}