
import request from './../request.js'

export default {
    getMyTopTracks(params) {
        var limit = params.limit || 50
        var range = params.time_range
        console.log("getMyTopTracks", limit, range)
      //  var range = { long_term: "long_term", medium_term: "medium_term", short_term: "short_term" }
        return request.get(`me/top/tracks?limit=${limit}&time_range=${range}`)
    },
    getMyTopArtists(params) {
        var limit = params.limit || 50
        var range = params.time_range
        console.log("getMyTopArtists", limit, range)
     //   var range = { long_term: "long_term", medium_term: "medium_term", short_term: "short_term" }
        return request.get(`me/top/artists?limit=${limit}&time_range=${range}`)
    },
}