<template>
    <div class="game-grid" >
        <button class="lucky" v-if="show_quickstart">  🧨 Choose a Playlist to Start 🚀</button>
        <GameCard v-for="game in games" 
                class="card2"
                :key="game?.id" 
                :game="game"
                :isFromSpotify="true"  
                @click.native="selectItem(game)" 
                @unselectItem="unselectItem()"/>                   
    </div>
</template>


<script>
import GameCard from '@/components/GameBrowser/GameCard/index.vue'
export default {
    name: "MobilePortraitGameBrowser",
    props: ["games", "tab"],
    components: { GameCard },
    data() {
        return {
            show_quickstart: false
        }
    },
    methods: {
        selectItem(item) {
            this.$emit("gameSelected", item)

        },
    }
}

</script>

<style lang="scss" scoped>

.game-grid {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    gap: 5.6vh;
    width: 77vw;
    margin: 0 auto;
}
.card2 {
    height: 9rem;
    z-index: 1;
}
.lucky {
//    background: linear-gradient(90.11deg, #CAFF34 0.13%, #9BFF7B 99.95%);
//    background: #CAFF34;
    width: 100%;
    font-weight: 600;
    border: 0;
    font-size: 14px;
    padding: 8px 8px;
    color: black;
    border-radius: 0.5rem;
    margin-top: 1rem;
}

</style>