<template>
   <div class="poll-window">
      <!-- <TopBar class="topbar" /> -->
      <TopBar :streaming_mode="false" :class="{'topbar-streaming': streaming_mode, 'topbar': !streaming_mode}" />
      <!-- <div class="streaming-frame"></div> -->

      <poll-container   key="playing-poll-container"
                        ref="playingPollRef"
                        :poll_options="poll_options"
                        :isAutoPlaying="isAutoPlaying" 
                        :landscape_mode="landscape_mode"
                        @menuRevealed="menuRevealed" class="poll_container" />
      <transition name="fade">
          <!-- <PlaylistsModal class="explorer" v-show="show_modal" ref="playlistsModal" @closeModal="closeModal" /> -->
         <SearchModal class="explorer" v-show="show_streaming_modal" ref="playlistsModal"/>
      </transition>
      <transition name="fade">
          <!-- <PlaylistsModal class="explorer" v-show="show_modal" ref="playlistsModal" @closeModal="closeModal" /> -->
         <GenreModal class="explorer" v-show="show_genre_modal" ref="genreModal" />
      </transition>
      <!-- <transition name="fade">
        <PlaylistsModal class="explorer" v-show="show_modal" ref="playlistsModal" @closeModal="closeModal" />
         <PollModal class="explorer" ref="pollModal" :tracks="game_tracks" />
      </transition> -->
      <!-- <PortraitModeModal v-if="$isMobile() && !landscape_mode" /> -->
   </div>
</template>

<script>
import PollContainer from '@/components/Poll/'
import TopBar from '@/components/Poll/TopBar'
import { mapGetters, mapActions } from "vuex";
import { gameLogic } from "@/mixins";
import { db } from '@/store/modules/firebase'
import api from '@/api';
import PollModal from '@/components/Modals/Poll/'
import SearchModal from '@/components/Modals/Search/'
import GenreModal from '@/components/Modals/Genres/'


  export default {
    name: "Poll",
    props: [],
    mixins: [gameLogic ],
    data() {
      return {
        isAutoPlaying: false,
        streaming_mode: false,
        game2: null
        }
    },
    components: {
      PollContainer,
      TopBar,
     // PollModal,
   //   PortraitModeModal,
      //PlaylistsModal,
      SearchModal,
      GenreModal

    },
  
    async mounted() {
      console.log("Poll.mounted()");
      this.streaming_mode = this.$route.name == 'Streaming'
      if (!this.gameInRoute) { this.showGenreModal() } 
      else {
        console.log("GAME IN ROUTE")
        var playlist_id = this.$route.params.id
        await this.loadGameFromURLParams()
        console.log("GAME LOADED USER LOADED")
        //this.$store.dispatch("context/goToGame", { game_id: playlist_id })
        if (!this.userVerifiedPlayerInCurrentGame) {
          //this.$router.push({ name: 'ConfirmationPage', params: { id: playlist_id } })
          console.log("User is not verified user in current game - think of fallback")
          //if user is not verified player
          // search game - do nothing or automatically enroll them, these games should be free?
          // if bracket, then enroll them immediately. if they don't have enough tokens - then redirect to buy tokens modal

        } else {
          // if user is verified player then bind to game
          console.log("User is verified player in current game")
            this.$store.commit('context/SET_GAME_ID', {id: playlist_id})
          // this.$bind('game2', db.ref(`games/${playlist_id}`))
          if (this.userHasFinishedAllPolls) {
            console.log("Redirecting to Results page", this.redirectToResultsPage)  
            this.$router.push({ name: "GameComplete", params: {id: playlist_id }})
          } 
        }
      }
        
    
        // await this.loadGameAndUserProfileFromURLParams()
   

      console.log("Streaming mode")
      playlist_id = "-NIxaQX52GvB09GVFAI9"
      this.$bind('game2', db.ref(`games/${playlist_id}`))
      this.$store.commit('context/SET_GAME_ID', { id: playlist_id })
      await this.loadGameFromURLParams()
      setTimeout(() => {
        this.showGenreModal()
      }, 500)
      
    },
    computed: {
      gameInRoute() {
        return this.$route.params.id
      },
      ...mapGetters({
          poll_options: 'context/cPoll_options',
          userVerifiedPlayerInCurrentGame: 'context/userIsPlayerInCurrentGame',
          landscape_mode: 'webHQ/getLandscapeMode',
          show_streaming_modal: 'modals/streamingModalVisible',
          show_genre_modal: 'modals/genreModalVisible',
          game: 'context/cGame',

          userHasFinishedAllPolls: 'context/userHasFinishedAllPolls'

        }),
        game_tracks() {
          return this.game ? this.game.tracks : []
        }
     },
    methods: {
      menuRevealed() {
        console.log("SUCCESS AUTOPLAYING")
        this.isAutoPlaying = false
      },
      setDocumentTitle() {
        if (this.$store.getters['context/cGame']) {
          console.log("Setting document title ", this.$store.getters['context/cGame'].name)
          document.title = `${this.$store.getters['context/cGame'].name}`
        } else {
          console.log( "NO GAME SET")
        }
      },
      async loadGameFromURLParams() {
        console.log("SUP loadGameFromURLParams")
        if (!this.$route.params.id) {
          console.log("No id found in route params")
          return
        }
        this.$store.commit('context/SET_GAME_ID', { id: this.$route.params.id })
        this.loadGame()
      },
      async loadUserProfile() {
        console.log("SUP loadUserProfile")
        if (!this.$store.getters['user/id']) {
          await this.$store.dispatch('user/fetchUserSpotifyProfile')
        }
      },
      async gameIDFromGenre(genre) {
        console.log("SUP loadGameFromGenre")
        const game = await api.getGameFromGenre(genre)
        this.$store.commit('context/SET_GAME', { game: game })
      },


       ...mapActions({
          next_poll: "game/NEXT_POLL",
          prev_poll: 'game/PREV_POLL',
          loadGame: 'context/loadGame',
          showStreamingModal: 'modals/SHOW_STREAMING_MODAL',
          showGenreModal: 'modals/SHOW_GENRE_MODAL',

        })
     },
     watch: {
    /**
     * Summary: Upon changing screen orientation from Portrait to Landscape, Autoplay the song on Mobile devices
     * 
     * @param  {[Bool]} newVal [New value of landscape_mode]
    
     */
      async landscape_mode(newValue) {
        console.log("landscape_mode changed to ", newValue)
        if (newValue && this.poll_options) {
          const track = this.poll_options[0]
          //await this.$store.dispatch('audioPlayer/play', {track: track, use_preview: true }) 
          const the = this
          this.isAutoPlaying = true
          // setTimeout(() => {
          // }, 200)
        } else {
          this.$store.dispatch('audioPlayer/pause')
          this.isAutoPlaying = false


        }
      }
     }

  }

/* To discard when I feel more confident about mounting the Poll view
          //if first character in playlist_id is "-", then it's a draft
          if (playlist_id.charAt(0) === "-") {
          } else {
            this.loadGameFromSpotify()  
          }
      //this.listenForScreenOrientation() 
      // this.setDocumentTitle()
      // var the = this
      // setTimeout(() => {
      //   the.setDocumentTitle()
      // }, 3000)   
      
      }
            async loadGameFromSpotify(playlist_id) {
            const fields = "id, name, images, description, owner, followers";
            var response = await api.spotify.playlists.getPlaylist(playlist_id, fields)
            var playlist = response.data
            this.game2 = playlist      
      },
*/
</script>

<style lang="scss" scoped>
.poll-window {
  position: relative;
}
.topbar {
  height: 2rem;
  position: absolute;
  z-index: 2;
}
.topbar-streaming {
height: 3.6rem;
position: absolute;
z-index: 2;
}
.poll_container {
  height: 100%;
}
.streaming-frame {
  position: absolute;
  top: 3.6rem;
  left: 0;
  width: 100%;
  bottom: 0;
  // border: 2px solid rgba(202, 255, 52, 0.5);
  z-index: 3;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}

</style>