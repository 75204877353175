<template>
    <div class="confirmation_container">

        <div class="confirmation_top">
            <div class="confirmation_top__text">
                <h1 class="confirmation_top__text__title">{{game.name}}</h1>
                <p class="confirmation_top__text__subtitle">}</p>
            </div>
           <img class="confirmation_top__game_image" :src="game_image" alt="" />
        </div>
        <div class="game_info">
            <h2 class="game_info__header"> Game Info</h2>
            <div class="game_info__row"><span class="game_info__description">Jackpot Size</span> <div class="game_info__data"><span >{{jackpot_size}}</span><span class="jackpot_label">&nbsp;Tokens</span></div></div>
            <div class="game_info__row"><span class="game_info__description">Time Left</span> <span class="game_info__data">21 : 39 : 43</span></div>
            <div class="game_info__row" v-if="creator_name"><span class="game_info__description">Creator</span> <span class="game_info__data">@{{creator_name}}</span></div> 
        </div>
        <div class="instructions" @click="joinGame()">
            <img class="instructions__image" src="@/assets/outerSpace.png" alt="" />
            <div class="instructions__btm">
                <div class="instructions__btm__text">
                    <p class="instructions__btm__text__title">First Time?</p>
                    <p class="instructions__btm__text__subtitle">Click to start playing this playlist</p>
                </div>
                <right-arrow :color="'white'" />


            </div>
        </div>
        <div class="cta_btns">
            <!-- <button v-if="game_started" class="green_gradient" @click="joinGame()">Share</button> -->
            <!-- <button v-if="game_started" class="green_gradient" @click="joinGame()">View</button> -->
        </div>
        <div class="back_btn" @click="goBack()"> Go Back</div>
        
  </div>
</template>

<script>
import RightArrow from "@/components/SVGs/RightArrow.vue"

export default {
    name: "MobilePortraitConfirmationPage",
    components: { RightArrow },
    props: ["game", "game_name", "game_image", "cta_label", "game_started", "creator", "track_count"],
    methods: {
        joinGame() {
            this.$emit("joinGame")
        },
        goBack() {
            this.$router.back()
        }
    },
    computed: {
        creator_name() { return this.creator?.username },
        jackpot_size() { return this.game.tokens || 0}
    },
}
</script>

<style lang="scss" scoped>
    .confirmation_container {
        padding-top: 1.5rem;
        color: #E8E8E8;
        background-color: #1f1f1f;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        gap: 3.6vh;
    }
    .confirmation_top {
        display: flex;
        flex-direction: row-reverse;
        width: 93vw;
        margin: 0 auto;
        gap: 5.4vw;
        &__game_image {
            flex: 1;
            object-fit: cover;
            margin: 0 auto;
            margin-bottom: 12px;
            display: block;
            overflow: hidden;
        }
        &__text {
            flex: 2.1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;
            &__title {
                font-weight: 600;
                font-size: 24px;
            }
            &__subtitle {
                font-weight: 400;
                font-size: 12px;
                color: #CDCDCD;
            }
        }
    }
    .game_info {
        margin-top: 11px;
        display: flex;
        flex-direction: column;
        width: 93%;
        margin: 0 auto;
        padding-left: 12px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background: #2C2929;
        gap: 9.5%;
        padding-top: 1rem;
        padding-bottom: 1vh;
        padding-left: 12px;
        text-align: left;
        &__header {
            font-size: 24px;
            line-height: 28px;
            font-weight: 500;
            color: #FFF;
            margin-bottom: 20px;
        }
        &__row {
            display: flex;
            justify-content: space-between;
            padding-right: 66px;
            margin-bottom: 20px;
        }
        &__description {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: flex-end;
            color: rgba(255, 255, 255, 0.68);
        }
        &__data {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: flex-end;
            color: #FFFFFF;
        }
        
    }
    .instructions {
        background: #252525;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        text-align: left;
        width: 93vw;
        margin: 0 auto;
        &__image {
            width: 100%;
            height: auto;
        }

        &__btm {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1vh 5.4vw;
            &__text {
                &__title {
                    font-weight: 600;
                    font-size: 24px;
                }
                &__subtitle {
                    font-weight: 400;
                    font-size: 11px;
                }

            }
        }

    }

    .green_gradient {
        background: linear-gradient(90deg, #CAFF34 0%, #9BFF7B 104.09%), #FFFFFF;
        border-radius: 20px;
        color: #000000;
        font-weight: 600;
        font-size: 16px;
        width: 26.7vw;
        height: 53px;
        line-height: 19px;
        border: 0;
        margin: 0 auto;
    }

    .cta_btns {
        display: flex;
        width: 93%;
        margin: 0 auto;

    }



.back_btn {
    font-weight: 700;
    margin-top: -16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    &__arrow {
        width: 15px;
    }
}
.jackpot_label {
    font-weight: 100;
}
</style>
