
import request from './../request.js'

export default {
    getCurrentUserPlaylists(limit = 2, offset) {
        return request.get("me/playlists", {
            params: {
                limit,
                offset
            }
        });
    },
    getPlaylist(playlist_id) {
        return request.get(`/playlists/${playlist_id}`, {
            params: {
                
            }
        });
    },
    getPlalistTracks(playlist_id, fields = "", limit=50, offset = 0) {
        return request.get(`playlists/${playlist_id}/tracks`, {
            params: {
                fields,
                limit,
                offset
            }
        });

    },
    createPlaylist(user_id, name, description) {
        return request.post(`users/${user_id}/playlists`, {
            name,
            description
        });
    },
    getUserPlalists(user_id, offset, limit) {
        return request.get(`users/${user_id}/playlists`, {
            params: {
                limit,
                offset
            }
        });
    },
    // saveTrack(playlist_id="", ids) {
    //     return request.put("me/tracks", {
    //         ids
    //     });
    // },

    // removeTrack(ids) {
    //     return request.delete("me/tracks", {
    //         data: {
    //             ids
    //         }
    //     });
    // }
    saveTrack(playlist_id, uris, position=0) {
        console.log("saveTrack() called", uris, playlist_id)
        return request.post(`playlists/${playlist_id}/tracks?uris=${uris}&position=${position}`);

    },
    removeTracks(playlist_id, uri, access_token) {
        // return request.delete(`playlists/${playlist_id}/tracks`, {
        //     data: { tracks: ids }
        // });
        fetch(`https://api.spotify.com/v1/playlists/${playlist_id}/tracks`, {
            body: "{\"tracks\":[{\"uri\":\"" + uri + "\"}]}",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/json"
            },
            method: "DELETE"
        })     

    }

}