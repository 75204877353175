<template>
    <div class="modal__frame">
        <div class="modal__dialog">
            <div class="modal__header">
                <p>Sign in with <span class="green-italics"> Spotify</span></p>
            </div>
            <div class="modal__body">
                <button class="green-gradient" @click="signIn()">Sign in with Spotify</button>
            </div>
        </div>
    </div>
</template>
<script>
import '@/assets/css/modals.scss';

export default {
    name: "SignInModalContent",
    methods: {
        signIn() {
            this.$store.dispatch("auth/login", {guest_email: null});
        }
    },
}
</script>
<style lang="scss" scoped>
p, button {
    font-size: 1.5rem;
}
.green-italics {
    font-style: italic;
    background: linear-gradient(90deg, #CAFF34 56.2%, #9BFF7B 73.92%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.green-gradient {
    background: linear-gradient(180deg, #CAFF34 0%, #9BFF7B 100%);
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 1rem;
    margin-top: 11.5vh;
}
.modal {
    display: block;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 32px;
        width: 66%;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 1.5rem;
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        height: 100%;
        text-align: left;
    }
    &__frame {
      height: 36vh;
    }
}
</style>