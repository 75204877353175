require('@/assets/main.scss');
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import '@/assets/css/global.scss';

//import { Howl, Howler } from 'howler';

import VueSlider from 'vue-slider-component'
Vue.component("VueSlider", VueSlider);
import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);
import { rtdbPlugin } from 'vuefire'
Vue.use(rtdbPlugin, { bindName: '$bind', unbindName: '$unbind' })

Vue.config.productionTip = false


Vue.filter("msToMinutes", function (millis) {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
});

new Vue({
  store,
  router,
  // howl: Howl,
  // howler: Howler,
  render: h => h(App)
}).$mount('#app')
