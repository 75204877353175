<template>
    <div class="header">
        <ul>
            <category-item  v-for="tag in tags" :key="tag"
                            :category_id="tag"
                            :class="{'is-active': active_tab == tag}"  
                            @setCategory="setCategory"/>
            <category-item :category_id="'edit'"
                            @goToEdit="goToEdit()" />
        </ul>
         <img src="@/assets/settingsGear.png" class="cog" @click="goToEdit()" >

    </div>
</template>
<script>

//import CategoryItem from '@/components/GameBrowser/Header/CategoryItem.vue';
import CategoryItem from '@/components/GameBrowser/Header/CategoryItem/index.vue';
export default {
    name: "MobileBrowserHeader",
    props: ["active_tab", "tags"],
    components: { CategoryItem },
    


    methods: {
        setCategory(val) {
            console.log("Mobile.SetTab()", val)
            this.$emit("tabSet", val)
        },
        goToEdit() {
            this.$router.push({name: "Home", params: { id: 2}})
        }
    }

}
</script>
<style lang="scss" scoped>


.header {
    background: #1f1f1f;
    color: white;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin:  1rem 1rem;

}

ul {
    list-style: none;
    display: flex;
    gap: 8%;
    width: 100%;
    flex-wrap: none;
    position: relative;
    overflow-x: auto;
//    padding: 1rem;
    font-weight: 100;
}
.cog {
    height: 1.5rem;
    width: auto;
}
@media only screen and (min-width: 480px) { 
    ul {
        gap: 3%;
    }
}
</style>
