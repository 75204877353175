import request from './../request.js'

export default {
    // search(query, types, limit = 30, auth_id) {
       
    //     return fetch(`https://api.spotify.com/v1/search?q=${query}&type=${types}&limit=${limit}&include_external=audio`, {
    //         method: 'GET',
    //         headers: { 'Authorization': 'Bearer ' + auth_id }
    //     })
    //     .then(response => response.json())
    // }

    search(
        q,
        type,
        limit,
        market,
        offset,
        include_external
    ) {
        type = type || 'playlist';
        return request.get(`search`, {
            params: {
                q,
                type,
                limit,
                offset,
                market,
                include_external
            }
        });
    }
}