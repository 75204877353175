<template>
    <div class="modal" v-show="show">
        <div class="modal__backdrop" @click="closeModal()">
            <div class="modal__frame">
                <div class="modal__dialog">
                    <div class="modal__header"> 
                    <p>Round 1</p>
                    </div>
                    <div class="modal__body">
                        <div class="grid-container">
                            <div
                            v-for="(track, index) in tracks"
                            :key="index"
                            class="grid-item"
                            :style="{ backgroundImage: `url('${getTrackImage(track)}')` }"> </div>
                        </div>
                        <div class="progress-container">
                            <div class="progress-bar"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "PollModal",
    props: ["tracks"],
    mounted() {
        console.log(this.tracks);

    },
    computed: {
        show() {
            return this.$store.getters['modals/pollModalVisible']
        }
    },
    methods: {
        getTrackImage(track) {
            return track.album.images[0].url || "";
        },
        closeModalOnTimer() {
            setTimeout(() => {
                this.closeModal();
            }, 5000);
        },
        closeModal() {
            this.$store.dispatch('modals/HIDE_POLL_MODAL')
        }
    }

}
</script>
<style lang="scss" scoped>
.modal {
    display: block;
    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 1000;
        display: flex;
        align-items: center;
    }
    &__frame {
        margin-top: -8vh;
       
        height: fit-content;
        padding-bottom: 2rem;
    
    }
    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 32px;
        width: 66%;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 1.5rem;
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        width: 66%;
        height: 100%;
        text-align: left;
    }
  
}
.progress-container {
  width: 100%;
  height: 20px;
  background-color: transparent;
}

.progress-bar {
  height: 100%;
  background-color: #4CAF50;
  width: 100%;
  animation: progress 5s linear forwards;
}

@keyframes progress {
  from { width: 100%; }
  to { width: 0%; }
}
@media only screen and (max-width: 1000px) and (orientation: landscape) {
    .modal {
      &__frame {
       height: 70vh;
     //  top: 6%;
      }
      &__body {
        width: 100%;

      }
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 100%;
    width: 100%;
    gap: 1rem;
  }
  
  .grid-item {
    background-size: cover;
    background-position: center;
    transition: opacity 1s;
    width: 150px;
    height: 150px;
    padding-bottom: 100%;
  }
</style>