<template>
    <div class="container">
        <h1>Account Creation</h1>
        <account-creation-modal />
    </div>

</template>
<script>
import AccountCreationModal from "@/components/AccountCreation/index.vue"
export default {
    name: "AccountCreation",
    components: {
        AccountCreationModal
    }

}
</script>
<style lang="scss" scoped>

    h1 {
        color: white;
        font-size: 18px;
    }
</style>