<template>
    <div class="track_card" >

        <div class="album_container">
            <img class="track_card__img" :src="image" alt="" />
            <span class="top-center number_Circle2" :class="{'isWinner': index <= 2}">{{rank}} </span>
        </div>
        <div class="track_card__details">
            <p class="track_card__name">{{name_label}}</p>
            <p class="track_card__artist" @click="artistSelected()">{{artist_label}}</p>
        </div>
        <div class="track_card__votes_group">
            <p :title="votes_count_title(track)" class="track_card__votes">{{votes_count}}</p>
            <svg class="ml-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9372 7.65088H13.2326C13.457 6.75092 14.0617 6.40841 14.0617 5.15177C14.0617 2.85816 11.4492 0.902329 10.7901 4.42896C10.5941 5.37853 8.43274 8.8154 7.47136 8.89099C7.14775 8.9217 6.87611 9.19334 6.87611 9.51695V15.7765C6.87611 16.1191 7.16901 16.3907 7.4997 16.4001C7.84221 16.412 8.42801 16.6151 9.04216 16.83C10.098 17.1915 11.4161 17.6521 12.8121 17.6521C14.6238 17.6521 16.6765 17.6804 16.4734 15.1222C17.0025 14.6168 17.208 13.7593 17.0025 13.0625C17.4749 12.4294 17.5245 11.7066 17.2765 10.9815C17.2765 10.9815 17.704 7.65088 15.9372 7.65088Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25015 9.5264V15.7765C6.25015 16.1167 5.9667 16.4001 5.62419 16.4001H2.81328C2.47077 16.4001 2.18732 16.1191 2.18732 15.7765V9.5264C2.18732 9.18389 2.47077 8.90044 2.81328 8.90044H5.62419C5.9667 8.90044 6.25015 9.18389 6.25015 9.5264Z" fill="white"/>
            </svg>

        </div>
        <PlayButton class="play_btn btn" :isPng="false" :track="TRACK" :preview="false" />
        <TrackAddition :heart="false" :track="TRACK" :game_complete="true" class="add_btn btn"/>
    </div>
  
</template>


<script>

function truncate(str, n) { return str ? 
                                        ((str.length > n) ? str.substr(0, n-1) + '...' : str) : "" }

import TrackAddition from '@/components/TrackAddition/'
import PlayButton from '@/components/PlayButton/'
export default {
    name: "Track",
    props: ["track", "index", "votes_count", "header"],
    components: { PlayButton, TrackAddition },

    computed: {
        rank() { return this.index + 1 },
        image() { 
            var images =  this.track?.item?.album?.images || null
            return images ? images[images.length - 1].url : ""
        },
        TRACK() {
            return this.track?.item
        },
        name_label() { return truncate(this.track?.item?.name, 15) ||  ""},
        artist_label() { return truncate(this.track?.item?.artists?.map(artist => artist?.name) .join(", "), 18) || "" },

    },
    methods: {
        votes_for_track(track) {
            return Object.keys(track?.voters).reduce((r,k) => Object.keys(track?.voters[k]).reduce((o,p) => (o[p] = o[p] + 1 || 0, o), r), {});
        },
        artistSelected() {
            this.$emit("artistSelected", this.track?.item?.artists[0]?.id)
        },
        async addTrackToPlaylist() {
            console.log("Track.addTrackToPlaylist", this.track)
            await this.$store.dispatch('user/favorite_song', this.track?.item)
        },
        getCurrentUserPositionForTrack(track) {
            console.log("getCurrentUserPositionForTracks() called")
            if (this.currentUserPositions && track.item) {
                return this.currentUserPositions[track.item.id] 
            } else {
                return "null"
            }
        },
        votes_count_title(track) { return `You voted ${this.tallyCurrentUsersVotesFor(track)} times for this song`},

        tallyTotalVotesFor(track) {
            var sum = 0
            if (track.voters != null) {
                for (let key in track.voters) {
                    // key represents a userID. track[key] is a dictionary. it should have 1 key per vote by the userID
                    // we want to aggregate the total votes by adding 1 for every subkey
                    sum += Object.keys(track.voters[key]).length
                }
            }
            return sum
        },
        tallyCurrentUsersVotesFor(track) {
          var tally = null
          if (this.preview_mode) {
            tally = track.item ? this.$store.getters['game_preview/vote_tally'][track.item.id] : 0
          } 
          else {
            tally = track?.voters  
                    && this.$store.getters['user/id'] 
                    && track.voters[this.$store.getters['user/id']] 
                    ? Object.keys(track.voters[this.$store.getters['user/id']]).length 
                    : 0
          }
          return tally
          
            
        },
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
.album_container { 
    width: 20%;
    height: auto;
    position: relative;
}
.play_btn {
    margin-left: 8%
}
.btn:hover {
    cursor: pointer;
}
.add_btn {
    margin-left: 6.6%
}
button {
    background: transparent;
    border: 0;
}
.track_card {
    display: flex;
    margin-bottom: 5.2%;
    align-items: center;
    width: 100%;
    &__details {
        text-align: left;
        margin-left: 3.8%;
        min-width: 21.3%;
        margin-right: 11.3%;
        white-space: nowrap;
    }
    &__img {
        height: auto;
        min-width: 54px;
        width: 54px;
    }
    &__name {
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
    }
    &__artist {
        font-size: 12px;
        line-height: 14px;
    }
    
    &__votes_group {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
        margin-left: 11.3%;
        min-width: 17.5%;//16%;
    }
    &__votes_group:hover {
        cursor: pointer;
    }
}
.number_Circle2 {
    min-width: 35.8%;
    min-height: 35.8%;
    width: fit-content;
    height: fit-content;
}

.top-center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: black;
    font-size: 16px;
    line-height: 19px;
    font-weight: 900;
    display: flex;
    background: #A8A8A8;
    justify-content: center;
    align-items: center;
}
button:hover {
    cursor: pointer;
}
.isWinner {
      background: #CAFF34;
}
</style>
