<template>
    <div class="artist-card" @click="selectArtist()"> 
        <img :src="artist_image" alt="" />
        <p> {{ artist_name }}</p>
    </div>
</template>
<script>
export default {
    name: "ArtistCard",
    props: ["artist"],
    computed: {
        artist_name() {
            return this.artist.name
        },
        artist_image() {
            return this.artist.images[0].url
        }
    },
    methods: {
        selectArtist() {
            this.$emit('selectArtist', this.artist)
        }
    }

}
</script>
<style lang="scss" scoped>
.artist-card {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    background: #494947;
    border-radius: 16px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
img {
    border-radius: 50%;
    height: 2.5vh;
    width: 2.5vh;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
p {
    font-family: 'Avenir';
    font-size: 12px;
    font-weight: 700;
}
</style>