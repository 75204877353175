<template>
    <a  class="vote-button mb-4"
        v-if="!userHasVoted"
        v-on:click.prevent="upvote">
        Vote
    </a>
    <div class="progress-container" v-else> {{votes_count_percentage}}% <br />
        <progress  class="voteResults mb-2" max="100" :value="votes_count_percentage" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "PollHeader",
    props: ["game_over", "track"],
    computed: {
        votes_count() { return this.current_poll[`${this.track.id}`] ? Object.keys(this.current_poll[`${this.track.id}`]).length : 0},
        total_votes_count() { 
            return Object.keys(this.current_poll)
                        .map(option => Object.keys(this.current_poll[`${option}`]))
                        .reduce((acc, curVal) => acc.concat(curVal), []).length },
        
        votes_count_percentage() { 
            return this.votes_count == 0 ? 0 : Math.round((this.votes_count / this.total_votes_count) * 100) },
    ...mapGetters({
        userHasVoted: "context/userHasVoted",
        current_poll: "context/cPoll"
    })
    },
    methods: {
        upvote() { 
            this.$emit('upvote')
        }
    }
}
</script>

<style lang="scss" scoped>
.vote-button {
  display: block;
  width: 105px;
  font-family: Rubik;
  margin: 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  padding: 8px 32px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 8px;
}

.vote-button:hover  {
    background: white;
    color:black;
}
progress {
  /* style rules */
 // width: MAX(39vh, 25vw); //25vw

  width: 100%;
  //width: 18.75rem;
  height: 1.5rem;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 0.5rem;
  border-left: 0;
}
progress::-webkit-progress-bar {
  /* style rules */
  background-color: transparent;
  border-radius: 0.4rem;
}
progress::-webkit-progress-value {
  /* style rules */
  background-color:rgba(255, 255, 255, 0.7);
  border-radius: 0.3rem;
}
progress::-moz-progress-bar {
  background-color: transparent;
  border-radius: 0.4rem;
  /* style rules */
}
</style>